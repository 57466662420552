<template>
<div class="music-edit-container" dir="rtl">
    <div class="music-edit-header">
      <h1>تعديل الموسيقى</h1>
    </div>
    <div class="music-edit-body">
      <section v-if="adminMusic.length" class="suggested-music">
        <h2>الموسيقى المقترحة</h2>
        <ul>
          <li v-for="track in adminMusic" :key="track.id" class="track-item">
            <button @click="selectTrack(track.url)" class="play-button">
              تشغيل <i class="fas fa-play" style="margin-left: 5px;"></i>
            </button>
            <span class="track-title">{{ track.title }}</span>
          </li>
        </ul>
      </section>
      <section class="upload-section">
        <h2>رفع موسيقاك</h2>
        <label for="file-upload" class="custom-file-upload">
          <i class="fas fa-cloud-upload-alt"></i> اختر ملف
        </label>
        <input id="file-upload" type="file" accept="audio/*" @change="handleFileUpload" />
        <div v-if="audioData" class="audio-info">
          <p>الملف الصوتي المحدد <strong>{{ audioFileName }}</strong></p>
          <audio :src="audioData" controls></audio>
        </div>
        <div v-if="loading" class="loading-indicator">جاري التحميل...</div>
      </section>
    </div>
  </div>
</template>



<script>
import { API_URL } from '@/config';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'MusicTemplate',
  props: {
    previewInstance: {
      type: Object,
      required: true
    },
    videoId: {
      type: Number,
      required: true
    },
    dynamicElementIds: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      videoDetails: null,
      localDynamicElementIds: {},
      audioData: null,
      audioFileName: '',
      adminMusic: [],
      loading: false // Added loading state
    };
  },
  computed: {
    ...mapState(['modifications']),
    audioElementId() {
      return this.localDynamicElementIds?.audio_key || null;
    }
  },
  watch: {
    videoId: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.fetchVideoData();
        }
      },
      immediate: true
    },
    dynamicElementIds: {
      handler(newVal) {
        this.updateDynamicElementIds(newVal);
      },
      immediate: true,
      deep: true
    },
    modifications: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.applyAudioModifications();
        }
      },
      deep: true
    }
  },
  async mounted() {
    await this.fetchVideoData();
    this.fetchAdminMusic();
    this.updateDynamicElementIds(this.dynamicElementIds);
  },
  methods: {
    updateDynamicElementIds(newDynamicElementIds) {
      this.localDynamicElementIds = newDynamicElementIds.elements || {};
      this.updateAudioModificationForNewTemplate();
      this.loadInitialAudioData();
    },
    updateAudioModificationForNewTemplate() {
      const audioKey = this.localDynamicElementIds?.audio_key || null;
      if (audioKey && this.videoDetails) {
        const audioData = this.modifications[audioKey] || (this.videoDetails.modifications && this.videoDetails.modifications[audioKey]) || '';
        this.updateAudio(audioData);
      } else {
        console.warn('Video details or audio key is not available.');
      }
    },
    ...mapMutations(['updateModifications']),
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.audioFileName = file.name;
      this.uploadFileToBackend(file);
    },
    async uploadFileToBackend(file) {
      this.loading = true; // Set loading to true when upload starts
      const formData = new FormData();
      formData.append('audio', file);
      const token = localStorage.getItem('token');

      try {
        const response = await fetch(`${API_URL}/api/upload_audio`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          body: formData
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log('File uploaded successfully:', data);
        this.audioData = data.url;
        this.updateAudio(this.audioData);
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        this.loading = false; // Set loading to false when upload finishes
      }
    },
    loadInitialAudioData() {
      const audioKey = this.localDynamicElementIds?.audio_key || null;
      if (this.videoDetails && audioKey) {
        const initialAudioData = this.modifications[audioKey] || this.videoDetails.modifications[audioKey] || '';
        if (initialAudioData) {
          this.audioData = initialAudioData;
          console.log('Initial audio data loaded:', this.audioData);
        }
      } else {
        console.warn('Video details or audio key is not available.');
      }
    },
    updateAudio(audioData) {
      const audioElementId = this.audioElementId;
      if (audioElementId) {
        console.log('Updating audio for element ID:', audioElementId, 'with data:', audioData);
        this.updateModifications({ key: audioElementId, value: audioData });
        this.applyAudioModifications();
      } else {
        console.warn('Audio element ID is not defined.');
      }
    },
    async fetchVideoData() {
      try {
        const response = await fetch(`${API_URL}/api/videos/${this.videoId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        const data = await response.json();
        this.videoDetails = data;
        this.localDynamicElementIds = data.dynamic_element_ids.elements || {};
        console.log('Video data fetched:', this.videoDetails);
        this.loadInitialAudioData();
      } catch (error) {
        console.error('Error fetching video data:', error);
        this.videoDetails = null; // Reset videoDetails on error
      }
    },
    fetchAdminMusic() {
      const token = localStorage.getItem('token');
      fetch(`${API_URL}/get_audio_files_users`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => response.json())
      .then(data => {
        this.adminMusic = data;
        console.log('Admin music fetched:', this.adminMusic);
      })
      .catch(error => {
        console.error('Error fetching admin music:', error);
      });
    },
    selectTrack(url) {
      console.log('Track selected:', url);
      this.audioData = url;
      this.updateAudio(url);
    },
    applyAudioModifications() {
      if (this.previewInstance) {
        const modifications = { ...this.modifications };
        const audioElementId = this.audioElementId;
        if (audioElementId) {
          modifications[audioElementId] = this.audioData;
          console.log('Applying modifications:', modifications);
          try {
            this.previewInstance.setModifications(modifications);
            console.log('Modifications applied successfully to preview instance.');
          } catch (error) {
            console.error('Error applying modifications to preview instance:', error);
          }
        } else {
          console.warn('Audio element ID is not defined for applying modifications.');
        }
        this.saveModificationsToDB(modifications); // Save modifications to the database
      } else {
        console.warn('Preview instance is not defined.');
      }
    },
    async saveModificationsToDB(modifications) {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token available. User must be logged in to save modifications.');
        return;
      }
      try {
        const response = await fetch(`${API_URL}/api/videos/${this.videoId}/modifications`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ modifications })
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Modifications saved to DB successfully:', data);
      } catch (error) {
        console.error('Error saving modifications to DB:', error);
      }
    }
  }
};
</script>

<style scoped>
.music-edit-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  background: #f4f4f8;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.music-edit-header h1 {
  color: #333;
  text-align: center;
}

.music-edit-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.suggested-music h2,
.upload-section h2 {
  color: #555;
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}

.track-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.play-button {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;
  margin-right: 15px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.play-button i {
  margin-right: 5px;
}

.track-title {
  flex-grow: 1;
  margin-right: 20px;
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #007BFF;
  color: white;
  border-radius: 4px;
  font-size: 16px;
  text-align: center;
}

.custom-file-upload i {
  margin-right: 8px;
}

#file-upload {
  display: none;
}

.audio-info {
  margin-top: 10px;
  background: #e9ecef;
  padding: 10px;
  border-radius: 4px;
}

.audio-info p {
  margin: 0;
  color: #333;
}

.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  color: #007bff;
  font-size: 16px;
  text-align: center;
}

.loading-indicator::before {
  content: '';
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border: 4px solid rgba(0, 123, 255, 0.2);
  border-top-color: #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>

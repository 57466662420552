//Router.js

import { createRouter, createWebHistory } from 'vue-router';

import LandingPage from './components/LandingPage.vue'; // Import LandingPage
import VideosMain from './components/views/VideosMain.vue';
import Login from './components/LoginMain.vue';
import RegisterMain from './components/RegisterMain.vue';
import FavouriteMain from './components/views/FavouriteMain.vue';
import AnalyticsMain from './components/views/AnalyticsMain.vue';
import ShareMain from './components/views/ShareMain.vue';
import ConnectWith from '@/components/views/ConnectWith.vue';
import SettingsMain from '@/components/views/SettingsMain.vue';
import WooCommerceAuth from './components/views/WooCommerceAuth.vue';
import SallaStore from './components/views/SallaStore.vue';
import EditMain from './components/views/EditMain.vue';
import SimpleVideoEditor from './components/views/SimpleVideoEditor.vue';
import SubmitMain from './components/SubmitMain.vue';
import VerificationMain from './components/VerificationMain.vue';
import WhoWrAre from './components/WhoWeAre.vue';
import VideoPreviewPage from './components/views/VideoPreviewPage.vue';
import ProductsMain from './components/views/ProductsMain.vue';
import PlansMain from './components/views/PlansMain.vue';
import SupportsMain from './components/views/SupportsMain.vue';
import ScrapeProducts from './components/views/ScrapeProducts.vue';


//reel_imports 
import LandingPageReel from '@/components/reels/LandingPageReel.vue';
import MainReelPage from '@/components/reels/MainReelPage.vue';
import ReelProjectsPage from '@/components/reels/ReelProjectsPage.vue';
import ReelPage from '@/components/reels/ReelPage.vue';

// Import additional pages
import ContactUs from './components/ContactUs.vue';
import CookiesMain from './components/CookiesMain.vue';
import OurVision from './components/OurVision.vue';
import PricingPlan from './components/Pricing_Plan.vue';
import TermsAndServices from './components/TermsAndServices.vue';
import Privacy_Policy from './components/Privacy_Policy.vue';
import WhoWeAre from './components/WhoWeAre.vue'; 

// Import Admin Components
import AdminLogin from './components/admin/AdminLogin.vue';
import AdminDashboard from './components/admin/AdminDashboard.vue';
import TemplateAdmin from './components/admin/TemplateAdmin.vue';
import AudioAdmin from './components/admin/AudioAdmin.vue';
import CreditsRequired from './components/admin/CreditsRequired.vue';
import SupportAdmin from './components/admin/SupportAdmin.vue';
import StripePlan from './components/admin/StripePlan.vue';
import DashboardHome from './components/admin/DashboardHome.vue';
import UserAdmin from './components/admin/UserAdmin.vue';
import TemplateImages from './components/admin/TemplateImages.vue';
import SettingsAdmin from './components/admin/SettingsAdmin.vue';

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
    meta: { showHeaderAndSidebar: false, showHeader: false }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: VideosMain,
    meta: { requiresAuth: true, showHeaderAndSidebar: true }
  },
  {
    path: '/SimpleVideoEditor',
    name: 'SimpleVideoEditor',
    component: SimpleVideoEditor,
    meta: { showHeaderAndSidebar: true, requiresAuth: true }
  },
  {
    path: '/preview/:productId?/:designId?',
    name: 'Preview',
    component: VideoPreviewPage,
    meta: { showHeaderAndSidebar: true, requiresAuth: true },
    props: true
  },
  { 
    path: '/favourite',
    name: 'Favourite', 
    component: FavouriteMain,
    meta: { showHeaderAndSidebar: true, requiresAuth: true }
  },
  {
    path: '/products',
    name: 'Products',
    component: ProductsMain,
    meta: { showHeaderAndSidebar: true, requiresAuth: true }
  },
  {
    path: '/Analytics',
    name: 'Analytics',
    component: AnalyticsMain,
    meta: { showHeaderAndSidebar: true, requiresAuth: true }
  },
  {
    path: '/Share',
    name: 'Share',
    component: ShareMain,
    meta: { showHeaderAndSidebar: true, requiresAuth: true }
  },
  {
    path: '/Connect',
    name: 'Connect',
    component: ConnectWith,
    meta: { showHeaderAndSidebar: true, requiresAuth: true }
  },
  {
    path: '/scrape-products',
    name: 'ScrapeProducts',
    component: ScrapeProducts,
    meta: { showHeaderAndSidebar: true, requiresAuth: true }
  },
  {
    path: '/landing-page-reel',
    name: 'LandingPageReel',
    component: LandingPageReel,
    meta: { showHeaderAndSidebar: false, requiresAuth: true, isReelPage: true }
  },
  {
    path: '/Main-Reel-Page',
    name: 'MainReelPage',
    component: MainReelPage,
    meta: { showHeaderAndSidebar: false, requiresAuth: false, isReelPage: true }
  },
  {
    path: '/Reel_Projects_Page',
    name: 'ReelProjectsPage',
    component: ReelProjectsPage,
    meta: { showHeaderAndSidebar: false, requiresAuth: false, isReelPage: true }
  },
  {
    path: '/reel/:id',
    name: 'ReelPage',
    component: ReelPage,
    meta: { showHeaderAndSidebar: false, requiresAuth: false, isReelPage: true }
  },



  {
    path: '/support',
    name: 'Support',
    component: SupportsMain,
    meta: { showHeaderAndSidebar: true, requiresAuth: true }
  },
  {
    path: '/Settings',
    name: 'Settings',
    component: SettingsMain,
    meta: { showHeaderAndSidebar: true, requiresAuth: true }
  },
  {
    path: '/woocommerce-auth',
    component: WooCommerceAuth,
    meta: { showHeaderAndSidebar: true, requiresAuth: true }
  },
  {
    path: '/salla_callback',
    name: 'SallaStore',
    component: SallaStore,
    meta: { showHeaderAndSidebar: true, requiresAuth: true }
  },
  {
    path: '/plans',
    name: 'Plans',
    component: PlansMain,
    meta: { showHeaderAndSidebar: true, requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { showHeaderAndSidebar: false, showHeader: false }
  },
  {
    path: '/WhoWrAre',
    name: 'WhoWrAre',
    component: WhoWrAre,
    meta: { showHeaderAndSidebar: false, showHeader: false }
  },
  {
    path: '/verification',
    name: 'VerificationPage',
    component: VerificationMain,
    meta: { showHeaderAndSidebar: false, showHeader: true, requiresAuth: true }
  },
  {
    path: '/submit',
    name: 'Submit',
    component: SubmitMain,
    meta: { showHeaderAndSidebar: false, showHeader: true, requiresAuth: true }
  },
  {
    path: '/edit/:videoId',
    name: 'EditVideo',
    component: EditMain,
    props: (route) => ({
      videoId: route.params.videoId,
      productId: route.query.productId,
      designId: route.query.designId
    })
  }
  ,
  {
    path: '/Register',
    name: 'Register',
    component: RegisterMain,
    meta: { showHeaderAndSidebar: false, showHeader: false }
  },
  {
    path: '/contact',
    name: 'ContactUs',
    component: ContactUs,
    meta: { showHeaderAndSidebar: false, showHeader: false }
  },
  {
    path: '/cookies',
    name: 'CookiesMain',
    component: CookiesMain,
    meta: { showHeaderAndSidebar: false, showHeader: false }
  },
  {
    path: '/vision',
    name: 'OurVision',
    component: OurVision,
    meta: { showHeaderAndSidebar: false, showHeader: false }
  },
  {
    path: '/pricing',
    name: 'PricingPlan',
    component: PricingPlan,
    meta: { showHeaderAndSidebar: false, showHeader: false }
  },
  {
    path: '/terms',
    name: 'TermsAndServices',
    component: TermsAndServices,
    meta: { showHeaderAndSidebar: false, showHeader: false }
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicy',
    component: Privacy_Policy,
    meta: { showHeaderAndSidebar: false, showHeader: false }
  },
  {
    path: '/who-we-are',
    name: 'WhoWeAre',
    component: WhoWeAre,
    meta: { showHeaderAndSidebar: false, showHeader: false }
  },
  // Admin Routes
  {
    path: '/admin/dashboard',
    component: AdminDashboard,
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      {
        path: '',
        name: 'DashboardHome',
        component: DashboardHome,
      },
      {
        path: 'template',
        name: 'TemplateAdmin',
        component: TemplateAdmin,
      },
      {
        path: 'support',
        name: 'SupportAdmin',
        component: SupportAdmin,
      },
      {
        path: 'credits',
        name: 'CreditsRequired',
        component: CreditsRequired,
      },
      {
        path: 'audio',
        name: 'AudioAdmin',
        component: AudioAdmin,
      },
      {
        path: 'users',
        name: 'UserAdmin',
        component: UserAdmin,
      },
      {
        path: 'template-images',
        name: 'TemplateImages',
        component: TemplateImages,
      },
      {
        path: 'stripeplan',
        name: 'StripePlan',
        component: StripePlan,
      },
      {
        path: 'settings',
        name: 'SettingsAdmin',
        component: SettingsAdmin,
      },
    ],
  },
  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: AdminLogin,
    meta: { showHeaderAndSidebar: false, showHeader: false },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  } 
});

export default router;


<template>
    <div class="metric-card">
      <div class="card-icon">
        <i :class="`fa fa-${icon}`"></i>
      </div>
      <div class="card-details">
        <h3>{{ title }}</h3>
        <p>{{ value }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'MetricCard',
    props: {
      title: {
        type: String,
        required: true,
      },
      value: {
        type: [Number, String],
        required: true,
        default: 0
      },
      icon: {
        type: String,
        required: true,
      },
    },
  };
  </script>
  
  <style scoped>
  .metric-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
  }



.metric-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}
  
  .card-icon {
    font-size: 40px;
    margin-right: 20px;
    color: #2c3e50;
  }
  
  .card-details h3 {
    margin: 0;
    font-size: 18px;
    color: #2c3e50;
  }
  
  .card-details p {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    color: #34495e;
  }
  </style>
  
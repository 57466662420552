<template>
    <div class="user-management">
      <h1>User Management</h1>
      <div class="search-filter">
        <input v-model="searchQuery" placeholder="Search by name, email, or card last 4" />
      </div>
      <div class="table-container">
        <table class="user-table">
          <thead>
            <tr>
              <th class="user-id">ID</th>
              <th class="user-name">Username</th>
              <th class="user-email">Email</th>
              <th class="user-subscribed">Subscribed</th>
              <th class="user-active">Active</th>
              <th class="user-card-last4">Card Last 4</th>
              <th class="actions">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in paginatedUsers" :key="user.id">
              <td>{{ user.id || 'No ID available' }}</td>
              <td>{{ user.username || 'No username available' }}</td>
              <td>{{ user.email || 'No email available' }}</td>
              <td>
                <span class="status-icon" :class="{ active: user.is_subscribed, inactive: !user.is_subscribed }"></span>
                {{ user.is_subscribed ? 'Yes' : 'No' }}
              </td>
              <td>
                <span class="status-icon" :class="{ active: user.subscription && user.subscription.status === 'active', inactive: !user.subscription || user.subscription.status !== 'active' }"></span>
                {{ user.subscription && user.subscription.status === 'active' ? 'Yes' : 'No' }}
              </td>
              <td>{{ user.payments && user.payments.length > 0 ? user.payments[user.payments.length - 1].card_last4 : 'N/A' }}</td>
              <td class="actions">
                <button @click="viewUser(user.id)">View</button>
                <button @click="editUser(user.id)">Edit</button>
                <button @click="deleteUser(user.id)">Delete</button>
                <button @click="suspendUser(user.id)">Suspend</button>
                <button @click="activateUser(user.id)">Activate</button>
                <button @click="makeAdmin(user.id)">Make Admin</button>
                <button @click="revokeAdmin(user.id)">Revoke Admin</button>
              </td>
            </tr>
          </tbody>
        </table> 
        <div class="pagination">
          <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
          <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
        </div>
      </div>
  
      <!-- View/Edit User Section -->
    <div v-if="selectedUser" class="user-details">
      <div class="user-header">
        <h2>{{ selectedUser.username || 'No username available' }}'s Details</h2>
        <button @click="closeUserDetails">Back to User List</button>
      </div>
      <div class="user-tabs">
        <button @click="selectedTab = 'general'" :class="{ active: selectedTab === 'general' }">General</button>
        <button @click="selectedTab = 'videos'" :class="{ active: selectedTab === 'videos' }">Videos</button>
        <button @click="selectedTab = 'subscriptions'" :class="{ active: selectedTab === 'subscriptions' }">Subscriptions</button>
        <button @click="selectedTab = 'products'" :class="{ active: selectedTab === 'products' }">Products</button>
        <button @click="selectedTab = 'credits'" :class="{ active: selectedTab === 'credits' }">Credits</button>
        <button @click="selectedTab = 'payments'" :class="{ active: selectedTab === 'payments' }">Payments</button>
        <button @click="selectedTab = 'tickets'" :class="{ active: selectedTab === 'tickets' }">Tickets</button>
      </div>

      <div v-if="selectedTab === 'general'" class="general-info">
        <div v-if="isEditMode" class="edit-form">
            <div class="form-group">
              <label for="username">Username:</label>
              <input v-model="selectedUser.username" type="text" id="username" name="username" />
            </div>
            <div class="form-group">
              <label for="email">Email:</label>
              <input v-model="selectedUser.email" type="email" id="email" name="email" />
            </div>
            <div class="form-group">
              <label for="phone">Phone:</label>
              <input v-model="selectedUser.phone" type="text" id="phone" name="phone" />
            </div>
            <div class="form-group">
              <label for="address">Address:</label>
              <input v-model="selectedUser.address" type="text" id="address" name="address" />
            </div>
            <div class="form-group">
              <label for="profile_image">Profile Image URL:</label>
              <input v-model="selectedUser.profile_image" type="text" id="profile_image" name="profile_image" />
            </div>
            <div class="form-group">
              <label for="is_verified">Is Verified:</label>
              <input v-model="selectedUser.is_verified" type="checkbox" id="is_verified" name="is_verified" />
            </div>
            <div class="form-group">
              <label for="is_admin">Is Admin:</label>
              <input v-model="selectedUser.is_admin" type="checkbox" id="is_admin" name="is_admin" />
            </div>
            <div class="form-group">
              <label for="is_subscribed">Is Subscribed:</label>
              <input v-model="selectedUser.is_subscribed" type="checkbox" id="is_subscribed" name="is_subscribed" />
            </div>
            <div class="form-group">
              <label for="stripe_customer_id">Stripe Customer ID:</label>
              <input v-model="selectedUser.stripe_customer_id" type="text" id="stripe_customer_id" name="stripe_customer_id" />
            </div>
            <div class="form-group">
            <label for="credits_amount">Credits Amount:</label>
            <input v-model="creditsAmount" type="number" id="credits_amount" name="credits_amount" />
          </div>
          <div class="form-group">
            <label for="expires_at">Credits Expiry Date:</label>
            <input v-model="creditsExpiry" type="date" id="expires_at" name="expires_at" />
          </div>
          <button @click="updateUser">Save</button>
        
          </div>
          <div v-else class="user-info">
            <div class="info-row">
              <strong>ID:</strong>
              <span>{{ selectedUser.id || 'No ID available' }}</span>
            </div>
            <div class="info-row">
              <strong>Username:</strong>
              <span>{{ selectedUser.username || 'No username available' }}</span>
            </div>
            <div class="info-row">
              <strong>Email:</strong>
              <span>{{ selectedUser.email || 'No email available' }}</span>
            </div>
            <div class="info-row">
              <strong>Phone:</strong>
              <span>{{ selectedUser.phone || 'No phone available' }}</span>
            </div>
            <div class="info-row">
              <strong>Address:</strong>
              <span>{{ selectedUser.address || 'No address available' }}</span>
            </div>
            <div class="info-row">
              <strong>Profile Image:</strong>
              <div class="profile-image">
                <img :src="selectedUser.profile_image || 'No image available'" alt="Profile Image" />
              </div>
            </div>
            <div class="info-row">
              <strong>Created At:</strong>
              <span>{{ selectedUser.created_at ? new Date(selectedUser.created_at).toLocaleString() : 'No creation date available' }}</span>
            </div>
            <div class="info-row">
              <strong>Updated At:</strong>
              <span>{{ selectedUser.updated_at ? new Date(selectedUser.updated_at).toLocaleString() : 'No update date available' }}</span>
            </div>
            <div class="info-row">
              <strong>Is Verified:</strong>
              <span>{{ selectedUser.is_verified ? 'Yes' : 'No' }}</span>
            </div>
            <div class="info-row">
              <strong>Is Admin:</strong>
              <span>{{ selectedUser.is_admin ? 'Yes' : 'No' }}</span>
            </div>
            <div class="info-row">
              <strong>Is Subscribed:</strong>
              <span>{{ selectedUser.is_subscribed ? 'Yes' : 'No' }}</span>
            </div>
            <div class="info-row">
              <strong>Stripe Customer ID:</strong>
              <span>{{ selectedUser.stripe_customer_id || 'No ID available' }}</span>
            </div>
            <div class="info-row">
              <strong>Total Credits:</strong>
              <span>{{ totalCredits || 'No credits available' }}</span>
            </div>
          </div>
        </div>
  
        <div v-if="selectedTab === 'videos'" class="videos-section">
          <h3>Videos</h3>
          <div class="video-grid">
            <div v-for="video in selectedUser.videos" :key="video.id" class="video-card">
              <video controls>
                <source :src="video.video_url" :type="'video/' + video.output_format">
                Your browser does not support the video tag.
              </video>
              <div class="video-info">
                <div class="info-row">
                  <strong>Created At:</strong>
                  <span>{{ video.created_at ? new Date(video.created_at).toLocaleString() : 'No creation date available' }}</span>
                </div>
                <div class="info-row">
                  <strong>Duration:</strong>
                  <span>{{ video.duration ? video.duration + ' seconds' : 'No duration available' }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div v-if="selectedTab === 'subscriptions'" class="subscriptions-section">
          <h3>Subscriptions</h3>
          <div v-if="selectedUser.subscription" class="subscription-info">
            <div class="info-row">
              <strong>ID:</strong>
              <span>{{ selectedUser.subscription.id || 'No ID available' }}</span>
            </div>
            <div class="info-row">
              <strong>Plan Product ID:</strong>
              <span>{{ selectedUser.subscription.plan_product_id || 'No product ID available' }}</span>
            </div>
            <div class="info-row">
              <strong>Status:</strong>
              <span>{{ selectedUser.subscription.status || 'No status available' }}</span>
            </div>
            <div class="info-row">
              <strong>Current Period Start:</strong>
              <span>{{ selectedUser.subscription.current_period_start ? new Date(selectedUser.subscription.current_period_start).toLocaleString() : 'No start date available' }}</span>
            </div>
            <div class="info-row">
              <strong>Current Period End:</strong>
              <span>{{ selectedUser.subscription.current_period_end ? new Date(selectedUser.subscription.current_period_end).toLocaleString() : 'No end date available' }}</span>
            </div>
          </div>
          <div v-else>
            <p>No active subscription found.</p>
          </div>
        </div>
  
        <div v-if="selectedTab === 'products'" class="products-section">
          <h3>Products</h3>
          <div class="product-grid">
            <div v-for="product in selectedUser.products" :key="product.id" class="product-card">
              <div class="product-image">
                <img :src="product.image_url || 'No image available'" alt="Product Image" />
              </div>
              <div class="product-info">
                <h4>{{ product.name || 'No name available' }}</h4>
                <div class="info-row">
                  <strong>Price:</strong>
                  <span>{{ product.price || 'No price available' }}</span>
                </div>
                <a :href="product.url || '#'" target="_blank">View Product</a>
              </div>
            </div>
          </div>
        </div>
  
        <div v-if="selectedTab === 'credits'" class="credits-section">
          <h3>Credits</h3>
          <div class="credit-list">
            <div v-for="credit in selectedUser.credits" :key="credit.id" class="credit-item">
              <div class="info-row">
                <strong>Amount:</strong>
                <span>{{ credit.credits_amount || 'No amount available' }}</span>
              </div>
              <div class="info-row">
                <strong>Purchased At:</strong>
                <span>{{ credit.purchased_at ? new Date(credit.purchased_at).toLocaleString() : 'No purchase date available' }}</span>
              </div>
              <div class="info-row">
                <strong>Expires At:</strong>
                <span>{{ credit.expires_at ? new Date(credit.expires_at).toLocaleString() : 'No expiry date available' }}</span>
              </div>
            </div>
          </div>
        </div>
  
        <div v-if="selectedTab === 'payments'" class="payments-section">
          <h3>Payments</h3>
          <div class="payment-list">
            <div v-for="payment in selectedUser.payments" :key="payment.id" class="payment-item">
              <div class="info-row">
                <strong>Amount:</strong>
                <span>{{ payment.amount || 'No amount available' }}</span>
              </div>
              <div class="info-row">
                <strong>Card Last 4:</strong>
                <span>{{ payment.card_last4 || 'No card info available' }}</span>
              </div>
              <div class="info-row">
                <strong>Currency:</strong>
                <span>{{ payment.currency || 'No currency available' }}</span>
              </div>
              <div class="info-row">
                <strong>Created At:</strong>
                <span>{{ payment.created ? new Date(payment.created).toLocaleString() : 'No date available' }}</span>
              </div>
            </div>
          </div>
        </div>
  
        <div v-if="selectedTab === 'tickets'" class="tickets-section">
          <h3>Tickets</h3>
          <div class="ticket-list">
            <div v-for="ticket in selectedUser.tickets" :key="ticket.id" class="ticket-item">
              <div class="info-row">
                <strong>ID:</strong>
                <span>{{ ticket.id || 'No ID available' }}</span>
              </div>
              <div class="info-row">
                <strong>Title:</strong>
                <span>{{ ticket.title || 'No title available' }}</span>
              </div>
              <div class="info-row">
                <strong>Status:</strong>
                <span>{{ ticket.status || 'No status available' }}</span>
              </div>
              <div class="info-row">
                <strong>Created At:</strong>
                <span>{{ ticket.created_at ? new Date(ticket.created_at).toLocaleString() : 'No creation date available' }}</span>
              </div>
              <div class="info-row">
                <strong>Updated At:</strong>
                <span>{{ ticket.updated_at ? new Date(ticket.updated_at).toLocaleString() : 'No update date available' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  
  
  
  
  
  
  <script>
import { API_URL } from '@/config';

export default {
  data() {
    return {
      users: [],
      selectedUser: null,
      selectedTab: 'general',
      isEditMode: false,
      currentPage: 1,
      usersPerPage: 3, // Set to 3 to show only 3 users per page
      searchQuery: '', // Added for search functionality
      creditsAmount: 0,
      creditsExpiry: ''
    };
  },
  computed: {
    filteredUsers() {
      // Filter users based on search query
      return this.users.filter(user => {
        const query = this.searchQuery.toLowerCase();
        return (
          user.username.toLowerCase().includes(query) ||
          user.email.toLowerCase().includes(query) ||
          (user.payments && user.payments.length > 0 && user.payments[user.payments.length - 1].card_last4.includes(query))
        );
      });
    },
    paginatedUsers() {
      const start = (this.currentPage - 1) * this.usersPerPage;
      const end = this.currentPage * this.usersPerPage;
      return this.filteredUsers.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredUsers.length / this.usersPerPage);
    },
    totalCredits() {
      if (this.selectedUser && this.selectedUser.credits) {
        return this.selectedUser.credits.reduce((total, credit) => total + credit.credits_amount, 0);
      }
      return 0;
    }
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    fetchUsers() {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to fetch users.');
        return;
      }

      fetch(`${API_URL}/api/admin/users`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          this.users = data.users.map(user => ({
            ...user,
            payments: user.payments || [],
          }));
        })
        .catch(error => {
          console.error('Error fetching users:', error);
          alert('Failed to fetch users');
        });
    },
    viewUser(userId) {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to view users.');
        return;
      }

      fetch(`${API_URL}/api/admin/users/${userId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          this.selectedUser = data.user;
          this.creditsAmount = 0;
          this.creditsExpiry = '';
          this.isEditMode = false; // Ensure we are not in edit mode when viewing
        })
        .catch(error => {
          console.error('Error fetching user details:', error);
          alert('Failed to fetch user details');
        });
    },
    editUser(userId) {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to edit users.');
        return;
      }

      fetch(`${API_URL}/api/admin/users/${userId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          this.selectedUser = data.user;
          this.selectedTab = 'general';
          this.isEditMode = true; // Enable edit mode
        })
        .catch(error => {
          console.error('Error fetching user details:', error);
          alert('Failed to fetch user details');
        });
    },
    updateUser() {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to update users.');
        return;
      }

      const updatedUser = {
        ...this.selectedUser,
        credits_amount: this.creditsAmount,
        expires_at: this.creditsExpiry
      };

      fetch(`${API_URL}/api/admin/users/${this.selectedUser.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedUser)
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          alert(data.message);
          this.fetchUsers();
          this.closeUserDetails();
        })
        .catch(error => {
          console.error('Error updating user:', error);
          alert('Failed to update user');
        });
    },
    deleteUser(userId) {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to delete users.');
        return;
      }

      fetch(`${API_URL}/api/admin/users/${userId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          console.log('User deleted:', data);
          this.fetchUsers();
        })
        .catch(error => {
          console.error('Error deleting user:', error);
          alert('Failed to delete user');
        });
    },
    suspendUser(userId) {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to suspend users.');
        return;
      }

      fetch(`${API_URL}/api/admin/users/${userId}/suspend`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          console.log('User suspended:', data);
          this.fetchUsers();
        })
        .catch(error => {
          console.error('Error suspending user:', error);
          alert('Failed to suspend user');
        });
    },
    activateUser(userId) {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to activate users.');
        return;
      }

      fetch(`${API_URL}/api/admin/users/${userId}/activate`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          console.log('User activated:', data);
          this.fetchUsers();
        })
        .catch(error => {
          console.error('Error activating user:', error);
          alert('Failed to activate user');
        });
    },
    makeAdmin(userId) {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to make users admin.');
        return;
      }

      fetch(`${API_URL}/api/admin/users/${userId}/make-admin`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          console.log('User promoted to admin:', data);
          this.fetchUsers();
        })
        .catch(error => {
          console.error('Error promoting user to admin:', error);
          alert('Failed to promote user to admin');
        });
    },
    revokeAdmin(userId) {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to revoke admin privileges.');
        return;
      }

      fetch(`${API_URL}/api/admin/users/${userId}/revoke-admin`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          console.log('User admin privileges revoked:', data);
          this.fetchUsers();
        })
        .catch(error => {
          console.error('Error revoking user admin privileges:', error);
          alert('Failed to revoke user admin privileges');
        });
    },
    closeUserDetails() {
      this.selectedUser = null;
      this.selectedTab = 'general';
      this.isEditMode = false;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    }
  }
};
</script>



  
  

  
  
<style scoped>
/* Global Styles */
.user-management {
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  font-size: 28px;
  margin-bottom: 30px;
  color: #333;
}

/* User Table */
.table-container {
  overflow-x: auto;
  margin-bottom: 30px;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.user-table th,
.user-table td {
  padding: 15px;
  text-align: left;
  vertical-align: middle;
  border-bottom: 1px solid #eee;
}

.user-table th {
  background-color: #f7f7f7;
  font-weight: 600;
  color: #333;
  text-transform: uppercase;
  white-space: nowrap;
}

.user-table td {
  color: #555;
}

.user-table tr:last-child td {
  border-bottom: none;
}

.user-table tbody tr:hover {
  background-color: #f5f5f5;
}

.user-table .user-id {
  width: 60px;
}

.user-table .user-name {
  width: 200px;
}

.user-table .user-email {
  width: 250px;
}

.user-table .user-subscribed,
.user-table .user-active {
  width: 100px;
  text-align: center;
}

.user-table .user-card-last4 {
  width: 120px;
  text-align: center;
}

.user-table .actions {
  white-space: nowrap;
}

.user-table .actions button {
  padding: 8px 12px;
  margin-right: 5px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-table .actions button:last-child {
  margin-right: 0;
}

.user-table .actions button:hover {
  background-color: #0056b3;
}

.user-table .actions button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.4);
}

.user-table .status-icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.user-table .status-icon.active {
  background-color: #28a745;
}

.user-table .status-icon.inactive {
  background-color: #dc3545;
}
/* Search Filter */
.search-filter {
  margin-bottom: 20px;
}

.search-filter input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.search-filter input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.2);
}

/* Pagination */
.pagination {
  margin-top: 20px;
  text-align: right;
}

.pagination button {
  padding: 8px 12px;
  margin-left: 5px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.4);
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* User Details */
.user-details {
  background-color: #fff;
  padding: 30px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.user-header h2 {
  font-size: 24px;
  margin: 0;
  color: #333;
}

.user-header button {
  padding: 10px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-header button:hover {
  background-color: #0056b3;
}

.user-header button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.4);
}

.user-tabs {
  margin-bottom: 30px;
  border-bottom: 1px solid #ddd;
}

.user-tabs button {
  padding: 12px 20px;
  margin-right: 10px;
  background-color: #f7f7f7;
  color: #333;
  border: none;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.user-tabs button.active {
  border-bottom-color: #007bff;
  color: #007bff;
  font-weight: 600;
}

.user-tabs button:focus {
  outline: none;
}

/* General Info */
.general-info {
  margin-bottom: 30px;
}

.edit-form .form-group {
  margin-bottom: 20px;
}

.edit-form label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #333;
}

.edit-form input[type="text"],
.edit-form input[type="email"],
.edit-form input[type="checkbox"] {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.edit-form input[type="checkbox"] {
  width: auto;
  margin-right: 8px;
}

.edit-form input[type="text"]:focus,
.edit-form input[type="email"]:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.2);
}

.edit-form button {
  padding: 12px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-form button:hover {
  background-color: #0056b3;
}

.edit-form button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.4);
}

.user-info .info-row {
  display: flex;
  margin-bottom: 15px;
}

.user-info .info-row strong {
  width: 180px;
  font-weight: 600;
  color: #333;
}

.user-info .info-row span {
  color: #555;
}

.user-info .profile-image {
  max-width: 120px;
  margin-top: 15px;
}

.user-info .profile-image img {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}

/* Videos Section */
.videos-section {
  margin-bottom: 30px;
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

.video-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.video-card video {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
  border-radius: 4px;
}

.video-info .info-row {
  display: flex;
  margin-bottom: 10px;
}

.video-info .info-row strong {
  width: 120px;
  font-weight: 600;
  color: #333;
}

.video-info .info-row span {
  color: #555;
}

/* Subscriptions Section */
.subscriptions-section {
  margin-bottom: 30px;
}

.subscription-info {
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.subscription-info .info-row {
  display: flex;
  margin-bottom: 15px;
}

.subscription-info .info-row strong {
  width: 180px;
  font-weight: 600;
  color: #333;
}

.subscription-info .info-row span {
  color: #555;
}

/* Products Section */
.products-section {
  margin-bottom: 30px;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
}

.product-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-image {
  text-align: center;
  margin-bottom: 15px;
}

.product-image img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.product-info h4 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #333;
}

.product-info .info-row {
  display: flex;
  margin-bottom: 10px;
}

.product-info .info-row strong {
  width: 100px;
  font-weight: 600;
  color: #333;
}

.product-info .info-row span {
  color: #555;
}

.product-info a {
  display: inline-block;
  margin-top: 15px;
  padding: 10px 16px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.product-info a:hover {
  background-color: #0056b3;
}

/* Credits Section */
.credits-section {
  margin-bottom: 30px;
}

.credit-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

.credit-item {
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.credit-item .info-row {
  display: flex;
  margin-bottom: 15px;
}

.credit-item .info-row strong {
  width: 150px;
  font-weight: 600;
  color: #333;
}

.credit-item .info-row span {
  color: #555;
}
</style>
<template>
    <div class="text-edit-container" dir="rtl">
      <div class="text-edit-header">
        <h3>تعديل النص</h3>
      </div>
      <div class="text-edit-body">
        <div v-for="(text, index) in textElements" :key="index" class="text-item">
          <div class="text-label">
            <span>{{ text.label }}</span>
          </div>
          <input type="text" v-model="text.content" @input="updateText(text.key, text.content)" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapMutations } from 'vuex';
  
  export default {
    name: 'TextPreview',
    props: {
      previewInstance: {
        type: Object,
        required: true,
      },
      modifications: {
        type: Object,
        required: true,
      },
      numTexts: {
        type: Number,
        required: true,
      },
      dynamicElementIds: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        textElements: [],
      };
    },
    mounted() {
      console.log('Mounted TextPreview');
      console.log('Initializing text elements with modifications:', this.modifications);
      console.log('Number of texts:', this.numTexts);
      this.initTextElements();
    },
    methods: {
      ...mapMutations({
        updateModifications: 'updateModifications',
      }),
      initTextElements() {
        const predefinedTextKeys = ['product_name_key', 'product_price_key', 'website_key'];
        const predefinedTextLabels = ['اسم المنتج', 'السعر', 'الموقع الإلكتروني'];
  
        // Initialize predefined text elements
        this.textElements = predefinedTextKeys.map((key, index) => {
          const elementId = this.dynamicElementIds[key];
          console.log(`Predefined Text Key: ${key}, Element ID: ${elementId}`);
          if (elementId) {
            return {
              key: elementId,
              label: predefinedTextLabels[index],
              content: this.modifications[elementId] || '',
            };
          }
        }).filter(element => element);
  
        // Initialize additional text elements
        for (let i = 1; i <= this.numTexts; i++) {
          const key = `text_${i}_key`;
          const elementId = this.dynamicElementIds[key];
          console.log(`Additional Text Key: ${key}, Element ID: ${elementId}`);
          if (elementId) {
            this.textElements.push({
              key: elementId,
              label: `النص الإضافي ${i}`,
              content: this.modifications[elementId] || '',
            });
          }
        }
  
        console.log('Text elements after initialization:', this.textElements);
        this.loadInitialTextValues();
      },
      loadInitialTextValues() {
        this.textElements.forEach(textElement => {
          const initialValue = this.modifications[textElement.key] || '';
          textElement.content = initialValue;
        });
        console.log('Text elements after loading initial values:', this.textElements);
      },
      updateText(key, content) {
        console.log('updateText called with key:', key, 'and content:', content);
        this.$emit('update-modifications', { [key]: content });
      },
    },
  };
  </script>
  
  
  
  
  
  
  
  


<style scoped>
/* Text Edit Container Styles */
.text-edit-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    margin-bottom: 20px;
}

.text-edit-header {
    background-color: #2c3e50;
    color: #ecf0f1;
    padding: 10px;
    border-radius: 5px 5px 0 0;
}

.text-edit-body {
    background-color: #ecf0f1;
    padding: 20px;
    border-radius: 0 0 5px 5px;
}

.text-item {
    margin-bottom: 15px;
}

.text-item .text-label {
    margin-bottom: 5px;
}

.text-item input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

</style>
<template>
    <div class="edit-preview-container">
      <div class="edit-preview-header">
        <h2>Edit Video</h2>
        <div class="header-actions">
          <button class="share-btn" @click="saveVideo" :disabled="isSaving">
            {{ isSaving ? 'Saving...' : 'Save' }}
          </button>
          <button class="exit-btn" @click="closeEditPopup">&#10005;</button>
        </div>
      </div>
      <div class="edit-preview-content">
        <div class="sidebar">
          <div
            @click="setActiveTab('media')"
            class="sidebar-item"
            :class="{ active: activeTab === 'media' }"
          >
            <div class="icon-wrapper">
              <i class="fas fa-photo-video"></i>
            </div>
            <span>Media</span>
          </div>
          <div
            @click="setActiveTab('texts')"
            class="sidebar-item"
            :class="{ active: activeTab === 'texts' }"
          >
            <div class="icon-wrapper">
              <i class="fas fa-font"></i>
            </div>
            <span>Texts</span>
          </div>
          <div
            @click="setActiveTab('music')"
            class="sidebar-item"
            :class="{ active: activeTab === 'music' }"
          >
            <div class="icon-wrapper">
              <i class="fas fa-music"></i>
            </div>
            <span>Music</span>
          </div>
        </div>
        <div class="main-content">
          <div v-if="activeTab === 'media'" class="media-tab">
            <h3>Media</h3>
            <MediaPreview
              v-if="previewInstance"
              :previewInstance="previewInstance"
              :modifications="modifications"
              :numImages="template.num_images || 1"
              :selectedDesign="template"
              @update-modifications="updateModifications"
            />
          </div>
          <div v-if="activeTab === 'texts'" class="text-tab">
            <h3>Texts</h3>
            <TextPreview
              :previewInstance="previewInstance"
              :modifications="modifications"
              :numTexts="template.num_texts || 1"
              :dynamicElementIds="dynamicElementIds"
              @update-modifications="updateModifications"
            />
          </div>
          <div v-if="activeTab === 'music'" class="music-tab">
            <h3>Music</h3>
            <MusicPreview
              :previewInstance="previewInstance"
              :modifications="modifications"
              :dynamicElementIds="dynamicElementIds"
              @update-modifications="updateModifications"
            />
          </div>
        </div>
        <div class="video-preview">
          <div ref="previewContainer"></div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
import { Preview } from '@creatomate/preview';
import { API_URL } from '@/config';
import MediaPreview from './MediaPreview.vue';
import TextPreview from './TextPreview.vue';
import MusicPreview from './MusicPreview.vue';

export default {
  name: 'EditPreviewMain',
  emits: ['update-video-preview', 'update-modifications', 'close'],
  components: {
    MediaPreview,
    TextPreview,
    MusicPreview,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    template: {
      type: Object,
      required: true,
    },
    initialModifications: {
      type: Object,
      default: () => ({}),
    },
    dynamicElementIds: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      previewInstance: null,
      activeTab: 'media',
      modifications: {},
      isSaving: false,
      numImages: this.template.num_images || 1,
      numTexts: this.template.num_texts || 1,
      requiredCredits: 0,
    };
  },
  mounted() {
    console.log('Mounted EditPreviewMain');
    console.log('Initial modifications:', this.initialModifications);
    console.log('Number of texts:', this.numTexts);
    this.modifications = { ...this.initialModifications };
    this.initializePreview();
    this.fetchRequiredCredits();
  },
  methods: {
    async checkCredits() {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to save videos.');
        return 0;
      }

      try {
        const response = await fetch(`${API_URL}/api/check-credits`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data.credits;
      } catch (error) {
        console.error('Failed to check credits:', error);
        return 0;
      }
    },
    async fetchRequiredCredits() {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to view required credits.');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/api/required-credits`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        if (data.length > 0) {
          this.requiredCredits = data[0].requested_credits;
        } else {
          this.requiredCredits = 0;
        }
      } catch (error) {
        console.error('Failed to fetch required credits:', error);
        this.requiredCredits = 0;
      }
    },
    initializePreview() {
      const previewContainer = this.$refs.previewContainer;
      this.previewInstance = new Preview(previewContainer, 'player', 'public-yqbsoiqlkk8rwzj1pf9n9id6');
      this.previewInstance.onReady = () => {
        this.loadPreview();
      };
    },
    loadPreview() {
      const templateId = this.template.creatomate_template_id;
      console.log('Loading preview with template ID:', templateId);
      this.previewInstance.loadTemplate(templateId);
      this.previewInstance.setModifications(this.initialModifications);
    },
    setActiveTab(tabName) {
      console.log('Setting active tab:', tabName);
      this.activeTab = tabName;
    },
    updateModifications(newModifications) {
      this.modifications = { ...this.modifications, ...newModifications };
      console.log('Updated modifications:', this.modifications);
      this.previewInstance.setModifications(this.modifications);
    },
    async saveVideo() {
      console.log('Modifications being sent:', JSON.stringify(this.modifications));
      this.isSaving = true; // Disable the save button and start saving

      try {
        // Update the video preview with new modifications
        this.$emit('update-video-preview', this.modifications);
        this.$emit('update-modifications', this.modifications);
        // Dispatch a notification that the modifications are saved
       

        // Close the edit popup and emit the 'close' event
        this.closeEditPopup();
      } catch (error) {
        console.error('Error saving modifications:', error);
      } finally {
        this.isSaving = false; // Re-enable the save button
      }
    },
    closeEditPopup() {
      this.$emit('close');
    },
  },
};
</script>

  
  
  
  
  
  
  
  
  
  <style scoped>
 /* Edit Preview Container Styles */
.edit-preview-container {
    width: 1200px; /* Fixed width for the container */
    height: 100vh;
    display: flex;
    flex-direction: column;
    margin: 0 auto; /* Center the container horizontally */
}

.edit-preview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #2c3e50;
    color: #ecf0f1;
}

.edit-preview-header h2 {
    margin: 0;
}

.header-actions button {
    background: #3498db;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-left: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.header-actions button:hover {
    background: #2980b9;
}

.edit-preview-content {
    display: flex;
    flex: 1;
    overflow: hidden;
}

.sidebar {
    width: 250px;
    background-color: #34495e;
    color: white;
    display: flex;
    flex-direction: column;
    padding: 10px;
    overflow-y: auto;
}

.sidebar-item {
    display: flex;
    align-items: center;
    padding: 15px 10px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.sidebar-item:hover, .sidebar-item.active {
    background-color: #2c3e50;
}

.sidebar-item .icon-wrapper {
    width: 30px;
    text-align: center;
}

.sidebar-item span {
    margin-left: 10px;
}

.main-content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
}

.main-content h3 {
    margin-top: 0;
}

.video-preview {
    width: 400px;
    background-color: #ecf0f1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.video-preview div {
    width: 100%;
    height: 100%;
}

@media (max-width: 768px) {
  .edit-preview-container {
    width: 100%;
    flex-direction: column;
    height: auto;
  }

  .edit-preview-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-actions {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  }

  .edit-preview-content {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    padding: 5px;
  }

  .sidebar-item {
    flex: 1;
    text-align: center;
    padding: 10px;
  }

  .main-content {
    width: 100%;
    order: 2;
    padding: 10px;
    overflow-y: auto;
  }

  .main-content h3 {
    margin-top: 0;
    text-align: center;
  }

  .video-preview {
    width: 100%;
    height: 200px; /* Fixed height for video preview */
    background-color: #ecf0f1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    order: 1;
  }

  .video-preview div {
    width: 100%;
    height: 100%;
  }
}


  </style>
<!-- eslint-disable vue/no-parsing-error -->
<template>
    <div class="pricing-page">
      <header class="header">
      <div class="header-actions">
        <router-link to="/dashboard" class="login-link" style="font-weight: bold; font-size: 18px; text-decoration: underline;">لنبدأ</router-link>
      </div>

      <nav class="header-nav">
        <a href="#" class="nav-link">الأسعار</a>
        <a href="#" class="nav-link">الأسئلة الشائعة</a>
      </nav>
      <div class="header-icons">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/1909eed33e4c92c0c6a7ae656b371072f6a331d8caa1d3f9aff6a1b1f6fa06f2?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Icon" class="icon" />
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/076ad4d43ff739e5b7165868b496c5cce1c42209ae846a2e33f5034beb9bd8ef?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Logo" class="logo" />
      </div>
    </header>
      <main class="main-content">
        <section class="hero-section">
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/65397bb1a72f67ed9f8951abf5b1cb80be9bdec5302e67fb542b36e91ec80103?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="" class="hero-background" />
          <h1 class="hero-title">خطط التسعير في &quot;إبداعي&quot;</h1>
        </section>
        <p class="description">
          نقدم مجموعة متنوعة من خطط التسعير لتلائم كل احتياجات وميزانيات الأعمال. كل خطة توفر مجموعة من الميزات التي تساعدك على إنشاء ونشر فيديوهات ترويجية تحقق أقصى تأثير.
        </p>
      </main>
    </div>
  </template>
  
  <style scoped>


.header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(231, 231, 231, 0.2);
  }

.header-actions {
  display: flex;
  gap: 20px;
  font-size: 16px;
}

.login-link {
  padding: 0.75rem 1.5rem;
    border-radius: 72px;
    background-color: #0098fd;
    color: #fff;
    font: 400 16px / 160% Tajawal, sans-serif;
    text-transform: uppercase;
}

.register-button {
  margin: auto 0;
  color: #0098fd;
  font: 400 16px/150% Tajawal, sans-serif;
  text-align: center;
}

.header-nav {
  display: flex;
  gap: 20px;
  margin: auto 0;
  font-size: 16px;
  color: #222;
  font-weight: 400;
  text-align: center;
  line-height: 150%;
}

.nav-link {
  font: 400 16px/150% Tajawal, sans-serif;
}

.header-icons {
  display: flex;
  gap: 10px;
  margin: auto 0;
}

.icon {
  width: 27px;
  aspect-ratio: 1;
  object-fit: contain;
}

.logo {
  width: 80px;
  aspect-ratio: 6.25;
  object-fit: contain;
  margin: auto 0;
}




  .pricing-page {
    background-color: var(--color-whites-White, #fff);
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
  }
  

  
  @media (max-width: 991px) {
    .header {
      flex-wrap: wrap;
      padding: 0 20px;
      max-width: 100%;
    }
  }
  
  .logo-container {
    display: flex;
    align-self: stretch;
    gap: 10px;
    margin: auto 0;
  }
  
  .logo-icon {
    width: 27px;
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
  }
  
  .logo-text {
    width: 80px;
    aspect-ratio: 6.25;
    object-fit: auto;
    object-position: center;
    margin: auto 0;
  }
  
  .navigation {
    display: flex;
    align-self: stretch;
    justify-content: space-between;
    gap: 20px;
    margin: auto 0;
    font-size: 16px;
    color: #222;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
    white-space: nowrap;
  }
  
  @media (max-width: 991px) {
    .navigation {
      white-space: initial;
    }
  }
  
  .nav-link {
    font-family: Tajawal, sans-serif;
  }
  
  .auth-buttons {
    display: flex;
    align-self: stretch;
    justify-content: space-between;
    gap: 20px;
    font-weight: 400;
  }
  
  .sign-in-link {
    color: var(--1, #0098fd);
    text-align: center;
    margin: auto 0;
    font: 16px/150% Tajawal, sans-serif;
  }
  
  .get-started-button {
    background-color: var(--1, #0098fd);
    border-radius: 72px;
    color: var(--color-whites-White, #fff);
    text-transform: uppercase;
    padding: 28px 38px 29px;
    font: 18px/160% Tajawal, sans-serif;
    justify-content: center;
  }
  
  @media (max-width: 991px) {
    .get-started-button {
      padding: 0 20px;
    }
  }
  
  .main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .hero-section {
    position: relative;
    overflow: hidden;
    align-self: center;
    min-height: 149px;
    width: 100%;
    max-width: 1190px;
    margin: 136px 0 14px;
    padding: 55px 60px 25px;
    color: #303030;
    text-align: center;
    text-transform: capitalize;
    font: 500 52px/133.2% Tajawal, sans-serif;
  }
  
  @media (max-width: 991px) {
    .hero-section {
      max-width: 100%;
      font-size: 40px;
      margin-top: 40px;
      padding: 0 20px;
    }
  }
  
  .hero-background {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  .hero-title {
    position: relative;
  }
  
  .description {
    color: var(--color-blacks-Black, #000);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    align-self: center;
    margin-top: 66px;
    width: 577px;
    font: 400 18px/30px Tajawal, sans-serif;
  }
  
  @media (max-width: 991px) {
    .description {
      max-width: 100%;
      margin-top: 40px;
    }
  }
  </style>
<!-- ReelHeader.vue -->
<template>
    <header class="reel-header">
      <div class="background-shapes"></div>
      <div class="header-content">
        <div class="logo">
          <router-link to="/" class="logo-text">Filmatex</router-link>
        </div>
        <nav>
          <router-link :to="{ name: 'ReelProjectsPage' }" class="nav-link">مشاريعي</router-link>
          <router-link :to="{ name: 'MainReelPage' }" class="nav-link">إنشاء ريل جديد</router-link>
        </nav>
        <div class="back-button">
          <router-link to="/" class="nav-link">العودة إلى Filmatex</router-link>
        </div>
      </div>
    </header>
  </template>
  
  <script>
  export default {
    name: 'ReelHeader'
  }
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;700&display=swap');
  
  .reel-header {
    background-color: #0c1620;
    color: white;
    font-family: 'Cairo', sans-serif;
    padding: 1rem 2rem;
    position: relative;
    overflow: hidden;
  }
  
  .background-shapes {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: 
      radial-gradient(circle at 10% 20%, rgba(255, 255, 255, 0.03) 0%, transparent 20%),
      radial-gradient(circle at 90% 80%, rgba(255, 255, 255, 0.03) 0%, transparent 20%),
      linear-gradient(to right, rgba(255, 255, 255, 0.02) 1px, transparent 1px),
      linear-gradient(to bottom, rgba(255, 255, 255, 0.02) 1px, transparent 1px);
    background-size: 100% 100%, 100% 100%, 20px 20px, 20px 20px;
    opacity: 0.5;
  }
  
  .background-shapes::before,
  .background-shapes::after {
    content: '';
    position: absolute;
    width: 100px;
    height: 100px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
  }
  
  .background-shapes::before {
    top: 10%;
    left: 5%;
    transform: rotate(15deg);
  }
  
  .background-shapes::after {
    bottom: 15%;
    right: 10%;
    transform: rotate(-10deg);
  }
  
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;
  }
  
  .logo-text {
    font-size: 1.5rem;
    font-weight: 700;
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .logo-text:hover {
    color: #4a90e2;
  }
  
  nav {
    display: flex;
    gap: 1.5rem;
  }
  
  .nav-link {
    color: #ffffff;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 400;
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .nav-link:hover {
    color: #4a90e2;
    transform: translateY(-2px);
  }
  
  .back-button .nav-link {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .back-button .nav-link:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: #4a90e2;
  }
  </style>
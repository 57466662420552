<template>
    <div class="credits-required-container">
      <div class="main-content">
        <section id="set-requirement" class="section">
          <h2>Set Video Creation Credit Requirement</h2>
          <form @submit.prevent="submitCreditRequirement" v-if="!creditRequirements.length" class="form">
            <div class="form-group">
              <label for="requested-credits">Requested Credits</label>
              <input type="number" id="requested-credits" v-model.number="requestedCredits" required min="1">
            </div>
            <button type="submit" class="btn-submit">Set Credits</button>
          </form>
          <div v-else class="message">
            <p>A credit requirement already exists. Please edit the existing one.</p>
          </div>
        </section>
  
        <section id="current-requirements" class="section">
          <h2>Current Credit Requirements</h2>
          <table v-if="creditRequirements.length" class="table">
            <thead>
              <tr>
                <th>Credits</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="requirement in creditRequirements" :key="requirement.id">
                <td>{{ requirement.requested_credits }}</td>
                <td>
                  <button @click="editRequirement(requirement.id)" class="btn-edit">Edit</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else class="message">
            <p>No credit requirements found.</p>
          </div>
        </section>
  
        <div v-if="message" class="alert" :class="{ 'alert-success': success, 'alert-danger': !success }">
          {{ message }}
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { API_URL } from '@/config';
  
  export default {
    name: 'CreditsRequired',
    data() {
      return {
        requestedCredits: null,
        message: '',
        success: false,
        creditRequirements: []
      };
    },
    created() {
      this.fetchCreditRequirements();
    },
    methods: {
      submitCreditRequirement() {
        const token = localStorage.getItem('token');
        if (!token) {
          alert('No token found. You must be logged in to set credit requirements.');
          return;
        }
        if (!this.requestedCredits) {
          alert("Please enter the requested credits.");
          return;
        }
  
        fetch(`${API_URL}/admin/add-credit-requirement`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ requested_credits: this.requestedCredits })
        })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(() => {
          this.message = 'Credit requirement added successfully';
          this.success = true;
          this.requestedCredits = null; // Reset the input after successful submission
          this.fetchCreditRequirements(); // Refresh the list of credit requirements
        })
        .catch(error => {
          console.error("Failed to add credit requirement:", error);
          this.message = 'Failed to add credit requirement';
          this.success = false;
        });
      },
      fetchCreditRequirements() {
        const token = localStorage.getItem('token');
        if (!token) {
          alert('No token found. You must be logged in to view credit requirements.');
          return;
        }
  
        fetch(`${API_URL}/api/required-credits`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => response.json())
        .then(data => {
          this.creditRequirements = data;
        })
        .catch(error => {
          console.error("Failed to fetch credit requirements:", error);
        });
      },
      editRequirement(creditId) {
        const token = localStorage.getItem('token');
        const newCredits = prompt("Enter the new requested credits:");
        if (!newCredits || isNaN(newCredits) || newCredits <= 0) {
          alert("Invalid credit value.");
          return;
        }
  
        fetch(`${API_URL}/admin/edit-credit-requirement/${creditId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ requested_credits: parseInt(newCredits) })
        })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(() => {
          this.message = 'Credit requirement updated successfully';
          this.success = true;
          this.fetchCreditRequirements(); // Refresh the list of credit requirements
        })
        .catch(error => {
          console.error("Failed to update credit requirement:", error);
          this.message = 'Failed to update credit requirement';
          this.success = false;
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .credits-required-container {
    display: flex;
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f4f5f7;
  }
  
  .main-content {
    width: 100%;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .section {
    margin-bottom: 40px;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  .form-group input[type="number"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .btn-submit {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .table th,
  .table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .table th {
    background-color: #f4f5f7;
    font-weight: bold;
  }
  
  .btn-edit {
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .message {
    margin-top: 20px;
    padding: 10px;
    background-color: #f8f9fa;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .alert {
    margin-top: 20px;
    padding: 10px;
    border-radius: 4px;
  }
  
  .alert-success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }
  
  .alert-danger {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
  }
  </style>
  
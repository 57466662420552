<template>
    <div class="pre-added-images">
      <h2>Manage Pre-added Images</h2>
      <form @submit.prevent="submitImageData" class="form">
        <div class="form-group">
          <label for="image-file">Image File</label>
          <input type="file" id="image-file" @change="onImageFileChange" accept="image/*" required>
        </div>
        <div class="form-group">
          <label for="image-description">Image Description</label>
          <textarea id="image-description" v-model="imageDescription" rows="3"></textarea>
        </div>
        <button type="submit" class="btn-submit" :disabled="!imageFile">Submit</button>
      </form>
  
      <h2>Existing Pre-added Images</h2>
      <table class="image-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Image</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="image in images" :key="image.id">
            <td>{{ image.id }}</td>
            <td><img :src="image.url" alt="Image" class="thumbnail" /></td>
            <td>{{ image.description }}</td>
            <td>
              <button class="btn-delete" @click="deleteImage(image.id)">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import { API_URL } from '@/config';
  
  export default {
    data() {
      return {
        imageFile: null,
        imageDescription: '',
        images: []
      };
    },
    mounted() {
      this.fetchImages();
    },
    methods: {
      onImageFileChange(event) {
        this.imageFile = event.target.files[0];
      },
      submitImageData() {
        const token = localStorage.getItem('token');
        if (!token) {
          alert('No token found. You must be logged in to submit data.');
          return;
        }
  
        const formData = new FormData();
        formData.append('image', this.imageFile);
        formData.append('description', this.imageDescription);
  
        fetch(`${API_URL}/api/preadded-images`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          body: formData,
        })
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then(data => {
            alert(data.message);
            // Reset form fields
            this.imageFile = null;
            this.imageDescription = '';
            // Fetch the updated list of images
            this.fetchImages();
          })
          .catch(error => {
            console.error('Failed to submit image data', error);
            alert('Failed to submit image data');
          });
      },
      fetchImages() {
        const token = localStorage.getItem('token');
        if (!token) {
          alert('No token found. You must be logged in to view images.');
          return;
        }
  
        fetch(`${API_URL}/api/preadded-images`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        })
          .then(response => response.json())
          .then(data => {
            this.images = data;
          })
          .catch(error => {
            console.error('Failed to fetch images', error);
            alert('Failed to fetch images');
          });
      },
      deleteImage(imageId) {
        const token = localStorage.getItem('token');
        if (!token) {
          alert('No token found. You must be logged in to delete images.');
          return;
        }
  
        fetch(`${API_URL}/api/preadded-images/${imageId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        })
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then(data => {
            alert(data.message);
            // Fetch the updated list of images
            this.fetchImages();
          })
          .catch(error => {
            console.error('Failed to delete image', error);
            alert('Failed to delete image');
          });
      }
    }
  };
  </script>
  
  <style scoped>
  .pre-added-images {
    padding: 20px;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  .form-group input[type="file"],
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-group textarea {
    resize: vertical;
  }
  
  button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .image-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .image-table th,
  .image-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .image-table th {
    background-color: #f4f5f7;
    font-weight: bold;
  }
  
  .thumbnail {
    width: 100px;
    height: auto;
  }
  
  .btn-delete {
    padding: 5px 10px;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .btn-delete:hover {
    background-color: #d32f2f;
  }
  </style>
  
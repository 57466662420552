<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="about-us-page">
    <header class="header">
      <div class="header-actions">
        <router-link to="/dashboard" class="login-link" style="font-weight: bold; font-size: 18px; text-decoration: underline;">لنبدأ</router-link>
      </div>

      <nav class="header-nav">
        <a href="#" class="nav-link">الأسعار</a>
        <a href="#" class="nav-link">الأسئلة الشائعة</a>
      </nav>
      <div class="header-icons">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/1909eed33e4c92c0c6a7ae656b371072f6a331d8caa1d3f9aff6a1b1f6fa06f2?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Icon" class="icon" />
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/076ad4d43ff739e5b7165868b496c5cce1c42209ae846a2e33f5034beb9bd8ef?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Logo" class="logo" />
      </div>
    </header>
    <section class="hero-section">
      <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/a9225584de39ad18e8223f8ccc1562ff571d19b3ba99325a03905da7607b5be1?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="" class="hero-background" />
      <h1 class="hero-title">من نحن</h1>
    </section>
    <section class="journey-section">
      <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/0bfa7d748070cee6089edb221384fef9a6b3c0cd55aa4a06c6affa06e5eb2e01?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Journey Illustration" class="journey-illustration" />
    </section>
    <section class="story-section">
      <div class="story-content">
        <div class="story-text">
          <h2 class="story-title">رحلتنا</h2>
          <p class="story-description">
            بدأت قصتنا في 2020، عندما لاحظنا التحديات التي تواجه العلامات التجارية في إنشاء محتوى فيديو جذاب ومؤثر دون الحاجة إلى ميزانيات ضخمة أو خبرات فنية متخصصة. من هنا، وُلدت &quot;إبداعي&quot; لتقديم حلول مبتكرة تدمج بين الذكاء الاصطناعي والإبداع البشري، مما يمكننا من إنتاج فيديوهات فريدة تلائم كل علامة تجارية وتساعدها على التألق.<br />
          </p>
        </div>
        <div class="story-image-container">
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/1bd92921133d464bed0c46402388d5b4af21498c3607d6522aa3dd8731745d41?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Story Illustration" class="story-image" />
        </div>
      </div>
    </section>
    <section class="illustration-section">
      <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/7d20ddf9758689e0eff3c82284a89b61e989da554a75d08fa8e0c77fd3651628?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Illustration" class="illustration" />
    </section>
    <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/24a147e131a9e17efed7a691bcf75b7536ca9ad478218bc311fead3bfc31a7ad?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Illustration" class="full-width-illustration" />
    <section class="join-us-section">
      <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/7bf0ce0d47183ff18f8fd6ae66088616edc4f59a2fd2fd22ad74437356634cb0?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="" class="join-us-background" />
      <div class="join-us-circle"></div>
      <div class="join-us-content">
        <div class="join-us-circle-small"></div>
        <div class="join-us-text">
          <h2 class="join-us-title">انضم إلينا في رحلتنا</h2>
          <p class="join-us-description">
            معًا، يمكننا إعادة تعريف مستقبل التسويق الرقمي. مرحبًا بك في العائلة.
          </p>
          <button class="join-us-button" @click="redirectToRegister">التجربة مجانا</button>
        </div>
      </div>
      <div class="join-us-circles">
        <div class="join-us-circle-large"></div>
        <div class="join-us-circle-medium"></div>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'AboutUsPage',
  setup() {
    const router = useRouter();
    
    const redirectToRegister = () => {
      router.push('/register');
    };

    return {
      redirectToRegister,
    };
  },
});
</script>


  


<style scoped>

.header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(231, 231, 231, 0.2);
  }

.header-actions {
  display: flex;
  gap: 20px;
  font-size: 16px;
}

.login-link {
  padding: 0.75rem 1.5rem;
    border-radius: 72px;
    background-color: #0098fd;
    color: #fff;
    font: 400 16px / 160% Tajawal, sans-serif;
    text-transform: uppercase;
}

.register-button {
  margin: auto 0;
  color: #0098fd;
  font: 400 16px/150% Tajawal, sans-serif;
  text-align: center;
}

.header-nav {
  display: flex;
  gap: 20px;
  margin: auto 0;
  font-size: 16px;
  color: #222;
  font-weight: 400;
  text-align: center;
  line-height: 150%;
}

.nav-link {
  font: 400 16px/150% Tajawal, sans-serif;
}

.header-icons {
  display: flex;
  gap: 10px;
  margin: auto 0;
}

.icon {
  width: 27px;
  aspect-ratio: 1;
  object-fit: contain;
}

.logo {
  width: 80px;
  aspect-ratio: 6.25;
  object-fit: contain;
  margin: auto 0;
}




.about-us-page {
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }
  
  .header {
    border-radius: 498px 0 0 498px;
    box-shadow: 0 2px 5px rgba(231, 231, 231, 0.2);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 80px;
    gap: 20px;
  }
  
  @media (max-width: 991px) {
    .header {
      flex-wrap: wrap;
      padding: 0 20px;
    }
  }
  
  .logo-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .logo-icon {
    width: 27px;
    aspect-ratio: 1;
    object-fit: contain;
  }
  
  .company-name {
    width: 80px;
    aspect-ratio: 6.25;
    object-fit: contain;
  }
  
  .navigation {
    display: flex;
    gap: 20px;
    font-size: 16px;
    color: #222;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    line-height: 150%;
    font-family: Tajawal, sans-serif;
  }
  
  @media (max-width: 991px) {
    .navigation {
      white-space: initial;
    }
  }
  
  .auth-buttons {
    display: flex;
    gap: 20px;
    font-weight: 400;
  }
  
  .sign-in-link {
    color: #0098fd;
    text-align: center;
    margin: auto 0;
    font: 16px/150% Tajawal, sans-serif;
  }
  
  .get-started-button {
    border-radius: 72px;
    background-color: #0098fd;
    color: #fff;
    text-transform: uppercase;
    padding: 28px 38px 29px;
    font: 18px/160% Tajawal, sans-serif;
  }
  
  @media (max-width: 991px) {
    .get-started-button {
      padding: 0 20px;
    }
  }
  
  .hero-section {
    position: relative;
    overflow: hidden;
    align-self: center;
    min-height: 149px;
    width: 100%;
    max-width: 1190px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #303030;
    text-align: center;
    text-transform: capitalize;
    margin: 136px 0 14px;
    padding: 55px 60px 25px;
    font: 500 52px/133.2% Tajawal, sans-serif;
  }
  
  @media (max-width: 991px) {
    .hero-section {
      max-width: 100%;
      font-size: 40px;
      margin-top: 40px;
      padding: 0 20px;
    }
  }
  
  .hero-background {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .hero-title {
    position: relative;
  }
  
  .journey-section {
    border-radius: 300px 0 0 300px;
    background: linear-gradient(179deg, rgba(186, 186, 186, 0.06) 1.2%, rgba(0, 152, 253, 0.04) 37.24%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 56px;
    width: 664px;
    max-width: 100%;
    padding: 70px 60px;
  }
  
  @media (max-width: 991px) {
    .journey-section {
      margin-top: 40px;
      padding: 0 20px;
    }
  }
  
  .journey-illustration {
    width: 500px;
    max-width: 100%;
    aspect-ratio: 1;
    object-fit: contain;
  }
  
  .story-section {
    align-self: center;
    margin-top: 39px;
    width: 100%;
    max-width: 1356px;
  }
  
  @media (max-width: 991px) {
    .story-section {
      max-width: 100%;
    }
  }
  
  .story-content {
    display: flex;
    gap: 20px;
  }
  
  @media (max-width: 991px) {
    .story-content {
      flex-direction: column;
      align-items: stretch;
      gap: 0;
    }
  }
  
  .story-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    margin: auto 0;
    padding: 0 20px;
    width: 50%;
  }
  
  @media (max-width: 991px) {
    .story-text {
      max-width: 100%;
      margin-top: 40px;
      width: 100%;
    }
  }
  
  .story-title {
    color: #303030;
    text-transform: capitalize;
    align-self: end;
    font: 500 40px/133.2% Tajawal, sans-serif;
  }
  
  .story-description {
    color: #000;
    text-align: right;
    margin-top: 44px;
    font: 400 20px/40px Tajawal, sans-serif;
  }
  
  @media (max-width: 991px) {
    .story-description {
      max-width: 100%;
      margin-top: 40px;
    }
  }
  
  .story-image-container {
    border-radius: 300px 0 0 300px;
    background: linear-gradient(179deg, rgba(186, 186, 186, 0.06) 1.2%, rgba(0, 152, 253, 0.04) 37.24%);
    display: flex;
    flex-grow: 1;
    justify-content: center;
    width: 50%;
    padding: 80px 55px;
  }
  
  @media (max-width: 991px) {
    .story-image-container {
      max-width: 100%;
      margin-top: 40px;
      padding: 0 20px;
      width: 100%;
    }
  }
  
  .story-image {
    width: 100%;
    margin-top: 63px;
    aspect-ratio: 1.49;
    object-fit: contain;
  }
  
  @media (max-width: 991px) {
    .story-image {
      max-width: 100%;
      margin-top: 40px;
    }
  }
  
  .illustration-section {
    border-radius: 300px 0 0 300px;
    background: linear-gradient(179deg, rgba(186, 186, 186, 0.06) 1.2%, rgba(0, 152, 253, 0.04) 37.24%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 39px;
    width: 665px;
    max-width: 100%;
    padding: 71px 60px;
  }
  
  @media (max-width: 991px) {
    .illustration-section {
      padding: 0 20px;
    }
  }
  
  .illustration {
    width: 500px;
    max-width: 100%;
    aspect-ratio: 1;
    object-fit: contain;
  }
  
  .full-width-illustration {
    width: 100%;
    margin-top: 39px;
    aspect-ratio: 2.22;
    object-fit: contain;
  }
  
  @media (max-width: 991px) {
    .full-width-illustration {
      max-width: 100%;
    }
  }
  
  .join-us-section {
    position: relative;
    overflow: hidden;
    align-self: center;
    display: flex;
    min-height: 311px;
    margin-top: 39px;
    width: 100%;
    justify-content: space-between;
    padding: 0 20px;
    gap: 20px;
  }
  
  @media (max-width: 991px) {
    .join-us-section {
      max-width: 100%;
      flex-wrap: wrap;
    }
  }
  
  .join-us-background {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .join-us-circle {
    position: relative;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    align-self: end;
    margin-top: 156px;
    width: 158px;
    height: 158px;
  }
  
  @media (max-width: 991px) {
    .join-us-circle {
      margin-top: 40px;
    }
  }
  
  .join-us-content {
    position: relative;
    align-self: start;
    display: flex;
    flex-direction: column;
    color: #fff;
    font-weight: 400;
  }
  
  @media (max-width: 991px) {
    .join-us-content {
      max-width: 100%;
    }
  }
  
  .join-us-circle-small {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    width: 138px;
    height: 56px;
  }
  
  .join-us-text {
    align-self: end;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
    width: 575px;
    max-width: 100%;
    padding: 0 1px;
  }
  
  .join-us-title {
    text-align: center;
    font: 500 40px/132% Tajawal, sans-serif;
  }
  
  .join-us-description {
    text-align: center;
    align-self: stretch;
    font: 20px/53px Tajawal, sans-serif;
  }
  
  @media (max-width: 991px) {
    .join-us-description {
      max-width: 100%;
    }
  }
  
  .join-us-button {
    border-radius: 72px;
    background-color: #fff;
    margin-top: 29px;
    color: #0098fd;
    text-transform: uppercase;
    padding: 28px 49px 29px;
    font: 18px/160% Tajawal, sans-serif;
  }
  
  @media (max-width: 991px) {
    .join-us-button {
      padding: 0 20px;
    }
  }
  
  .join-us-circles {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .join-us-circle-large {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    align-self: end;
    width: 164px;
    height: 164px;
  }
</style>

  

  
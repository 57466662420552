<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="activation-code-container">
    <div class="activation-code-content">
      <div class="activation-code-wrapper">
        <div class="activation-code-column">
          <div class="activation-code-info">
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f71e6eb12d9eabd08f6c8f796b8ab5e9c2bea6392f77fbb4ba68b721a967cba4?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&"
              alt="Activation code icon"
              class="activation-code-icon"
            />
            <p class="activation-code-description">
              لقد أرسلنا رمز التفعيل المكون من 4 أرقام <br />إلى
              <span class="activation-code-email">{{ userEmail }}</span>
            </p>
            <div class="activation-code-inputs">
              <div
                v-for="(digit, index) in 4"
                :key="index"
                class="activation-code-input-wrapper"
              >
                <input
                  v-model="verificationCode[index]"
                  type="text"
                  maxlength="1"
                  class="activation-code-input"
                  @input="handleInputChange(index)"
                  :ref="(el) => { if (index === currentInputIndex) el.focus() }"
                />
              </div>
            </div>
            <button @click="submitVerificationCode" class="activation-code-submit">
              تاكيد الرمز
            </button>
            <div class="activation-code-resend">
              <span class="activation-code-resend-link">إعادة إرسال الرمز</span>
              <span class="activation-code-resend-text">لم تتلقى الرمز؟</span>
            </div>
          </div>
        </div>
        <div class="activation-code-image-column">
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/6f79a99596c963a878d9c70e08b561ef68807dec882a3d97f9e56db37f2867b5?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&"
            alt="Activation code illustration"
            class="activation-code-illustration"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import AuthService from '@/services/authService';
import { API_URL } from '@/config';

export default {
  setup() {
    const router = useRouter();
    const verificationCode = ref(['', '', '', '']);
    const currentInputIndex = ref(0);
    const errorMessage = ref('');

    const handleInputChange = (index) => {
      if (verificationCode.value[index].length === 1) {
        const nextIndex = index + 1;
        if (nextIndex < 4) {
          currentInputIndex.value = nextIndex;
        }
      }
    };

    const submitVerificationCode = async () => {
      if (!AuthService.isLoggedIn()) {
        console.error('No valid session found, redirecting to login.');
        router.push('/login');
        return;
      }

      const token = AuthService.getToken(); // Get the token using AuthService
      const code = verificationCode.value.join('');

      console.log("JWT Token:", token);
      console.log("Verification Code:", code);

      if (!token) {
        console.error('JWT Token is missing, redirecting to login.');
        router.push('/login');
        return;
      }

      try {
        const body = JSON.stringify({
          verification_code: code
        });

        console.log("Request body:", body);

        const response = await fetch(`${API_URL}/verify`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`  // Use the token
          },
          body: body
        });

        console.log("Response status:", response.status);
        console.log("Response headers:", response.headers);

        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }

        const data = await response.json();
        console.log("Response from server:", data);

        if (data.success) {
          console.log("Verification successful, redirecting to:", data.redirect);
          router.push(data.redirect);
        } else {
          errorMessage.value = data.message || 'Verification failed, please try again.';
          console.error("Verification failed:", errorMessage.value);
        }
      } catch (error) {
        console.error('Error submitting verification code:', error);
        errorMessage.value = error.message || 'An error occurred. Please try again.';
      }
    };

    const userEmail = computed(() => {
      return AuthService.getUserEmail();
    });

    return {
      verificationCode,
      currentInputIndex,
      errorMessage,
      handleInputChange,
      submitVerificationCode,
      userEmail,
    };
  },
};
</script>











  
<style scoped>
/* General Styles */
.activation-code-container {
  background-color: var(--color-whites-White, #fff);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 60px;
  font-family: 'Tajawal', sans-serif;
}

@media (max-width: 991px) {
  .activation-code-container {
    padding: 40px 20px;
  }
}

.activation-code-content {
  margin-top: 152px;
  width: 100%;
  max-width: 1273px;
}

@media (max-width: 991px) {
  .activation-code-content {
    max-width: 100%;
    margin-top: 40px;
  }
}

.activation-code-wrapper {
  display: flex;
  gap: 20px;
  align-items: center;
}

@media (max-width: 767px) {
  .activation-code-wrapper {
    flex-direction: column;
    align-items: stretch;
    gap: 40px;
  }
}

/* Column Styles */
.activation-code-column {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 35%;
  margin-left: 0;
}

@media (max-width: 767px) {
  .activation-code-column {
    width: 100%;
  }
}

.activation-code-image-column {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 65%;
  margin-left: 20px;
}

@media (max-width: 767px) {
  .activation-code-image-column {
    width: 100%;
  }
}

/* Info Section */
.activation-code-info {
  align-items: center;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  margin: auto 0;
}

@media (max-width: 767px) {
  .activation-code-info {
    margin-top: 0;
  }
}

.activation-code-icon {
  aspect-ratio: 1.61;
  object-fit: auto;
  object-position: center;
  width: 119px;
  max-width: 100%;
}

.activation-code-description {
  color: #0098fd;
  text-align: center;
  margin-top: 16px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
}

.activation-code-email {
  color: rgba(0, 152, 253, 1);
}

/* Input Fields */
.activation-code-inputs {
  display: flex;
  margin-top: 16px;
  gap: 16px;
  justify-content: center;
}

.activation-code-input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.activation-code-input {
  border-radius: 8px;
  border: 1px solid rgba(35, 40, 51, 0.1);
  background-color: var(--color-whites-White, #fff);
  width: 48px;
  height: 48px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #232833;
  outline: none;
  transition: border-color 0.3s ease;
}

.activation-code-input:focus {
  border-color: #0098fd;
}

/* Submit Button */
.activation-code-submit {
  border-radius: 8px;
  background-color: var(--1, #0098fd);
  align-self: stretch;
  margin-top: 24px;
  height: 48px;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.activation-code-submit:hover {
  background-color: #0081d6;
}

/* Resend Link */
.activation-code-resend {
  display: flex;
  margin-top: 16px;
  gap: 4px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 150%;
  justify-content: center;
}

.activation-code-resend-link {
  color: var(--1, #0098fd);
  cursor: pointer;
  transition: color 0.3s ease;
}

.activation-code-resend-link:hover {
  color: #0081d6;
}

.activation-code-resend-text {
  color: #393939;
}

/* Illustration */
.activation-code-illustration {
  aspect-ratio: 1.41;
  object-fit: auto;
  object-position: center;
  width: 100%;
  flex-grow: 1;
  max-height: 400px;
  object-fit: contain;
}

@media (max-width: 767px) {
  .activation-code-illustration {
    max-height: 300px;
    margin-top: 0;
  }
}
</style>
<template>
  <div class="admin-container">
    <div class="main-content">
      <section id="add-template" class="section">
        <h2>Add Design Template</h2>
        <form @submit.prevent="submitDesignTemplate" class="form">
          <div class="form-group">
            <label for="creatomate-template-id">Creatomate Template ID</label>
            <input type="text" id="creatomate-template-id" v-model="creatomateTemplateId" required>
          </div>
          <div class="form-group">
            <label for="design-name">Design Name</label>
            <input type="text" id="design-name" v-model="designName" required>
          </div>
          <div class="form-group">
            <label for="design-description">Design Description</label>
            <textarea id="design-description" v-model="designDescription" rows="3" required></textarea>
          </div>
          <div class="form-group">
            <label for="video-preview">Video Preview</label>
            <input type="file" id="video-preview" @change="onVideoPreviewChange" accept="video/*" required>
          </div>
          <div class="form-group">
            <label for="num-images">Number of Images</label>
            <input type="number" id="num-images" v-model.number="numImages" min="1" required>
          </div>
          <div class="form-group">
            <label for="num-texts">Number of Texts</label>
            <input type="number" id="num-texts" v-model.number="numTexts" min="1" required>
          </div>
          <div class="form-group">
            <label for="dynamic-element-ids">Dynamic Element IDs</label>
            <span class="help-icon" @click="showHelp = !showHelp">See Example Here Click Here ?!</span>
            <textarea id="dynamic-element-ids" v-model="dynamicElementIds" rows="3"></textarea>
          </div>
          <button type="submit" class="btn btn-primary" :disabled="!videoPreview">Submit</button>
        </form>
      </section>

      <section id="template-list" class="section">
        <h2>Design Templates</h2>
        <table class="template-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Creatomate ID</th>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="template in templates" :key="template.id">
              <td>{{ template.id }}</td>
              <td>{{ template.creatomate_template_id }}</td>
              <td>{{ template.name }}</td>
              <td>
                <button class="btn-edit" @click="openEditModal(template)">Edit</button>
                <button class="btn-delete" @click="deleteDesignTemplate(template.id)">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </section>



<!-- Help Pop-up -->
<div v-if="showHelp" class="help-popup">
  <div class="help-content">
    <span class="close" @click="showHelp = false">&times;</span>
    <h3>How to Add Dynamic Elements to Your Video Template</h3>
    <p>To make your video template interactive and customizable, you need to provide information about the dynamic elements (images, text, audio) in your template. Here's how:</p>
    <ol>
      <li>Count the number of images and text elements in your video template.</li>
      <li>In the "Number of Images" field, enter the total number of images in your template.</li>
      <li>In the "Number of Texts" field, enter the total number of text elements in your template.</li>
      <li>In the "Dynamic Element IDs" field, provide the unique identifiers (IDs) for each dynamic element in your template. Use the following format:</li>
      <pre><code>{
  "template_id": "your_template_id",
  "elements": {
    "product_name_key": "ID_for_product_name",
    "product_price_key": "ID_for_product_price",
    "website_key": "ID_for_website",
    "audio_key": "ID_for_audio",
    "main_image_key": "ID_for_main_image",
    "secondary_image_key": "ID_for_secondary_image",
    "text_1_key": "ID_for_text_1",
    "text_2_key": "ID_for_text_2"
  }
}</code></pre>
      <li>Replace "your_template_id" with the actual ID of your video template.</li>
      <li>Replace each "ID_for_..." with the unique identifier for that specific element in your template.</li>
      <li>If you have additional text elements, add them using the format "text_X_key", where X is a number (e.g., "text_3_key", "text_4_key", etc.).</li>
    </ol>
    <p>Here's an example of what the "Dynamic Element IDs" field might look like:</p>
    <pre><code>{
  "template_id": "your_template_id",
  "elements": {
    "product_price_key": "abc123",
    "website_key": "def456",
    "main_image_key": "ghi789",
    "audio_key": "jkl012",
    "text_1_key": "mno345",
    "text_2_key": "pqr678"
  }
}</code></pre>
    <p>Important: Make sure the number of images and text elements you specify in the "Number of Images" and "Number of Texts" fields match the actual number of images and text elements in your video template. This is essential for your template to work correctly.</p>
  </div>
</div>


     
    </div>

    <!-- Edit Template Modal -->
    <div v-if="showEditModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeEditModal">&times;</span>
        <h2>Edit Design Template</h2>
        <form @submit.prevent="updateDesignTemplate" class="form">
          <div class="form-group">
            <label for="template-id">Template ID</label>
            <input type="number" id="template-id" v-model.number="editTemplateId" required disabled>
          </div>
          <div class="form-group">
            <label for="edit-creatomate-template-id">Creatomate Template ID</label>
            <input type="text" id="edit-creatomate-template-id" v-model="editCreatomateTemplateId">
          </div>
          <div class="form-group">
            <label for="edit-design-name">Design Name</label>
            <input type="text" id="edit-design-name" v-model="editDesignName">
          </div>
          <div class="form-group">
            <label for="edit-design-description">Design Description</label>
            <textarea id="edit-design-description" v-model="editDesignDescription" rows="3"></textarea>
          </div>
          <div class="form-group">
            <label for="edit-design-preview-url">Design Preview URL</label>
            <input type="text" id="edit-design-preview-url" v-model="editDesignPreviewUrl">
          </div>
          <div class="form-group">
            <label for="edit-num-images">Number of Images</label>
            <input type="number" id="edit-num-images" v-model.number="editNumImages" min="1">
          </div>
          <div class="form-group">
            <label for="edit-num-texts">Number of Texts</label>
            <input type="number" id="edit-num-texts" v-model.number="editNumTexts" min="1">
          </div>
          <div class="form-group">
            <label for="edit-dynamic-element-ids">Dynamic Element IDs</label>
            <textarea id="edit-dynamic-element-ids" v-model="editDynamicElementIds" rows="3"></textarea>
          </div>
          <div class="form-group">
            <label for="edit-created-at">Created At</label>
            <input type="text" id="edit-created-at" v-model="editCreatedAt" disabled>
          </div>
          <div class="form-group">
            <label for="edit-updated-at">Updated At</label>
            <input type="text" id="edit-updated-at" v-model="editUpdatedAt" disabled>
          </div>
          <div class="form-actions">
            <button type="button" class="btn-cancel" @click="closeEditModal">Cancel</button>
            <button type="submit" class="btn-update">Update</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { API_URL } from '@/config';

export default {
  data() {
    return {
      creatomateTemplateId: '',
      designName: '',
      designDescription: '',
      videoPreview: null,
      numImages: 1,
      numTexts: 1,
      dynamicElementIds: '',
      templates: [],
      showEditModal: false,
      showHelp: false,  // Added data property for showing help
      editTemplateId: null,
      editCreatomateTemplateId: '',
      editDesignName: '',
      editDesignDescription: '',
      editDesignPreviewUrl: '',
      editNumImages: 1,
      editNumTexts: 1,
      editDynamicElementIds: '',
      editVideoPreview: null,
      editCreatedAt: '',
      editUpdatedAt: ''
    };
  },
  mounted() {
    this.fetchDesignTemplates();
  },
  methods: {
    deleteDesignTemplate(templateId) {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to delete templates.');
        return;
      }

      const confirmed = confirm('Are you sure you want to delete this design template?');
      if (!confirmed) {
        return;
      }

      fetch(`${API_URL}/api/design-templates/${templateId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          alert(data.message);
          // Refresh the design templates list
          this.fetchDesignTemplates();
        })
        .catch(error => {
          console.error('Failed to delete design template', error);
          alert('Failed to delete design template');
        });
    },
    onVideoPreviewChange(event) {
      this.videoPreview = event.target.files[0];
    },
    onEditVideoPreviewChange(event) {
      this.editVideoPreview = event.target.files[0];
    },
    submitDesignTemplate() {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to submit data.');
        return;
      }

      const formData = new FormData();
      formData.append('creatomate_template_id', this.creatomateTemplateId);
      formData.append('design_name', this.designName);
      formData.append('design_description', this.designDescription);
      formData.append('num_images', this.numImages);
      formData.append('num_texts', this.numTexts);
      formData.append('dynamic_element_ids', this.dynamicElementIds);
      formData.append('video_preview', this.videoPreview);

      fetch(`${API_URL}/api/insert-design-template`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          alert(data.message);
          // Reset form fields
          this.creatomateTemplateId = '';
          this.designName = '';
          this.designDescription = '';
          this.videoPreview = null;
          this.numImages = 1;
          this.dynamicElementIds = '';
          // Refresh the design templates list
          this.fetchDesignTemplates();
        })
        .catch(error => {
          console.error('Failed to submit design template data', error);
          alert('Failed to submit design template data');
        });
    },
    updateDesignTemplate() {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to update data.');
        return;
      }

      const formData = new FormData();
      formData.append('creatomate_template_id', this.editCreatomateTemplateId);
      formData.append('design_name', this.editDesignName);
      formData.append('design_description', this.editDesignDescription);
      formData.append('design_preview_url', this.editDesignPreviewUrl);
      formData.append('num_images', this.editNumImages);
      formData.append('num_texts', this.editNumTexts);
      formData.append('dynamic_element_ids', this.editDynamicElementIds);

      if (this.editVideoPreview) {
        formData.append('video_preview', this.editVideoPreview);
      }

      fetch(`${API_URL}/api/design-templates/${this.editTemplateId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          alert(data.message);
          // Reset form fields and close the modal
          this.editTemplateId = null;
          this.editCreatomateTemplateId = '';
          this.editDesignName = '';
          this.editDesignDescription = '';
          this.editDesignPreviewUrl = '';
          this.editNumImages = 1;
          this.editDynamicElementIds = '';
          this.editVideoPreview = null;
          this.showEditModal = false;
          // Refresh the design templates list
          this.fetchDesignTemplates();
        })
        .catch(error => {
          console.error('Failed to update design template', error);
          alert('Failed to update design template');
        });
    },
    fetchDesignTemplates() {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to fetch templates.');
        return;
      }

      fetch(`${API_URL}/api/design-templates`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          this.templates = data;
        })
        .catch(error => {
          console.error('Failed to fetch design templates', error);
          alert('Failed to fetch design templates');
        });
    },
    openEditModal(template) {
      this.editTemplateId = template.id;
      this.editCreatomateTemplateId = template.creatomate_template_id;
      this.editDesignName = template.name;
      this.editDesignDescription = template.description;
      this.editDesignPreviewUrl = template.preview_url;
      this.editNumImages = template.num_images;
      this.editNumTexts = template.num_texts;
      this.editDynamicElementIds = template.dynamic_element_ids;
      this.editCreatedAt = template.created_at;
      this.editUpdatedAt = template.updated_at;
      this.editVideoPreview = null;
      this.showEditModal = true;
    },
    closeEditModal() {
      this.showEditModal = false;
    }
  },
};
</script>


<style scoped>
body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
}

.help-icon {
  cursor: pointer;
  font-size: 1.5em;
  margin-left: 5px;
  color: #007bff;
  transition: color 0.3s, transform 0.3s;
}

.help-icon:hover {
  color: #0056b3;
  transform: scale(1.2);
}

.help-popup {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #007bff;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: fadeIn 0.3s;
}

.help-content {
  position: relative;
}

.help-content .close {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  font-size: 1.5em;
  color: #ff0000;
  transition: color 0.3s, transform 0.3s;
}

.help-content .close:hover {
  color: #cc0000;
  transform: scale(1.2);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.admin-container {
  display: flex;
  padding: 20px;
  background-color: #f4f5f7;
}

.main-content {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section {
  margin-bottom: 40px;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.template-table {
  width: 100%;
  border-collapse: collapse;
}

.template-table th,
.template-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.template-table th {
  background-color: #f4f5f7;
  font-weight: bold;
}

.form {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group textarea {
  resize: vertical;
}

button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #45a049;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.btn-edit {
  padding: 5px 10px;
  background-color: #2196F3;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.btn-edit:hover {
  background-color: #0c7cd5;
}

.btn-delete {
  padding: 5px 10px;
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
}

.btn-delete:hover {
  background-color: #d32f2f;
}

/* Modal Styles */
.modal {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-title {
  font-size: 24px;
  margin: 0;
}

.close {
  font-size: 28px;
  font-weight: bold;
  color: #999;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close:hover {
  color: #333;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.btn-cancel {
  background-color: #f5f5f5;
  color: #333;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.btn-cancel:hover {
  background-color: #e0e0e0;
}

.btn-update {
  background-color: #4CAF50;
  color: #fff;
  transition: background-color 0.3s ease;
}

.btn-update:hover {
  background-color: #45a049;
}
</style>


<template>
    <div class="admin-login">
      <div class="login-container">
        <h2>Admin Login</h2>
        <form @submit.prevent="login">
          <div class="input-group">
            <label for="username">Username:</label>
            <input type="text" v-model="username" required />
          </div>
          <div class="input-group">
            <label for="password">Password:</label>
            <input type="password" v-model="password" required />
          </div>
          <button type="submit">Login</button>
        </form>
        <p v-if="error" class="error-message">{{ error }}</p>
      </div>
    </div>
    </template>
    <script>
    import AuthService from '@/services/authService';
    
    export default {
      data() {
        return {
          username: '',
          password: '',
          error: '',
        };
      },
      methods: {
        async login() {
          try {
            const credentials = {
              username: this.username,
              password: this.password,
            };
            const response = await AuthService.adminLogin(credentials);
            console.log('Admin login successful:', response);
            // Store the access token and user data in local storage
            AuthService.login(response);
            // Redirect to the admin dashboard
            this.$router.push('/admin/dashboard');
          } catch (err) {
            console.error('Admin login failed:', err);
            this.error = 'Login failed. Please try again.';
          }
        },
      },
    };
    </script>
   <style scoped>
   .admin-login {
     display: flex;
     justify-content: center;
     align-items: center;
     height: 100vh;
     background-color: #f5f5f5;
   }
   
   .login-container {
     background-color: #ffffff;
     padding: 40px;
     border-radius: 4px;
     box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
     max-width: 400px;
     width: 100%;
     animation: fadeIn 0.5s ease;
   }
   
   @keyframes fadeIn {
     0% {
       opacity: 0;
       transform: translateY(-10px);
     }
     100% {
       opacity: 1;
       transform: translateY(0);
     }
   }
   
   h2 {
     text-align: center;
     margin-bottom: 20px;
     color: #333333;
     font-size: 24px;
     font-weight: 600;
   }
   
   .input-group {
     margin-bottom: 20px;
   }
   
   label {
     display: block;
     font-weight: 500;
     margin-bottom: 5px;
     color: #555555;
   }
   
   input {
     width: 100%;
     padding: 12px;
     border: 1px solid #dddddd;
     border-radius: 4px;
     transition: border-color 0.3s ease;
   }
   
   input:focus {
     outline: none;
     border-color: #4c7cff;
   }
   
   button {
     width: 100%;
     padding: 12px;
     background-color: #4c7cff;
     color: #ffffff;
     border: none;
     border-radius: 4px;
     cursor: pointer;
     transition: background-color 0.3s ease;
     font-weight: 600;
   }
   
   button:hover {
     background-color: #3d64c8;
   }
   
   .error-message {
     color: #ff4d4d;
     text-align: center;
     margin-top: 10px;
   }
   </style>
<template>
  <div class="design-template-container">
    <div v-for="template in templates" :key="template.id" class="template-item" @click="selectTemplate(template)">
      <div class="template-preview">
        <video v-if="template.preview_url.endsWith('.mp4')" :src="template.preview_url" controls></video>
        <img v-else :src="template.preview_url" :alt="template.name" />
      </div>
      <h4>{{ template.name }}</h4>
      <p>{{ template.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DesignTemplate',
  props: {
    templates: {
      type: Array,
      required: true
    }
  },
  methods: {
    selectTemplate(template) {
      this.$emit('template-selected', {
        template: template,
        modifications: this.$store.state.modifications
      });
    }
  }
}
</script>

<style scoped>
.design-template-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
}

.template-item {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 1rem;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  background: #fff;
}

.template-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.template-preview {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  margin-bottom: 1rem;
  overflow: hidden;
  border-radius: 10px;
}

.template-preview video,
.template-preview img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

h4 {
  margin: 0.5rem 0;
  font-size: 1.2rem;
  color: #333;
}

p {
  font-size: 0.9rem;
  color: #666;
}
</style>

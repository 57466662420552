<template>
  <div id="app">
    <loader-main v-if="isLoading" />
    <div v-else>
      <header v-if="showHeader && $route.meta.requiresAuth">
        <HeaderMain />
      </header>
      <ReelHeader v-else-if="isReelPage" />
      <div class="container" v-if="showHeaderAndSidebar && $route.path !== '/submit'">
        <SidebarOriginal />
        <router-view></router-view>
      </div>
      <router-view v-else></router-view>
      <FooterMain v-if="!isAdminPage && !isReelPage" />
    </div>
  </div>
</template>

<script>
import { ref, computed, provide, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import HeaderMain from './components/HeaderMain.vue';
import SidebarOriginal from './components/SidebarOriginal.vue';
import { ReelHeader } from '@/components/reels/ReelPage.vue';  // Import ReelHeader from ReelPage
import LoaderMain from './components/LoaderMain.vue';
import FooterMain from './components/FooterMain.vue';
import { API_URL } from '@/config'; // Ensure the path is correct

export default {
  name: 'App',
  components: {
    HeaderMain,
    ReelHeader,
    SidebarOriginal,
    LoaderMain,
    FooterMain
  },
  setup() {
    const route = useRoute();
    const showHeaderAndSidebar = computed(() => route.meta.showHeaderAndSidebar);
    const showHeader = computed(() => route.meta.showHeader !== false);
    const isLoading = ref(false);
    provide('isLoading', isLoading);

    const isAdminPage = computed(() => {
      return route.path.startsWith('/admin');
    });

    const isReelPage = computed(() => {
      const reelRoutes = ['/landing-page-reel', '/Main-Reel-Page', '/Reel_Projects_Page', '/reel'];
      return reelRoutes.some(path => route.path.startsWith(path));
    });

    const logoUrl = ref('');
    const siteTitle = ref('');
    const faviconUrl = ref('');

    const fetchSiteSettings = async () => {
      try {
        const response = await fetch(`${API_URL}/api/public/site-settings`);
        if (response.ok) {
          const data = await response.json();
          logoUrl.value = data.logo_url || '';
          siteTitle.value = data.site_title || '';
          faviconUrl.value = data.favicon_url || ''; // Store the favicon URL
        } else {
          console.error('Failed to fetch site settings:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching site settings:', error);
      }
    };

    watch(siteTitle, (newTitle) => {
      document.title = newTitle;
    });

    onMounted(() => {
      fetchSiteSettings();

      // Update the favicon dynamically
      const faviconLink = document.querySelector('link[rel="icon"]');
      if (faviconLink) {
        watch(faviconUrl, (newFaviconUrl) => {
          faviconLink.href = newFaviconUrl;
        });
      }
    });

    return {
      showHeaderAndSidebar,
      showHeader,
      isLoading,
      isAdminPage,
      isReelPage,
      logoUrl,
      siteTitle
    };
  }
};
</script>

<style scoped>
html, body {
  overflow-x: hidden;
}
.container {
  display: flex;
  background: #ffffff;
}
</style>
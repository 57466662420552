<template>
    <div class="admin-dashboard">
      <div class="main-content">
        <section id="stripe-plans" class="section">
          <h2>Stripe Plan Products</h2>
          <table class="table" v-if="plans.length">
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Price</th>
                <th>Credits</th>
                <th>Interval</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(plan, index) in plans" :key="index">
                <td>{{ plan.name }}</td>
                <td>{{ plan.description }}</td>
                <td>{{ plan.price }}</td>
                <td>{{ plan.credits_amount }}</td>
                <td>{{ plan.interval }} ({{ plan.interval_count }})</td>
                <td>
                  <button class="btn-remove" @click="removePlan(plan.id)">Remove</button>
                </td>
              </tr>
            </tbody>
          </table>
          <p v-else>No plans available</p>
          <form @submit.prevent="submitPlanProductForm" class="form">
            <h3>Add Stripe Plan Product</h3>
            <div class="form-group">
              <label for="plan-name">Plan Name</label>
              <input type="text" id="plan-name" v-model="planName" required>
            </div>
            <div class="form-group">
              <label for="plan-description">Plan Description</label>
              <textarea id="plan-description" v-model="planDescription"></textarea>
            </div>
            <div class="form-group">
              <label for="stripe-product-id">Stripe Product ID</label>
              <input type="text" id="stripe-product-id" v-model="stripeProductId" required>
            </div>
            <div class="form-group">
              <label for="stripe-plan-id">Stripe Plan ID</label>
              <input type="text" id="stripe-plan-id" v-model="stripePlanId" required>
            </div>
            <div class="form-group">
              <label for="price">Price</label>
              <input type="number" id="price" v-model="price" required>
            </div>
            <div class="form-group">
              <label for="credits-amount">Credits Amount</label>
              <input type="number" id="credits-amount" v-model="creditsAmount" required>
            </div>
            <div class="form-group">
              <label for="interval">Interval</label>
              <input type="text" id="interval" v-model="interval" required>
            </div>
            <div class="form-group">
              <label for="interval-count">Interval Count</label>
              <input type="number" id="interval-count" v-model="intervalCount" required>
            </div>
            <div class="form-group">
              <label for="custom-description">Custom Description</label>
              <textarea id="custom-description" v-model="customDescription"></textarea>
            </div>
            <div class="form-group">
              <label for="plan-image">Plan Image</label>
              <input type="file" id="plan-image" @change="handleImageUpload">
            </div>
            <button type="submit" class="btn-submit">Add Plan</button>
          </form>
        </section>
  
        <section id="one-time-purchases" class="section">
          <h2>One-Time Purchase Products</h2>
          <table class="table" v-if="oneTimePurchases.length">
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Price</th>
                <th>Credits</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(product, index) in oneTimePurchases" :key="index">
                <td>{{ product.name }}</td>
                <td>{{ product.description }}</td>
                <td>{{ product.price }}</td>
                <td>{{ product.credits_amount }}</td>
              </tr>
            </tbody>
          </table>
          <p v-else>No one-time purchase products available</p>
  
          <form @submit.prevent="submitProductForm" class="form">
            <h3>Add One-Time Purchase Product</h3>
            <div class="form-group">
              <label for="product-name">Product Name</label>
              <input type="text" id="product-name" v-model="productName" required>
            </div>
            <div class="form-group">
              <label for="product-description">Product Description</label>
              <textarea id="product-description" v-model="productDescription"></textarea>
            </div>
            <div class="form-group">
              <label for="product-price">Price</label>
              <input type="number" id="product-price" v-model="productPrice" required>
            </div>
            <div class="form-group">
              <label for="credits-amount">Credits Amount</label>
              <input type="number" id="credits-amount" v-model="creditsAmount" required>
            </div>
            <div class="form-group">
              <label for="stripe-product-id">Stripe Product ID</label>
              <input type="text" id="stripe-product-id" v-model="stripeProductId" required>
            </div>
            <button type="submit" class="btn-submit">Add Product</button>
          </form>
        </section>
      </div>
    </div>
  </template>
  
  
  <script>
import { API_URL } from '@/config';

export default {
  data() {
    return {
      plans: [],
      oneTimePurchases: [],
      planName: '',
      planDescription: '',
      stripeProductId: '',
      stripePlanId: '',
      price: '',
      creditsAmount: '',
      interval: '',
      intervalCount: 1,
      customDescription: '',
      productName: '',
      productDescription: '',
      productPrice: '',
      planImage: null, // New state for image
    };
  },
  created() {
    this.fetchPlans();
    this.fetchOneTimePurchases();
  },
  methods: {
    async removePlan(planId) {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('No token available. User must be logged in to remove a plan.');
          return;
        }

        const confirmed = window.confirm('Are you sure you want to remove this plan?');
        if (!confirmed) {
          return;
        }

        const response = await fetch(`${API_URL}/admin/stripe_plan_product/${planId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          },
        });

        const data = await response.json();
        if (response.ok) {
          alert('Plan removed successfully');
          this.fetchPlans(); // Refresh plans
        } else {
          alert(data.error || 'An error occurred');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred');
      }
    },
  
    handleImageUpload(event) {
      this.planImage = event.target.files[0];
    },
    async fetchPlans() {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token available. User must be logged in to fetch plans.');
        return;
      }
      try {
        const response = await fetch(`${API_URL}/stripe_plan_products`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });
        const data = await response.json();
        if (response.ok) {
          this.plans = data;
        } else {
          console.error('Error fetching plans:', data);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
    async fetchOneTimePurchases() {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token available. User must be logged in to fetch one-time purchases.');
        return;
      }
      try {
        const response = await fetch(`${API_URL}/one_time_purchases`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });
        const data = await response.json();
        if (response.ok) {
          this.oneTimePurchases = data;
        } else {
          console.error('Error fetching one-time purchases:', data);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
    async submitPlanProductForm() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('No token available. User must be logged in to submit form.');
          return;
        }

        const formData = new FormData();
        formData.append('name', this.planName);
        formData.append('description', this.planDescription);
        formData.append('stripe_product_id', this.stripeProductId);
        formData.append('stripe_plan_id', this.stripePlanId);
        formData.append('price', this.price);
        formData.append('credits_amount', this.creditsAmount);
        formData.append('interval', this.interval);
        formData.append('interval_count', this.intervalCount);
        formData.append('custom_description', this.customDescription);

        if (this.planImage) {
          formData.append('image', this.planImage);
        }

        const response = await fetch(`${API_URL}/admin/stripe_plan_product`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          body: formData,
        });

        const data = await response.json();
        if (response.ok) {
          alert('Stripe plan product added successfully');
          this.planName = '';
          this.planDescription = '';
          this.stripeProductId = '';
          this.stripePlanId = '';
          this.price = '';
          this.creditsAmount = '';
          this.interval = '';
          this.intervalCount = 1;
          this.customDescription = '';
          this.planImage = null; // Reset the image
          this.fetchPlans(); // Refresh plans
        } else {
          alert(data.error || 'An error occurred');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred');
      }
    },
    async submitProductForm() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('No token available. User must be logged in to submit form.');
          return;
        }
        const response = await fetch(`${API_URL}/admin/one_time_purchase`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            name: this.productName,
            description: this.productDescription,
            price: this.productPrice,
            credits_amount: this.creditsAmount,
            stripe_product_id: this.stripeProductId,
          }),
        });
        const data = await response.json();
        if (response.ok) {
          alert('One-time purchase product added successfully');
          this.productName = '';
          this.productDescription = '';
          this.productPrice = '';
          this.creditsAmount = '';
          this.stripeProductId = '';
          this.fetchOneTimePurchases(); // Refresh products
        } else {
          alert(data.error || 'An error occurred');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred');
      }
    },
  },
};
</script>

  
  
  
  
<style scoped>
.admin-dashboard {
  display: flex;
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f4f5f7;
}

.main-content {
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section {
  margin-bottom: 40px;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.table th,
.table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.table th {
  background-color: #f4f5f7;
  font-weight: bold;
}

.form {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.form h3 {
  font-size: 20px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group textarea {
  resize: vertical;
}

.btn-submit,
.btn-remove {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-remove {
  background-color: #dc3545;
}

.btn-submit:hover,
.btn-remove:hover {
  opacity: 0.8;
}
</style>
<template>
    <div class="dashboard-home">
      <div class="grid-container">
        <metric-card v-for="metric in metrics" :key="metric.title" :title="metric.title" :value="metric.value ?? 0" :icon="metric.icon"></metric-card>
      </div>
    </div>
  </template>
  
  <script>
  import MetricCard from './MetricCard.vue';
  import { API_URL } from '@/config';
  import AuthService from '@/services/authService';
  
  export default {
    name: 'DashboardHome',
    components: {
      MetricCard,
    },
    data() {
      return {
        metrics: [
          { title: 'Total Users', value: 0, endpoint: `${API_URL}/api/admin/total-users`, icon: 'users' },
          { title: 'New Users (Last 7 Days)', value: 0, endpoint: `${API_URL}/api/admin/new-users`, icon: 'user-plus' },
          { title: 'Total Credits', value: 0, endpoint: `${API_URL}/api/admin/total-credits`, icon: 'credit-card' },
          { title: 'Subscribed Users', value: 0, endpoint: `${API_URL}/api/admin/subscribed-users`, icon: 'star' },
          { title: 'Total Tickets', value: 0, endpoint: `${API_URL}/api/admin/total-tickets`, icon: 'clipboard-list' },
          { title: 'Open Tickets', value: 0, endpoint: `${API_URL}/api/admin/open-tickets`, icon: 'exclamation-circle' },
          { title: 'Total Revenue', value: 0, endpoint: `${API_URL}/api/admin/total-revenue`, icon: 'dollar-sign' },
          { title: 'WoCommerce Users', value: 0, endpoint: `${API_URL}/api/admin/woocommerce-users`, icon: 'shopping-cart' },
          { title: 'Salla Users', value: 0, endpoint: `${API_URL}/api/admin/salla-users`, icon: 'shopping-bag' },
          { title: 'Zid Users', value: 0, endpoint: `${API_URL}/api/admin/zid-users`, icon: 'shopping-basket' },
          { title: 'Total Videos', value: 0, endpoint: `${API_URL}/api/admin/total-videos`, icon: 'video' },
          { title: 'Total Products', value: 0, endpoint: `${API_URL}/api/admin/total-products`, icon: 'box' },
          { title: 'Total Active Subscriptions', value: 0, endpoint: `${API_URL}/api/admin/total-active-subscriptions`, icon: 'check-circle' },
        ],
      };
    },
    beforeRouteEnter(to, from, next) {
      if (!AuthService.isLoggedIn() || !AuthService.isAdmin()) {
        next('/admin/login');
      } else {
        next();
      }
    },
    mounted() {
      this.fetchMetrics();
    },
    methods: {
      async fetchMetrics() {
        for (let metric of this.metrics) {
          try {
            const response = await fetch(metric.endpoint, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${AuthService.getToken()}`
              },
            });
            const data = await response.json();
            metric.value = data[Object.keys(data)[0]] ?? 0;
          } catch (error) {
            console.error('Error fetching metric:', metric.title, error);
          }
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .dashboard-home {
    padding: 20px;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .metric-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .metric-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }
  
  .card-icon {
    font-size: 40px;
    margin-right: 20px;
    color: #2c3e50;
  }
  
  .card-details h3 {
    margin: 0;
    font-size: 18px;
    color: #2c3e50;
  }
  
  .card-details p {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    color: #34495e;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .metric-card {
      flex-direction: column;
      text-align: center;
    }
  
    .card-icon {
      margin-bottom: 10px;
    }
  }
  </style>
  
<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="vision-mission-page">
    <header class="header">
      <div class="header-actions">
        <router-link to="/dashboard" class="login-link" style="font-weight: bold; font-size: 18px; text-decoration: underline;">لنبدأ</router-link>
      </div>

      <nav class="header-nav">
        <a href="#" class="nav-link">الأسعار</a>
        <a href="#" class="nav-link">الأسئلة الشائعة</a>
      </nav>
      <div class="header-icons">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/1909eed33e4c92c0c6a7ae656b371072f6a331d8caa1d3f9aff6a1b1f6fa06f2?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Icon" class="icon" />
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/076ad4d43ff739e5b7165868b496c5cce1c42209ae846a2e33f5034beb9bd8ef?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Logo" class="logo" />
      </div>
    </header>
    <main>
      <section class="hero-section">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/4f64db5236baf9384e8f226f9d808751a0f4cc8d7f906dfa9e96985f12fde6cc?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="" class="hero-background" />
        <h1 class="hero-title">الرؤية والرسالة</h1>
      </section>
      <section class="mission-section">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/ab901140ad26920e31a4de901bdf5b9252e24fb40106540f8f2ccd75c7657078?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Mission" class="mission-image" />
      </section>
      <section class="mission-details">
        <div class="mission-content">
          <div class="mission-text">
            <h2 class="mission-heading">رسالتنا</h2>
            <p class="mission-description">
              نلتزم في &quot;إبداعي&quot; بتقديم حلول فيديو ترويجية مبتكرة ومتاحة تساعد العلامات التجارية على تعزيز وجودها الرقمي وتحقيق أهدافها التسويكية بكفاءة. نحن نسعى لبناء شراكات طويلة الأمد مع عملائنا عبر تقديم خدمات تتميز بالجودة، الابتكار، والدعم المستمر.<br />
            </p>
          </div>
        </div>
        <div class="mission-image-container">
          <div class="mission-image-wrapper">
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/6accda49aad69efea17f8d69b6914cf2ea8fd36e42e808feeee83c09b67e15dc?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Mission" class="mission-details-image" />
          </div>
        </div>
      </section>
      <section class="vision-section">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/fe5828cac8c1c8d1f0498dc15f0e05dd6a01e5f7a12c56bc2bf66e6885b4d28e?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Vision" class="vision-image" />
      </section>
    </main>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VisionMissionPage',
});
</script>

<style scoped>


.header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(231, 231, 231, 0.2);
  }

.header-actions {
  display: flex;
  gap: 20px;
  font-size: 16px;
}

.login-link {
  padding: 0.75rem 1.5rem;
    border-radius: 72px;
    background-color: #0098fd;
    color: #fff;
    font: 400 16px / 160% Tajawal, sans-serif;
    text-transform: uppercase;
}

.register-button {
  margin: auto 0;
  color: #0098fd;
  font: 400 16px/150% Tajawal, sans-serif;
  text-align: center;
}

.header-nav {
  display: flex;
  gap: 20px;
  margin: auto 0;
  font-size: 16px;
  color: #222;
  font-weight: 400;
  text-align: center;
  line-height: 150%;
}

.nav-link {
  font: 400 16px/150% Tajawal, sans-serif;
}

.header-icons {
  display: flex;
  gap: 10px;
  margin: auto 0;
}

.icon {
  width: 27px;
  aspect-ratio: 1;
  object-fit: contain;
}

.logo {
  width: 80px;
  aspect-ratio: 6.25;
  object-fit: contain;
  margin: auto 0;
}



.vision-mission-page {
  background-color: var(--color-whites-White, #fff);
  display: flex;
  flex-direction: column;
  
}

.main {
  width: 100%;
}

.hero-section {
  position: relative;
  overflow: hidden;
  align-self: center;
  min-height: 149px;
  width: 100%;
  max-width: 1190px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #303030;
  text-align: center;
  text-transform: capitalize;
  margin: 136px 0 14px;
  padding: 55px 60px 25px;
  font: 500 52px/133.2% Tajawal, sans-serif;
}

@media (max-width: 991px) {
  .hero-section {
    max-width: 100%;
    font-size: 40px;
    margin-top: 40px;
    padding: 0 20px;
  }
}

.hero-background {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.hero-title {
  position: relative;
}

.mission-section {
  border-radius: 300px 0 0 300px;
  background: linear-gradient(179deg, rgba(186, 186, 186, 0.06) 1.2%, rgba(0, 152, 253, 0.04) 37.24%);
  display: flex;
  margin-top: 56px;
  width: 100%;
  max-width: 664px;
  align-items: center;
  justify-content: center;
  padding: 71px 60px;
}

@media (max-width: 991px) {
  .mission-section {
    margin-top: 40px;
    padding: 0 20px;
  }
}

.mission-image {
  width: 100%;
  max-width: 500px;
  aspect-ratio: 1;
  object-fit: contain;
}

.mission-details {
  align-self: center;
  margin-top: 39px;
  width: 100%;
  max-width: 1356px;
  display: flex;
  gap: 20px;
  justify-content: center; /* Center the mission details section */
}

@media (max-width: 991px) {
  .mission-details {
    max-width: 100%;
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
}

.mission-content {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 0;
}

@media (max-width: 991px) {
  .mission-content {
    width: 100%;
  }
}

.mission-text {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  margin: auto 0;
  padding: 0 20px;
}

@media (max-width: 991px) {
  .mission-text {
    max-width: 100%;
    margin-top: 40px;
  }
}

.mission-heading {
  color: #303030;
  text-transform: capitalize;
  align-self: end;
  font: 500 40px/133.2% Tajawal, sans-serif;
}

.mission-description {
  color: var(--color-blacks-Black, #000);
  text-align: right;
  margin-top: 44px;
  font: 400 20px/40px Tajawal, sans-serif;
}

@media (max-width: 991px) {
  .mission-description {
    max-width: 100%;
    margin-top: 40px;
  }
}

.mission-image-container {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 20px;
}

@media (max-width: 991px) {
  .mission-image-container {
    width: 100%;
  }
}

.mission-image-wrapper {
  border-radius: 300px 0 0 300px;
  background: linear-gradient(179deg, rgba(186, 186, 186, 0.06) 1.2%, rgba(0, 152, 253, 0.04) 37.24%);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center; /* Center content within the wrapper */
  justify-content: center;
  width: 100%;
  padding: 71px 60px;
}

@media (max-width: 991px) {
  .mission-image-wrapper {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
}

.mission-details-image {
  width: 100%;
  max-width: 500px;
  aspect-ratio: 1;
  object-fit: contain;
}

.vision-section {
  border-radius: 300px 0 0 300px;
  background: linear-gradient(179deg, rgba(186, 186, 186, 0.06) 1.2%, rgba(0, 152, 253, 0.04) 37.24%);
  display: flex;
  margin-top: 39px;
  width: 100%;
  max-width: 665px;
  flex-direction: column;
  align-items: center; /* Center content within the section */
  justify-content: center;
  padding: 80px 60px;
}

@media (max-width: 991px) {
  .vision-section {
    padding-left: 20px;
  }
}

.vision-image {
  width: 100%;
  max-width: 665px;
  aspect-ratio: 1.43;
  object-fit: contain;
  margin: 42px 0 18px;
}

@media (max-width: 991px) {
  .vision-image {
    margin-top: 40px;
  }
}
</style>

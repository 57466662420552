<template>
     <div> <ReelHeader /></div>
    <div class="reel-projects-page">
      <div class="background-shapes"></div>
      <h1 class="title">مشاريع الريلز</h1>
      
      <div class="tabs">
        <button 
          @click="activeTab = 'all'" 
          :class="{ active: activeTab === 'all' }"
        >
          كل المشاريع
        </button>
        <button 
          @click="activeTab = 'saved'" 
          :class="{ active: activeTab === 'saved' }"
        >
          المشاريع المحفوظة
        </button>
      </div>
  
      <div v-if="activeTab === 'all'">
        <h2>المشاريع المكتملة</h2>
        <div class="projects-grid">
            <router-link 
  v-for="project in completedProjects" 
  :key="project.id" 
  :to="{ name: 'ReelPage', params: { id: project.id }}" 
  class="project-card"
>
            <div class="thumbnail-container">
              <img :src="getYouTubeThumbnail(project.youtube_id)" alt="Project Thumbnail">
              <div class="duration">{{ formatDuration(project.duration) }}</div>
            </div>
            <div class="project-info">
              <h3>{{ project.title || 'Loading...' }}</h3>
              <p>{{ formatDate(project.upload_date) }}</p>
            </div>
          </router-link>
        </div>
  
        <h2>المشاريع قيد المعالجة</h2>
        <div class="projects-grid">
  <div 
    v-for="project in pendingProjects" 
    :key="project.id" 
    class="project-card pending"
  >
    <div class="processing-timer">
      وقت المعالجة المتبقي: {{ project.remainingTime }}
    </div>
    <div class="thumbnail-container">
      <img :src="getYouTubeThumbnail(project.youtube_id)" alt="Project Thumbnail">
    </div>
    <div class="project-info">
      <h3>{{ project.title || 'Loading...' }}</h3>
      <p>{{ formatDate(project.upload_date) }}</p>
    </div>
  </div>
</div>
      </div>
  
      <div v-else class="projects-grid">
        <router-link 
          v-for="project in savedProjects" 
          :key="project.id" 
          :to="{ name: 'ReelPage', params: { id: project.id }}" 
          class="project-card"
        >
          <div class="thumbnail-container">
            <img :src="getYouTubeThumbnail(project.youtube_id)" alt="Project Thumbnail">
            <div class="duration">{{ formatDuration(project.duration) }}</div>
          </div>
          <div class="project-info">
            <h3>{{ project.title || 'Loading...' }}</h3>
            <p>{{ formatDate(project.upload_date) }}</p>
          </div>
        </router-link>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted, onUnmounted } from 'vue';
  import { API_URL } from '@/config';
  import ReelHeader from './ReelHeader.vue';  // Make sure this path is correct
  
  export default {
  components: {
    ReelHeader
  },
    setup() {
      const activeTab = ref('all');
      const completedProjects = ref([]);
      const pendingProjects = ref([]);
      const savedProjects = ref([]);
      let intervalId = null;
  
      const fetchProcessedVideos = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${API_URL}/user_processed_videos`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          const data = await response.json();
          if (data.success) {
            completedProjects.value = data.completed_videos || [];
            pendingProjects.value = data.pending_videos || [];
            await fetchVideoDetails(completedProjects.value);
            await fetchVideoDetails(pendingProjects.value);
          } else {
            console.error('Failed to fetch processed videos');
          }
        } catch (error) {
          console.error('Error fetching processed videos:', error);
        }
      };
  
      const fetchSavedProjects = async () => {
        // Implement this method to fetch saved projects
        // Similar to fetchProcessedVideos, but for saved projects
      };
  
      const fetchVideoDetails = async (projects) => {
        const token = localStorage.getItem('token');
        for (let project of projects) {
          if (!project.title || !project.duration) {
            try {
              const response = await fetch(`${API_URL}/get_video_info`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  },
  body: JSON.stringify({ video_url: project.video_url })
});
              const data = await response.json();
              project.title = data.title;
              project.duration = data.duration;
            } catch (error) {
              console.error('Error fetching video details:', error);
            }
          }
        }
      };
  
      const getYouTubeThumbnail = (youtubeId) => {
        return `https://img.youtube.com/vi/${youtubeId}/0.jpg`;
      };
  
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('ar-EG');
      };
  
      const formatDuration = (seconds) => {
        if (!seconds) return '00:00';
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
      };
  
      const getRemainingTime = async (project) => {
        try {
          const response = await fetch(`${API_URL}/get_remaining_processing_time/${project.id}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
          const data = await response.json();
          
          if (data.remaining_time === 0) {
            return 'Processing...';
          }
  
          const minutes = Math.floor(data.remaining_time / 60);
          const seconds = data.remaining_time % 60;
          return `${minutes}:${seconds.toString().padStart(2, '0')}`;
        } catch (error) {
          console.error('Error fetching remaining time:', error);
          return 'Error';
        }
      };
  
      const updateRemainingTimes = async () => {
        for (const project of pendingProjects.value) {
          project.remainingTime = await getRemainingTime(project);
        }
      };
  
      onMounted(() => {
        fetchProcessedVideos();
        fetchSavedProjects();
        updateRemainingTimes();
        intervalId = setInterval(updateRemainingTimes, 5000);
      });
  
      onUnmounted(() => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      });
  
      return {
        activeTab,
        completedProjects,
        pendingProjects,
        savedProjects,
        getYouTubeThumbnail,
        formatDate,
        formatDuration,
        getRemainingTime,
        fetchProcessedVideos,
        fetchSavedProjects
      };
    }
  }
  </script>
  
  
  <style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;600;700&display=swap');

.reel-projects-page {
  background-color: #0c1620;
  color: white;
  font-family: 'Cairo', sans-serif;
  padding: 4rem 2rem;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  direction: rtl;
}

.background-shapes {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    radial-gradient(circle at 10% 20%, rgba(255, 255, 255, 0.03) 0%, transparent 20%),
    radial-gradient(circle at 90% 80%, rgba(255, 255, 255, 0.03) 0%, transparent 20%),
    linear-gradient(to right, rgba(255, 255, 255, 0.02) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.02) 1px, transparent 1px);
  background-size: 100% 100%, 100% 100%, 20px 20px, 20px 20px;
  opacity: 0.5;
  z-index: -1;
}

.title {
  font-size: 3rem;
  margin-bottom: 3rem;
  text-align: center;
  font-weight: 700;
  color: #3498db;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.tabs button {
  background-color: transparent;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  margin: 0 1rem;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 25px;
  font-weight: 600;
}

.tabs button:hover {
  background-color: rgba(52, 152, 219, 0.2);
}

.tabs button.active {
  background-color: #3498db;
  box-shadow: 0 4px 6px rgba(52, 152, 219, 0.3);
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 2.5rem;
  padding: 0 2rem;
}

.project-card {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(52, 152, 219, 0.3);
}

.thumbnail-container {
  position: relative;
  overflow: hidden;
  aspect-ratio: 16 / 9;
}

.thumbnail-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.thumbnail-container:hover img {
  transform: scale(1.1);
}

.duration {
  position: absolute;
  bottom: 15px;
  right: 15px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 600;
  backdrop-filter: blur(5px);
}

.project-info {
  padding: 1.5rem;
}

.project-info h3 {
  margin: 0 0 10px;
  font-size: 1.3rem;
  line-height: 1.3;
  max-height: 2.6em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #3498db;
}

.project-info p {
  margin: 0;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
}

.project-actions {
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem 1.5rem;
}

.action-btn {
  color: white;
  border: none;
  padding: 0.6rem 1.2rem;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  flex: 1;
  margin: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.share-btn { background-color: #2ecc71; }
.share-btn:hover { background-color: #27ae60; }

.download-btn { background-color: #e74c3c; }
.download-btn:hover { background-color: #c0392b; }

.edit-btn { background-color: #f39c12; }
.edit-btn:hover { background-color: #d35400; }

@media (max-width: 768px) {
  .projects-grid {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
  
  .title {
    font-size: 2.5rem;
  }
  
  .tabs button {
    padding: 0.6rem 1.2rem;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .reel-projects-page {
    padding: 3rem 1rem;
  }
  
  .projects-grid {
    grid-template-columns: 1fr;
  }
  
  .title {
    font-size: 2rem;
  }
  
  .tabs {
    flex-direction: column;
    align-items: center;
  }
  
  .tabs button {
    margin: 0.5rem 0;
    width: 80%;
  }
}

.project-card.pending {
  opacity: 0.7;
  position: relative;
}

.project-card.pending::after {
  content: 'قيد المعالجة';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.project-card.pending .duration {
  background-color: #f39c12;
  color: white;
}
</style>
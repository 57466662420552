<template>
  <div>
    <reel-header />
  </div>
  <div class="reel-page">
    <div class="background-shapes"></div>
    <h1 class="page-title">{{ videoTitle }}</h1>
    <div class="reels-container">
      <div v-for="(outputVideo, index) in processedVideo.output_videos" :key="outputVideo.id" class="reel-card">
        <div class="video-container">
          <div class="video-player">
            <video
              :id="`video-player-${index}`"
              class="video-js"
              controls
              preload="auto"
              width="340"
              height="600"
              data-setup="{}"
            >
              <source :src="outputVideo.s3_url" type="video/mp4" />
            </video>
          </div>
          <div class="video-info">
            <div class="score-container">
              <div class="score-circle">
                <span class="score">{{ getReelScore(index) }}</span>
                <span class="score-label">/100</span>
              </div>
              <div class="score-explanation">
                <p class="arabic-text">درجة تفاعل الفيديو</p>
                <p class="arabic-subtext">لتحديد أفضل فيديو للمشاركة</p>
              </div>
            </div>
            <button class="share-button" @click="openVideo(outputVideo.s3_url)">
              <i class="fas fa-share-alt"></i> مشاركة
            </button>
            <div class="transcription-container">
              <h3>النص الكامل</h3>
              <p>{{ processedVideo.full_transcription }}</p>
            </div>
            <div class="transcription-container">
              <h3>نص المقطع</h3>
              <p>{{ getReelTranscript(index) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, nextTick } from 'vue';
import { useRoute } from 'vue-router';
import { API_URL } from '@/config';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import ReelHeader from './ReelHeader.vue';

export default {
  components: {
    ReelHeader
  },
  setup() {
    const route = useRoute();
    const processedVideo = ref({});
    const videoPlayers = ref([]);
    const videoTitle = ref('');
    const videoDuration = ref(null);

    const fetchProcessedVideoDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${API_URL}/processed_video_summary/${route.params.id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (data.success) {
          processedVideo.value = data.video_summary;
          console.log('Processed video data:', JSON.stringify(processedVideo.value, null, 2));
          
          // Check if reel_segments and output_videos are properly populated
          if (!processedVideo.value.reel_segments || processedVideo.value.reel_segments.length === 0) {
            console.error('No reel segments found in the response');
          }
          if (!processedVideo.value.output_videos || processedVideo.value.output_videos.length === 0) {
            console.error('No output videos found in the response');
          }
          
          // Fetch video title using the video_url from the summary
          if (processedVideo.value.video_url) {
            await fetchVideoTitle(processedVideo.value.video_url);
          } else {
            console.error('No video URL found in the processed video summary');
            videoTitle.value = 'Untitled Video';
          }
          
          await nextTick();
          initVideoPlayers();
        } else {
          console.error('Failed to fetch processed video details:', data.error);
        }
      } catch (error) {
        console.error('Error fetching processed video details:', error);
      }
    };

    const fetchVideoTitle = async (videoUrl) => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${API_URL}/get_video_info`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ video_url: videoUrl })
        });
        const data = await response.json();
        if (data.title) {
          videoTitle.value = data.title;
        } else {
          videoTitle.value = 'Untitled Video';
        }
      } catch (error) {
        console.error('Error fetching video title:', error);
        videoTitle.value = 'Untitled Video';
      }
    };

    const openVideo = (url) => {
      window.open(url, '_blank');
    };

    const getReelTranscript = (index) => {
      const outputVideo = processedVideo.value.output_videos[index];
      if (!outputVideo) {
        console.error(`No output video found for index ${index}`);
        return 'No transcript available';
      }
      
      const correspondingReel = processedVideo.value.reel_segments.find(
        segment => segment.id === outputVideo.reel_segment_id
      );
      
      console.log(`Index: ${index}, OutputVideo:`, outputVideo, "CorrespondingReel:", correspondingReel);
      
      return correspondingReel ? correspondingReel.transcript_text : 'No transcript available';
    };

    const getReelScore = (index) => {
      const outputVideo = processedVideo.value.output_videos[index];
      if (!outputVideo) {
        return 'N/A';
      }
      
      const correspondingReel = processedVideo.value.reel_segments.find(
        segment => segment.id === outputVideo.reel_segment_id
      );
      
      return correspondingReel && correspondingReel.gpt_score ? Math.round(correspondingReel.gpt_score * 10) : 'N/A';
    };

    const initVideoPlayers = () => {
      processedVideo.value.output_videos.forEach((video, index) => {
        const playerElement = document.querySelector(`#video-player-${index}`);
        if (playerElement) {
          const player = videojs(playerElement, {
            techOrder: ['html5'],
            html5: {
              nativeVideoTracks: false,
              nativeAudioTracks: false,
              nativeTextTracks: false,
              hls: {
                overrideNative: true
              }
            }
          }, function onPlayerReady() {
            console.log('Player is ready');
            this.on('loadedmetadata', () => {
              console.log('Video metadata loaded:', this.currentSrc());
              console.log('Video dimensions:', this.videoWidth(), 'x', this.videoHeight());
              console.log('Video duration:', this.duration());
            });
            this.on('error', () => {
              console.error('Video error:', this.error());
            });
          });
          videoPlayers.value.push(player);
        } else {
          console.error(`Video element #video-player-${index} not found`);
        }
      });
    };

    onMounted(() => {
      fetchProcessedVideoDetails();
    });

    return {
      processedVideo,
      videoTitle,
      videoDuration,
      getReelScore,
      getReelTranscript,
      openVideo,
    };
  }
}
</script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
  @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css');
  
  .reel-page {
    background-color: #0a0f18;
  color: #e0e0e0;
  font-family: 'Poppins', 'Cairo', sans-serif;
  padding: 2rem;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}
 

.score-container {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}


.score-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: conic-gradient(#4CAF50 0deg, #4CAF50 calc(3.6deg * var(--score)), #2c3e50 calc(3.6deg * var(--score)), #2c3e50 360deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  position: relative;
  margin-right: 1rem;
}

.score-explanation {
  flex: 1;
}

.arabic-text {
  font-family: 'Cairo', sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  color: #4CAF50;
  margin: 0;
  text-align: right;
}

.arabic-subtext {
  font-family: 'Cairo', sans-serif;
  font-size: 0.9rem;
  color: #a0a0a0;
  margin: 0;
  text-align: right;
}


  .background-shapes {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: 
      radial-gradient(circle at 10% 20%, rgba(255, 255, 255, 0.03) 0%, transparent 20%),
      radial-gradient(circle at 90% 80%, rgba(255, 255, 255, 0.03) 0%, transparent 20%),
      linear-gradient(to right, rgba(255, 255, 255, 0.02) 1px, transparent 1px),
      linear-gradient(to bottom, rgba(255, 255, 255, 0.02) 1px, transparent 1px);
    background-size: 100% 100%, 100% 100%, 20px 20px, 20px 20px;
    opacity: 0.3;
    z-index: -1;
  }
  
  .page-title {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    font-weight: 600;
    color: #4CAF50;
    text-shadow: 0 0 10px rgba(76, 175, 80, 0.3);
  }
  
  .reels-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .reel-card {
    background-color: #1e2a38;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
  }
  
  .reel-card:hover {
    transform: translateY(-5px);
  }
  
  .video-container {
    display: flex;
    gap: 2rem;
    padding: 1.5rem;
  }
  
  .video-player {
    flex: 1;
    max-width: 340px;
  }
  
  .video-js {
    width: 100%;
    height: 600px;
    background-color: #000;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  .video-info {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .score-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
  }
  
  .score-circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: conic-gradient(#4CAF50 0deg, #4CAF50 calc(3.6deg * var(--score)), #2c3e50 calc(3.6deg * var(--score)), #2c3e50 360deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    position: relative;
  }
  
  .score-circle::before {
    content: '';
    position: absolute;
    inset: 5px;
    border-radius: 50%;
    background-color: #1e2a38;
  }
  
  .score, .score-label {
    position: relative;
    z-index: 1;
  }
  
  .score {
    font-weight: bold;
    color: #4CAF50;
  }
  
  .score-label {
    font-size: 0.8rem;
    opacity: 0.7;
  }
  
  .share-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 25px;
    cursor: pointer;
    margin-bottom: 1.5rem;
    align-self: flex-start;
    font-size: 1rem;
    font-weight: 600;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .share-button:hover {
    background-color: #45a049;
  }
  
  .transcription-container {
    background-color: #2c3e50;
    padding: 1.5rem;
    border-radius: 12px;
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: 1.5rem;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .transcription-container h3 {
  font-family: 'Cairo', sans-serif;
  text-align: right;
}

.transcription-container p {
  text-align: right;
  direction: rtl;
}
  
  /* Custom scrollbar for webkit browsers */
  .transcription-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .transcription-container::-webkit-scrollbar-track {
    background: #1e2a38;
  }
  
  .transcription-container::-webkit-scrollbar-thumb {
    background-color: #4CAF50;
    border-radius: 4px;
  }
  
  @media (max-width: 768px) {
    .video-container {
      flex-direction: column;
    }
    
    .video-player {
      max-width: 100%;
    }
  }
  </style>
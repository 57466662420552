<template>
  <div class="media-container" dir="rtl">
    <div ref="previewContainer"></div>

    <!-- الصور المقترحة -->
    <section class="section-container">
      <h2>اختر صورة مضافة مسبقاً</h2>
      <div class="image-grid">
        <div
          v-for="(image, index) in preaddedImages"
          :key="image.id"
          class="image-item"
          @click="selectImage(image.url, index)"
        >
          <img :src="image.url" :alt="image.name" />
        </div>
      </div>
    </section>

    <!-- قسم رفع الصور -->
    <section v-for="(imageKey, index) in imageKeys" :key="imageKey" class="section-container">
      <h2>{{ `Image ${index + 1}` }}</h2>
      <label :for="'file-upload-' + imageKey" class="custom-file-upload">اختر ملف</label>
      <input :id="'file-upload-' + imageKey" type="file" accept="image/*" @change="handleFileUpload(imageKey, $event)" hidden/>
      <div v-if="imageData[imageKey]" class="image-preview">
        <p>الملف المختار: {{ imageFileName[imageKey] }}</p>
        <img :src="imageData[imageKey]" :alt="imageFileName[imageKey]" class="uploaded-image-preview"/>
      </div>
    </section>

    <!-- صور المنتجات الخاصة بالمستخدم -->
    <section class="section-container">
      <h2>صور منتجاتك</h2>
      <input type="text" v-model="searchQuery" @input="filterProductImages" placeholder="ابحث عن الصور حسب اسم المنتج..." class="search-bar">
      <div class="image-grid">
        <div
          v-for="(productImage, index) in filteredProductImages"
          :key="productImage.id"
          class="image-item"
          @click="selectImage(productImage.url, index)"
        >
          <img :src="productImage.url" :alt="productImage.name" />
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import { API_URL } from '@/config';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'MediaTemplate',
  props: {
    previewInstance: {
      type: Object,
      required: true
    },
    updatePreviewInstance: {
      type: Function,
      required: true
    },
    videoId: {
      type: Number,
      required: true
    },
    numImages: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      videoDetails: null,
      dynamicElementIds: null,
      imageData: {},
      imageFileName: {},
      preaddedImages: [],
      productImages: [],
      filteredProductImages: [],
      searchQuery: ''
    };
  },
  computed: {
    ...mapState(['modifications']),
    imageKeys() {
      const keys = [
        'main_image_key',
        'secondary_image_key',
        'third_image_key',
        'fourth_image_key',
        'fifth_image_key',
        'sixth_image_key',
        'seventh_image_key'
      ];
      return keys.slice(0, this.numImages);
    },
  },
  watch: {
    numImages() {
      this.updateImageKeys();
    },
    '$store.state.videoDetails'(newValue) {
      if (newValue) {
        this.videoDetails = newValue;
        this.dynamicElementIds = newValue.dynamic_element_ids.elements;
        this.updateImageData(); 
      }
    },
    searchQuery() {
      this.filterProductImages();
    },
    previewInstance(newValue) {
      if (newValue) {
        console.log('Preview instance is ready in MediaTemplate');
      }
    }
  },
  methods: {
    updateImageData() {
      this.imageData = {};
      this.imageFileName = {};
      this.imageKeys.forEach(imageKey => {
        const elementId = this.dynamicElementIds[imageKey];
        if (elementId) {
          this.imageData[elementId] = '';
          this.imageFileName[elementId] = '';
        }
      });
    },
    updateImageKeys() {
      const keys = [
        'main_image_key',
        'secondary_image_key',
        'third_image_key',
        'fourth_image_key',
        'fifth_image_key',
        'sixth_image_key',
        'seventh_image_key'
      ];
      this.imageKeys = keys.slice(0, this.numImages);
      console.log('Updated image keys:', this.imageKeys);
    },
    ...mapMutations(['updateModifications']),
    async handleFileUpload(imageKey, event) {
  const file = event.target.files[0];
  this.imageFileName[imageKey] = file.name;

  const formData = new FormData();
  formData.append('image', file);

  try {
    const response = await fetch(`${API_URL}/api/upload-image`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: formData
    });

    if (response.ok) {
      const data = await response.json();
      const imageUrl = data.url;
      this.imageData[imageKey] = imageUrl;
      console.log(`Image URL for ${imageKey}:`, imageUrl);
      await this.updateImage(imageKey, imageUrl);
    } else {
      console.error('Failed to upload image');
    }
  } catch (error) {
    console.error('Error uploading image:', error);
  }
},
    async selectImage(url, index) {
      console.log('Select image triggered:', { url, index });
      const imageKeyIndex = index % this.numImages;
      const imageKey = this.mapImageKey(imageKeyIndex);
      if (imageKey) {
        console.log('Mapped image key:', imageKey);
        this.imageData[imageKey] = url;
        console.log('Image data for', imageKey, ':', url);
        await this.updateImage(imageKey, this.imageData[imageKey]);
      } else {
        console.warn(`Invalid image key at index: ${imageKeyIndex}`);
      }
    },

    mapImageKey(index) {
      const keys = [
        'main_image_key',
        'secondary_image_key',
        'third_image_key',
        'fourth_image_key'
      ];
      return keys[index] || 'main_image_key';
    },

    async fetchVideoData() {
      console.log('Fetching video data...');
      this.videoDetails = this.$store.state.videoDetails;
      console.log('Video data fetched:', this.videoDetails);
      this.dynamicElementIds = this.videoDetails.dynamic_element_ids.elements;
      console.log('Dynamic element IDs set:', this.dynamicElementIds);
    },
    async loadPreaddedImages() {
      const response = await fetch(`${API_URL}/api/preadded-images`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      this.preaddedImages = await response.json();
      console.log('Preadded images loaded:', this.preaddedImages);
    },
    async fetchProductImages() {
      const response = await fetch(`${API_URL}/api/product-images`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      this.productImages = await response.json();
      this.filteredProductImages = this.productImages;
      console.log('Product images loaded:', this.productImages);
    },
    filterProductImages() {
      if (!this.searchQuery) {
        this.filteredProductImages = this.productImages;
      } else {
        this.filteredProductImages = this.productImages.filter(p =>
          p.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
      console.log('Filtered product images:', this.filteredProductImages);
    },
    async updateImage(imageKey, imageData) {
      console.log('Dynamic element IDs:', this.dynamicElementIds);
      if (this.dynamicElementIds && this.dynamicElementIds[imageKey]) {
        const elementId = this.dynamicElementIds[imageKey];
        console.log('Updating modification for element ID:', elementId);
        this.$store.commit('updateModification', { key: elementId, value: imageData });
        await this.saveModificationsToDb();
        setTimeout(() => {
          console.log('Calling updatePreviewInstance with modifications:', this.$store.state.modifications);
          this.updatePreviewInstance();
        }, 100);
      } else {
        console.warn(`Element ID not found for image key: ${imageKey}`);
      }
    },
    async saveModificationsToDb() {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to save modifications.');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/api/videos/${this.videoId}/modifications`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ modifications: this.$store.state.modifications })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Modifications saved to DB successfully:', data);
      } catch (error) {
        console.error('Failed to save modifications to DB:', error);
      }
    }
  },
  mounted() {
    this.fetchVideoData();
    this.loadPreaddedImages();
    this.fetchProductImages();
  }
};
</script>



  
  
  
<style scoped>

.search-bar {
  padding: 8px;
  margin: 10px 0;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.media-container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  background: #f8f9fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section-container {
  margin-bottom: 30px;
}

h2 {
  color: #333;
  margin-bottom: 10px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Always two images per row */
  grid-gap: 10px;
}

.image-item {
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  border-radius: 8px;
  overflow: hidden;
}

.image-item:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.image-item:hover img {
  transform: scale(1.1);
}

.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  margin-top: 5px;
}

.image-preview {
  margin-top: 10px;
  padding: 10px;
  background-color: #e9ecef;
  border-radius: 5px;
}

.uploaded-image-preview {
  max-width: 100%;
  height: auto;
  display: block;
  margin-top: 10px;
}
</style>

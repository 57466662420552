<!-- eslint-disable vue/no-parsing-error -->
<template>
    <div class="cookie-policy">
      <header class="header">
      <div class="header-actions">
        <router-link to="/dashboard" class="login-link" style="font-weight: bold; font-size: 18px; text-decoration: underline;">لنبدأ</router-link>
      </div>

      <nav class="header-nav">
        <a href="#" class="nav-link">الأسعار</a>
        <a href="#" class="nav-link">الأسئلة الشائعة</a>
      </nav>
      <div class="header-icons">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/1909eed33e4c92c0c6a7ae656b371072f6a331d8caa1d3f9aff6a1b1f6fa06f2?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Icon" class="icon" />
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/076ad4d43ff739e5b7165868b496c5cce1c42209ae846a2e33f5034beb9bd8ef?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Logo" class="logo" />
      </div>
    </header>
      <section class="hero-section">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/694ed41d890e642ce0f2dc895df6fdfb3c01d41ce716d9db96b816634e287ff7?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="" class="hero-background" />
        <h1 class="hero-title">ملفات تعريف الارتباط</h1>
      </section>
      <main class="main-content">
        <article class="content-section">
          <h2 class="section-title">ما هي ملفات تعريف الارتباط؟</h2>
          <p class="section-description">
            ملفات تعريف الارتباط هي ملفات نصية صغيرة تُخزن على جهازك عند زيارة مواقع ويب. تُستخدم هذه الملفات لتحسين تجربتك على موقعنا وتقديم خدمات مخصصة.
          </p>
          <h2 class="section-title">كيف نستخدم ملفات تعريف الارتباط؟</h2>
          <ul class="usage-list">
            <li class="usage-item">
              لتحسين الأداء: لتسريع عمليات التحميل وتحسين تجربة التصفح.
            </li>
            <li class="usage-item">
              للتحليلات: لفهم كيفية استخدام زوارنا للموقع وتحسين خدماتنا بناءً على هذه المعلومات.
            </li>
            <li class="usage-item">
              للتخصيص: لتقديم محتوى وإعلانات مخصصة تناسب اهتماماتك
            </li>
          </ul>
        </article>
        <aside class="decorative-dots">
          <div class="dot"></div>
          <div class="dot"></div>
        </aside>
      </main>
    </div>
  </template>
  
  <script lang="ts">

  </script>
  
  <style scoped>


.header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(231, 231, 231, 0.2);
  }

.header-actions {
  display: flex;
  gap: 20px;
  font-size: 16px;
}

.login-link {
  padding: 0.75rem 1.5rem;
    border-radius: 72px;
    background-color: #0098fd;
    color: #fff;
    font: 400 16px / 160% Tajawal, sans-serif;
    text-transform: uppercase;
}

.register-button {
  margin: auto 0;
  color: #0098fd;
  font: 400 16px/150% Tajawal, sans-serif;
  text-align: center;
}

.header-nav {
  display: flex;
  gap: 20px;
  margin: auto 0;
  font-size: 16px;
  color: #222;
  font-weight: 400;
  text-align: center;
  line-height: 150%;
}

.nav-link {
  font: 400 16px/150% Tajawal, sans-serif;
}

.header-icons {
  display: flex;
  gap: 10px;
  margin: auto 0;
}

.icon {
  width: 27px;
  aspect-ratio: 1;
  object-fit: contain;
}

.logo {
  width: 80px;
  aspect-ratio: 6.25;
  object-fit: contain;
  margin: auto 0;
}



  .cookie-policy {
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }
  
  .header {
    box-shadow: 0 2px 5px 0 rgba(231, 231, 231, 0.2);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 80px;
    gap: 20px;
  }
  
  @media (max-width: 991px) {
    .header {
      flex-wrap: wrap;
      padding: 0 20px;
    }
  }
  
  .logo-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .logo-icon {
    width: 27px;
    aspect-ratio: 1;
    object-fit: contain;
  }
  
  .logo-text {
    width: 80px;
    aspect-ratio: 6.25;
    object-fit: contain;
  }
  
  .navigation {
    display: flex;
    gap: 20px;
    font-size: 16px;
    color: #222;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    line-height: 150%;
  }
  
  @media (max-width: 991px) {
    .navigation {
      white-space: initial;
    }
  }
  
  .nav-link {
    font-family: Tajawal, sans-serif;
  }
  
  .auth-buttons {
    display: flex;
    gap: 20px;
    font-weight: 400;
  }
  
  .sign-in-link {
    color: #0098fd;
    text-align: center;
    margin: auto 0;
    font: 16px/150% Tajawal, sans-serif;
  }
  
  .get-started-button {
    justify-content: center;
    border-radius: 72px;
    background-color: #0098fd;
    color: #fff;
    text-transform: uppercase;
    padding: 28px 38px 29px;
    font: 18px/160% Tajawal, sans-serif;
    border: none;
    cursor: pointer;
  }
  
  @media (max-width: 991px) {
    .get-started-button {
      padding: 0 20px;
    }
  }
  
  .hero-section {
    position: relative;
    overflow: hidden;
    align-self: center;
    min-height: 149px;
    width: 100%;
    max-width: 1190px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #303030;
    text-align: center;
    text-transform: capitalize;
    margin: 136px 0 14px;
    padding: 55px 60px 25px;
    font: 500 52px/133.2% Tajawal, sans-serif;
  }
  
  @media (max-width: 991px) {
    .hero-section {
      max-width: 100%;
      font-size: 40px;
      margin-top: 40px;
      padding: 0 20px;
    }
  }
  
  .hero-background {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  .hero-title {
    position: relative;
    z-index: 1;
  }
  
  .main-content {
    align-self: center;
    display: flex;
    margin-top: 75px;
    gap: 20px;
    padding: 0 20px;
  }
  
  @media (max-width: 991px) {
    .main-content {
      flex-wrap: wrap;
      margin-top: 40px;
    }
  }
  
  .content-section {
    color: #000;
    text-align: right;
    flex-grow: 1;
    flex-basis: auto;
    font: 400 24px/48px Inter, sans-serif;
  }
  
  @media (max-width: 991px) {
    .content-section {
      max-width: 100%;
    }
  }
  
  .section-title {
    font-family: Tajawal, sans-serif;
    font-size: 30px;
    margin-bottom: 16px;
  }
  
  .section-description {
    font-family: Tajawal, sans-serif;
    margin-bottom: 32px;
  }
  
  .usage-list {
    list-style-type: disc;
    padding-right: 20px;
  }
  
  .usage-item {
    font-family: Tajawal, -apple-system, Roboto, Helvetica, sans-serif;
    margin-bottom: 8px;
  }
  
  .decorative-dots {
    align-self: start;
    display: flex;
    flex-direction: column;
  }
  
  @media (max-width: 991px) {
    .decorative-dots {
      display: none;
    }
  }
  
  .dot {
    background-color: #0098fd;
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
  
  .dot:last-child {
    margin-top: 231px;
  }
  
  @media (max-width: 991px) {
    .dot:last-child {
      margin-top: 40px;
    }
  }
  </style>
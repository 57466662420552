<template>
  <div> <ReelHeader /></div>
    <div class="landing-page">
      <div class="background-shapes"></div>
      
      <!-- First Section -->
      <section class="hero">
        <h1 class="arabic-text">مقطع فيديو طويل وعشرة مقاطع سريعة الانتشار. إنشاء أسرع بعشر مرات.</h1>
        <p class="arabic-text">في أداة فيديو تعمل بالذكاء الاصطناعي تعمل على إعادة استخدام مقاطع الفيديو الطويلة وتحويلها إلى مقاطع قصيرة واحدة فقط</p>
        
        <div class="video-input">
    <div class="input-wrapper">
      <button @click="goToMainReelPage" class="get-clips-btn">ابدا الان</button>
    </div>
  </div>
        
        <div class="video-player">
          <video 
            src="https://ebda3i.s3.ap-south-1.amazonaws.com/65bc4ddca87ce72dc13a583d_promov3-transcode.mp4" 
            autoplay 
            loop 
            muted 
            playsinline
          ></video>
        </div>
      </section>
  
        <!-- Second Section -->
<section class="second-section">
  <div class="content-wrapper">
    <div class="video-thumbnails">
      <img src="https://ebda3i.s3.ap-south-1.amazonaws.com/second+section.png" alt="Second section thumbnails">
    </div>
    <div class="text-content">
  <h2 class="arabic-text">
    <span class="highlight">مقاطع احترافية</span><br>
    من فيديو واحد فقط
  </h2>
  <p class="arabic-text">حول فيديو واحد إلى عشرات المقاطع القصيرة الجذابة بنقرة زر. ابتكر محتوى متنوع ومثير للاهتمام من مصدر واحد.</p>
</div>
  </div>
</section>

  <!-- Third Section -->
  <section class="testimonials-section">
    <h2 class="section-title arabic-text">شهادات المستخدمين</h2>
    <div class="testimonials-wrapper">
      <div class="testimonials-container">
        <div class="testimonial-card">
          <div class="stars">★★★★★</div>
          <p class="arabic-text">أداة تحويل الفيديو هذه رائعة! كانت لدي عدة فيديوهات طويلة من رحلتي الأخيرة وتمكنت من تحويلها إلى مقاطع قصيرة بسهولة. الجودة ممتازة والعملية سريعة للغاية. أوصي بها بشدة لأي شخص يريد تحرير الفيديوهات بسهولة</p>
          <div class="user-info">
            <img src="https://randomuser.me/api/portraits/men/1.jpg" alt="User profile picture">
            <span class="username arabic-text">أحمد محمد</span>
          </div>
        </div>
        <div class="testimonial-card">
          <div class="stars">★★★★☆</div>
          <p class="arabic-text">تطبيق ممتاز لتحرير الفيديو. سهل الاستخدام وسريع في المعالجة. أعجبني كثيرًا قدرته على تحويل مقاطع الفيديو الطويلة إلى مقاطع أقصر دون فقدان الجودة. أنصح به بشدة لمن يحتاج إلى أداة فعالة لتحرير الفيديو.</p>
          <div class="user-info">
            <img src="https://randomuser.me/api/portraits/women/2.jpg" alt="User profile picture">
            <span class="username arabic-text">فاطمة علي</span>
          </div>
        </div>
        <div class="testimonial-card">
          <div class="stars">★★★★★</div>
          <p class="arabic-text">هذه الأداة غيرت طريقة عملي مع الفيديوهات. سهولة الاستخدام والنتائج عالية الجودة جعلتها لا غنى عنها في مشاريعي. أقدر بشكل خاص السرعة التي يمكنني بها تحويل الفيديوهات الطويلة إلى مقاطع أقصر وأكثر جاذبية.</p>
          <div class="user-info">
            <img src="https://randomuser.me/api/portraits/men/3.jpg" alt="User profile picture">
            <span class="username arabic-text">محمود حسن</span>
          </div>
        </div>
        <div class="testimonial-card">
          <div class="stars">★★★★★</div>
          <p class="arabic-text">أداة رائعة لتحرير الفيديو! ساعدتني في تحويل الفيديوهات الطويلة إلى مقاطع قصيرة بسهولة. الجودة ممتازة والسرعة مذهلة. أنصح بها لكل من يريد تحرير فيديوهاته بكفاءة.</p>
          <div class="user-info">
            <img src="https://randomuser.me/api/portraits/women/4.jpg" alt="User profile picture">
            <span class="username arabic-text">نور الهدى</span>
          </div>
        </div>
        <div class="testimonial-card">
          <div class="stars">★★★★☆</div>
          <p class="arabic-text">تجربة رائعة مع هذه الأداة! سهلة الاستخدام وفعالة جدًا. ساعدتني في تحسين محتوى الفيديو الخاص بي بشكل كبير. أوصي بها لجميع صانعي المحتوى.</p>
          <div class="user-info">
            <img src="https://randomuser.me/api/portraits/men/5.jpg" alt="User profile picture">
            <span class="username arabic-text">عمر خالد</span>
          </div>
        </div>
        <div class="testimonial-card">
          <div class="stars">★★★★★</div>
          <p class="arabic-text">أداة مذهلة لتحرير الفيديو! سرعة المعالجة وجودة النتائج أذهلتني. أصبح تحرير الفيديوهات الطويلة أمرًا سهلاً وممتعًا. شكرًا لهذه الأداة الرائعة!</p>
          <div class="user-info">
            <img src="https://randomuser.me/api/portraits/women/6.jpg" alt="User profile picture">
            <span class="username arabic-text">ليلى سعيد</span>
          </div>
        </div>
        <div class="testimonial-card">
          <div class="stars">★★★★☆</div>
          <p class="arabic-text">تطبيق ممتاز لتحويل وتحرير الفيديو. سهل الاستخدام ونتائجه عالية الجودة. ساعدني كثيرًا في تحسين محتواي على وسائل التواصل الاجتماعي. أنصح به بشدة!</p>
          <div class="user-info">
            <img src="https://randomuser.me/api/portraits/men/7.jpg" alt="User profile picture">
            <span class="username arabic-text">ياسر محمود</span>
          </div>
        </div>
        <div class="testimonial-card">
          <div class="stars">★★★★☆</div>
          <p class="arabic-text">أداة رائعة وسهلة الاستخدام لتحرير الفيديو. النتائج مبهرة وسرعة المعالجة ممتازة. أنصح بها بشدة لكل من يريد تحسين فيديوهاته بسهولة.</p>
          <div class="user-info">
            <img src="https://randomuser.me/api/portraits/women/8.jpg" alt="User profile picture">
            <span class="username arabic-text">سمية عبد الله</span>
          </div>
        </div>
        <div class="testimonial-card">
          <div class="stars">★★★★★</div>
          <p class="arabic-text">أفضل أداة لتحرير الفيديو استخدمتها على الإطلاق! تحويل الفيديوهات الطويلة إلى مقاطع قصيرة أصبح سهلاً وسريعاً. الجودة رائعة وأوصي بها لكل من يبحث عن أداة فعالة.</p>
          <div class="user-info">
            <img src="https://randomuser.me/api/portraits/men/9.jpg" alt="User profile picture">
            <span class="username arabic-text">كريم نبيل</span>
          </div>
        </div>
        <div class="testimonial-card">
          <div class="stars">★★★★★</div>
          <p class="arabic-text">تطبيق مدهش لتحرير الفيديو! سهولة الاستخدام والسرعة والجودة العالية تجعله الأفضل في السوق. أصبحت عملية تحرير الفيديوهات ممتعة وسهلة بفضل هذه الأداة.</p>
          <div class="user-info">
            <img src="https://randomuser.me/api/portraits/women/10.jpg" alt="User profile picture">
            <span class="username arabic-text">ريم سعيد</span>
          </div>
        </div>
        <div class="testimonial-card">
          <div class="stars">★★★★☆</div>
          <p class="arabic-text">أداة ممتازة لتحرير الفيديو. ساعدتني كثيراً في تحسين جودة محتوى الفيديو الخاص بي. السرعة وسهولة الاستخدام هي الميزات التي أحببتها أكثر. أنصح بها بشدة.</p>
          <div class="user-info">
            <img src="https://randomuser.me/api/portraits/men/11.jpg" alt="User profile picture">
            <span class="username arabic-text">يوسف علي</span>
          </div>
        </div>
        <div class="testimonial-card">
          <div class="stars">★★★★★</div>
          <p class="arabic-text">أداة لا غنى عنها لأي شخص يعمل في مجال تحرير الفيديو. تحويل الفيديوهات الطويلة إلى مقاطع قصيرة أصبح أمراً سهلاً بفضل هذه الأداة. الجودة مذهلة وسهولة الاستخدام لا تصدق.</p>
          <div class="user-info">
            <img src="https://randomuser.me/api/portraits/women/12.jpg" alt="User profile picture">
            <span class="username arabic-text">سلوى أحمد</span>
          </div>
        </div>
        <div class="testimonial-card">
          <div class="stars">★★★★★</div>
          <p class="arabic-text">هذه الأداة غيرت قواعد اللعبة بالنسبة لي. سرعة التحويل وجودة الفيديو ممتازة. أنصح بها بشدة لكل من يريد أداة فعالة لتحرير الفيديو.</p>
          <div class="user-info">
            <img src="https://randomuser.me/api/portraits/men/13.jpg" alt="User profile picture">
            <span class="username arabic-text">عبد الله حسين</span>
          </div>
        </div>
      </div>
    </div>
  </section>

 <!-- section 4 -->
 <section class="section-4">
    <div class="left-image">
      <img src="https://ebda3i.s3.ap-south-1.amazonaws.com/section+4+image2.png" alt="Left decorative image">
    </div>
    <div class="content">
      <h3 class="subtitle">البدء في بناء الأفكار</h3>
      <h2 class="title">أشترك معنا الان</h2>
      <p class="description">يمكنك التجربة مجانًا</p>
      <router-link :to="{ name: 'Plans' }" class="cta-button">اشتراك</router-link>
    </div>
    <div class="right-image">
      <img src="https://ebda3i.s3.ap-south-1.amazonaws.com/section+4+image.png" alt="Right decorative image">
    </div>
  </section>


 <!-- Section 5 -->
 <section class="faq-section">
      <h2 class="section-title">الأسئلة الشائعة</h2>
      <div class="faq-container">
        <div v-for="(faq, index) in faqs" :key="index" class="faq-item">
          <div class="faq-question" @click="toggleFaq($event)">
            <span>{{ faq.question }}</span>
            <button class="toggle-btn">▼</button>
          </div>
          <div class="faq-answer">
            <p>{{ faq.answer }}</p>
          </div>
        </div>
      </div>
    </section>


    </div>
  </template>
  
  <script>
  import ReelHeader from './ReelHeader.vue';  // Make sure this path is correct
  
  export default {
  components: {
    ReelHeader
  },
    data() {
      return {
        videoUrl: '',
        embedUrl: '',
        faqs: [
          {
            question: 'كيف يمكنني استخدام الأداة لتحويل الفيديوهات الطويلة إلى مقاطع قصيرة؟',
            answer: 'يمكنك ببساطة تحميل الفيديو الذي ترغب في تحويله. ثم تحديد الأجزاء التي تريد تقسيمها إلى مقاطع قصيرة. ستقوم الأداة بمعالجة الفيديو وإنشاء المقاطع القصيرة تلقائيًا.',
          
          },
          {
            question: 'ما هي جودة المقاطع القصيرة الناتجة؟',
            answer: 'تحافظ الأداة على جودة الفيديو الأصلي في المقاطع القصيرة الناتجة. يمكنك أيضًا اختيار جودة المقاطع حسب احتياجاتك.',
            isOpen: false
          },
          {
            question: 'هل يمكنني تحرير المقاطع القصيرة بعد إنشائها؟',
            answer: 'نعم، توفر الأداة إمكانية تحرير المقاطع القصيرة بعد إنشائها. يمكنك إضافة نصوص، موسيقى، أو تأثيرات بصرية حسب رغبتك.',
            isOpen: false
          },
          {
            question: 'كم عدد المقاطع القصيرة التي يمكنني إنشاؤها من فيديو واحد؟',
            answer: 'يمكنك إنشاء عدد غير محدود من المقاطع القصيرة من فيديو واحد. الحد الأقصى يعتمد فقط على طول الفيديو الأصلي وكيفية تقسيمك له.',
            isOpen: false
          },
          {
            question: 'هل الأداة متوافقة مع جميع تنسيقات الفيديو؟',
            answer: 'نعم، الأداة متوافقة مع معظم تنسيقات الفيديو الشائعة مثل MP4، AVI، MOV، وغيرها. إذا كان لديك تنسيق غير مدعوم، يرجى التواصل مع فريق الدعم الفني.',
            isOpen: false
          },
          {
            question: 'هل يمكنني استخدام الأداة على الهاتف المحمول؟',
            answer: 'نعم، الأداة متوافقة مع الأجهزة المحمولة. يمكنك استخدامها على هاتفك الذكي أو جهازك اللوحي من خلال متصفح الويب أو تطبيقنا المخصص للأجهزة المحمولة.',
            isOpen: false
          }
        ]
      };
    },
    methods: {

      goToMainReelPage() {
      this.$router.push({ name: 'MainReelPage' });
    },
    
    playVideo() {
      const url = new URL(this.videoUrl);
      const videoId = url.searchParams.get('v');
      this.embedUrl = `https://www.youtube.com/embed/${videoId}`;
    },
    toggleFaq(event) {
      const faqItem = event.currentTarget.parentNode;
      faqItem.classList.toggle('active');
    }
  }
};
</script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;700&display=swap');
  
  .landing-page {
    background: #021220;
    color: white;
    min-height: 100vh;
    font-family: 'Cairo', sans-serif;
    position: relative;
    overflow: hidden;
  }
  
  .background-shapes {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: 
      radial-gradient(circle at 10% 20%, rgba(255, 255, 255, 0.03) 0%, transparent 20%),
      radial-gradient(circle at 90% 80%, rgba(255, 255, 255, 0.03) 0%, transparent 20%),
      linear-gradient(to right, rgba(255, 255, 255, 0.02) 1px, transparent 1px),
      linear-gradient(to bottom, rgba(255, 255, 255, 0.02) 1px, transparent 1px);
    background-size: 100% 100%, 100% 100%, 20px 20px, 20px 20px;
    opacity: 0.5;
  }
  
  .background-shapes::before,
  .background-shapes::after {
    content: '';
    position: absolute;
    width: 200px;
    height: 200px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 20px;
  }
  
  .background-shapes::before {
    top: 10%;
    left: 5%;
    transform: rotate(15deg);
  }
  
  .background-shapes::after {
    bottom: 15%;
    right: 10%;
    transform: rotate(-10deg);
  }
  
  .hero {
    padding: 4rem 2rem;
    text-align: center;
    position: relative;
    z-index: 1;
  }
  
  .arabic-text {
    direction: rtl;
  }
  
  h1 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    font-weight: 700;
    line-height: 1.2;
  }
  
  p {
    font-size: 1.1rem;
    margin-bottom: 3rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.6;
    opacity: 0.8;
  }
  
  .video-input {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.input-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 50px;
  width: auto;
  padding: 5px;
}

.get-clips-btn {
  padding: 1rem 2rem;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
  z-index: 2;
}

.get-clips-btn::before,
.get-clips-btn::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: #3498db;
  border-radius: 25px;
  transform: translate(-50%, -50%) scale(1);
  opacity: 0;
  transition: all 0.6s ease;
  z-index: -1;
}

.get-clips-btn:hover::before {
  transform: translate(-50%, -50%) scale(1.5);
  opacity: 0.3;
}

.get-clips-btn:hover::after {
  transform: translate(-50%, -50%) scale(2);
  opacity: 0.1;
}
  
  .video-player {
    width: 80%;
    max-width: 1000px;
    aspect-ratio: 16 / 9;
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  }
  
  .video-player video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
/* Section 2 */
.second-section {
  padding: 6rem 0;
  overflow: hidden;
}

.content-wrapper {
  display: flex;
  align-items: center;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  gap: 2rem;
}

.video-thumbnails {
  flex: 1.2;
  position: relative;
  margin-left: -10%;
}

.video-thumbnails::before {
  content: '';
  position: absolute;
  top: -10%;
  left: -10%;
  width: 120%;
  height: 120%;
  background: rgba(0, 170, 255, 0.1);
  border-radius: 20px;
  z-index: -1;
}

.video-thumbnails img {
  width: 100%;
  border-radius: 10px;
}

.text-content {
  flex: 0.8;
  text-align: right;
  padding-left: 4rem;
  margin-right: -5%;
}

.text-content h2 {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  color: white;
  line-height: 1.2;
}

.text-content h2 .highlight {
  color: #68c5e4;
}

.text-content p {
  font-size: 1.3rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 1200px) {
  .second-section {
    padding: 4rem 2rem;
  }

  .content-wrapper {
    flex-direction: column;
    gap: 3rem;
  }
  
  .video-thumbnails, .text-content {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
  }
  
  .video-thumbnails img {
    width: 100%;
  }
  
  .text-content {
    text-align: center;
  }
}

/* Section 3 */
.testimonials-section {
  padding: 40px 20px;
  font-family: Arial, sans-serif;
  overflow: hidden;
}

.section-title {
  color: white;
  text-align: right;
  font-size: 28px;
  margin-bottom: 30px;
}

.testimonials-wrapper {
  max-width: 100%;
  overflow: hidden;
}

.testimonials-container {
  display: flex;
  gap: 30px;
  animation: scroll 60s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-350px * 7 - 30px * 7));
  }
}

.testimonial-card {
  background-color: rgba(45, 55, 72, 0.7);
  border-radius: 12px;
  padding: 30px;
  width: 350px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.stars {
  color: #f6e05e;
  font-size: 24px;
  margin-bottom: 15px;
  direction: rtl;
}

.testimonial-card p {
  color: white;
  font-size: 16px;
  line-height: 1.6;
  text-align: right;
  margin-bottom: 20px;
}

.user-info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.user-info img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: 15px;
}

.username {
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.arabic-text {
  direction: rtl;
}

/* Section 3 */
.section-4 {
  position: relative;
  color: #ffffff;
  padding: 100px 0;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-image, .right-image {
  position: absolute;
  top: 0;
  height: 100%;
}

.left-image {
  left: 0;
}

.right-image {
  right: 0;
}

.left-image img, .right-image img {
  height: 100%;
  object-fit: cover;
}

.content {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  z-index: 1;
}

.subtitle {
  font-size: 24px;
  color: #4fd1c5;
  margin-bottom: 10px;
  font-weight: normal;
}

.title {
  font-size: 48px;
  margin-bottom: 20px;
  font-weight: bold;
}

.description {
  font-size: 18px;
  margin-bottom: 30px;
}

.cta-button {
  background-color: #4fd1c5;
  color: #0e1621;
  border: none;
  padding: 12px 40px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #45b7ac;
}

@media (max-width: 1200px) {
  .left-image, .right-image {
    opacity: 0.3;
  }
}

@media (max-width: 768px) {
  .section-4 {
    padding: 60px 20px;
  }
  
  .subtitle {
    font-size: 20px;
  }
  
  .title {
    font-size: 36px;
  }
  
  .description {
    font-size: 16px;
  }
}
/* Section 5 */
.faq-section {
  padding: 80px 20px;
  font-family: 'Cairo', sans-serif;
}

.section-title {
  color: #ffffff;
  font-size: 36px;
  text-align: center;
  margin-bottom: 50px;
  font-weight: bold;
}

.faq-container {
  max-width: 800px;
  margin: 0 auto;
}

.faq-item {
  background-color: #1e2734;
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background-color: #2a3648;
}

.faq-question span {
  color: #ffffff;
  font-size: 18px;
  text-align: right;
  flex-grow: 1;
  font-weight: 500;
}

.toggle-btn {
  background-color: transparent;
  border: none;
  color: #4fd1c5;
  font-size: 24px;
  cursor: pointer;
  padding: 0 10px;
  transition: transform 0.3s ease;
}

.toggle-btn.active {
  transform: rotate(180deg);
}

.faq-answer {
  background-color: #2d3748;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.faq-answer p {
  color: #e2e8f0;
  font-size: 16px;
  line-height: 1.6;
  text-align: right;
  margin: 0;
  padding: 25px 30px;
}

@media (max-width: 768px) {
  .section-title {
    font-size: 30px;
  }

  .faq-question span {
    font-size: 16px;
  }

  .faq-answer p {
    font-size: 14px;
  }
}
  </style>
  
<!-- eslint-disable vue/no-parsing-error -->
<template>
    <div class="contact-page">
      <header class="header">
      <div class="header-actions">
        
        <router-link to="/dashboard" class="login-link" style="font-weight: bold; font-size: 18px; text-decoration: underline;">لنبدأ</router-link>
      </div>

      <nav class="header-nav">
        <a href="#" class="nav-link">الأسعار</a>
        <a href="#" class="nav-link">الأسئلة الشائعة</a>
      </nav>
      <div class="header-icons">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/1909eed33e4c92c0c6a7ae656b371072f6a331d8caa1d3f9aff6a1b1f6fa06f2?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Icon" class="icon" />
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/076ad4d43ff739e5b7165868b496c5cce1c42209ae846a2e33f5034beb9bd8ef?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Logo" class="logo" />
      </div>
    </header>
      <main class="main-content">
        <section class="hero-section">
          <div class="hero-background"></div>
          <h1 class="hero-title">تواصل معنا</h1>
        </section>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/066183b5f4807e92d98b1014f09184ed3a9ff9877e479b08e79b2cf113bc8292?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Contact Icon" class="contact-icon" />
        <h2 class="contact-title">تواصل معنا في &quot;إبداعي&quot;</h2>
        <p class="contact-description">
          نحن هنا للإجابة على جميع استفساراتك وتقديم الدعم الذي تحتاجه لإنشاء فيديوهات ترويجية تخطف الأنظار. سواء كان لديك سؤال، تحتاج إلى مساعدة، أو ترغب في مشاركتنا أفكارك، فريقنا مستعد للتواصل معك.
        </p>
        <form class="contact-form">
          <label for="name-input" class="visually-hidden">Name</label>
          <input type="text" id="name-input" class="form-input" placeholder="Name" />
          <label for="email-input" class="visually-hidden">Email</label>
          <input type="email" id="email-input" class="form-input" placeholder="Email" />
          <label for="phone-input" class="visually-hidden">Phone</label>
          <input type="tel" id="phone-input" class="form-input" placeholder="Phone" />
          <label for="message-input" class="visually-hidden">Message</label>
          <textarea id="message-input" class="form-textarea" placeholder="Message"></textarea>
          <button type="submit" class="submit-button">Submit</button>
        </form>
        <div class="social-links">
          <a href="#" class="social-link">
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/9766262708b48e2835da5746ef587f509b3456f06eb5fff390226e190def3af6?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Facebook" class="social-icon" />
          </a>
          <a href="#" class="social-link">
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/17407035faa13b157f834c3ecafd6b2709a62c0d0711e8373e23ca0598521262?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Twitter" class="social-icon" />
          </a>
          <a href="#" class="social-link">
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/6ce79af3be0aa30af43388fd405707422798173d243616bfc102e069943f9b47?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Instagram" class="social-icon" />
          </a>
        </div>
      </main>
      
    </div>
  </template>
  
  <style scoped>


.header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(231, 231, 231, 0.2);
  }

.header-actions {
  display: flex;
  gap: 20px;
  font-size: 16px;
}

.login-link {
  padding: 0.75rem 1.5rem;
    border-radius: 72px;
    background-color: #0098fd;
    color: #fff;
    font: 400 16px / 160% Tajawal, sans-serif;
    text-transform: uppercase;
}

.register-button {
  margin: auto 0;
  color: #0098fd;
  font: 400 16px/150% Tajawal, sans-serif;
  text-align: center;
}

.header-nav {
  display: flex;
  gap: 20px;
  margin: auto 0;
  font-size: 16px;
  color: #222;
  font-weight: 400;
  text-align: center;
  line-height: 150%;
}

.nav-link {
  font: 400 16px/150% Tajawal, sans-serif;
}

.header-icons {
  display: flex;
  gap: 10px;
  margin: auto 0;
}

.icon {
  width: 27px;
  aspect-ratio: 1;
  object-fit: contain;
}

.logo {
  width: 80px;
  aspect-ratio: 6.25;
  object-fit: contain;
  margin: auto 0;
}




  
 
  @media (max-width: 991px) {
    .header {
      max-width: 100%;
    }
  }
  
  .logo-container {
    box-shadow: 0 2px 5px 0 rgba(231, 231, 231, 0.2);
    background-color: var(--color-whites-White, #fff);
    display: flex;
    width: 100%;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    padding: 14px 80px;
  }
  
  @media (max-width: 991px) {
    .logo-container {
      max-width: 100%;
      flex-wrap: wrap;
      padding: 0 20px;
    }
  }
  
  .logo-icon {
    width: 27px;
    aspect-ratio: 1;
    object-fit: contain;
  }
  
  .logo-text {
    width: 80px;
    aspect-ratio: 6.25;
    object-fit: contain;
    margin: auto 0;
  }
  
  .navigation {
    align-self: stretch;
    display: flex;
    gap: 20px;
    font-size: 16px;
    color: #222;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    line-height: 150%;
    justify-content: space-between;
    margin: auto 0;
  }
  
  @media (max-width: 991px) {
    .navigation {
      white-space: initial;
    }
  }
  
  .nav-link {
    font-family: Tajawal, sans-serif;
  }
  
  .auth-buttons {
    align-self: stretch;
    display: flex;
    gap: 20px;
    font-weight: 400;
    justify-content: space-between;
  }
  
  .sign-in-link {
    color: var(--1, #0098fd);
    text-align: center;
    margin: auto 0;
    font: 16px/150% Tajawal, sans-serif;
  }
  
  .get-started-button {
    justify-content: center;
    border-radius: 72px;
    background-color: var(--1, #0098fd);
    color: var(--color-whites-White, #fff);
    text-transform: uppercase;
    padding: 28px 38px 29px;
    font: 18px/160% Tajawal, sans-serif;
  }
  
  @media (max-width: 991px) {
    .get-started-button {
      padding: 0 20px;
    }
  }
  
  .main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .hero-section {
    position: relative;
    overflow: hidden;
    align-self: center;
    min-height: 149px;
    width: 100%;
    max-width: 1190px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #303030;
    text-align: center;
    text-transform: capitalize;
    margin: 136px 0 14px;
    padding: 55px 60px 25px;
    font: 500 52px/133.2% Tajawal, sans-serif;
  }
  
  @media (max-width: 991px) {
    .hero-section {
      max-width: 100%;
      font-size: 40px;
      margin-top: 40px;
      padding: 0 20px;
    }
  }
  
  .hero-background {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  .hero-title {
    position: relative;
    z-index: 1;
  }
  
  .contact-icon {
    width: 76px;
    aspect-ratio: 1;
    object-fit: contain;
    fill: var(--1, #0098fd);
    margin-top: 64px;
  }
  
  @media (max-width: 991px) {
    .contact-icon {
      margin-top: 40px;
    }
  }
  
  .contact-title {
    color: #303030;
    text-transform: capitalize;
    margin-top: 60px;
    font: 400 40px/133.2% Tajawal, sans-serif;
  }
  
  @media (max-width: 991px) {
    .contact-title {
      margin-top: 40px;
    }
  }
  
  .contact-description {
    color: var(--color-blacks-Black, #000);
    text-align: center;
    margin-top: 40px;
    font: 400 20px/40px Tajawal, sans-serif;
  }
  
  @media (max-width: 991px) {
    .contact-description {
      max-width: 100%;
    }
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 545px;
    max-width: 100%;
    margin-top: 40px;
  }
  
  .form-input,
  .form-textarea {
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid rgba(224, 224, 224, 1);
    font: 400 16px/24px Tajawal, sans-serif;
  }
  
  .form-textarea {
    height: 168px;
    resize: none;
  }
  
  .submit-button {
    width: 100%;
    padding: 15px;
    border-radius: 26px;
    background-color: var(--1, #0098fd);
    color: var(--color-whites-White, #fff);
    font: 400 18px/27px Tajawal, sans-serif;
    cursor: pointer;
  }
  
  .social-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 60px;
    padding: 6px 80px;
  }
  
  @media (max-width: 991px) {
    .social-links {
      flex-wrap: wrap;
      padding: 6px 20px;
      margin-top: 40px;
    }
  }
  
  .social-link {
    display: flex;
  }
  
  .social-icon {
    width: 28px;
    aspect-ratio: 1;
    object-fit: contain;
  }
  
  .footer {
    background-color: #f5fbff;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
    width: 100%;
    padding-bottom: 48px;
  }
  
  @media (max-width: 991px) {
    .footer {
      max-width: 100%;
      margin-top: 40px;
    }
  }
  
  .footer-divider {
    background-color: var(--border-default, #e8e8e8);
    min-height: 1px;
    width: 100%;
    margin-top: 96px;
  }
  
  @media (max-width: 991px) {
    .footer-divider {
      max-width: 100%;
      margin-top: 40px;
    }
  }
  
  .footer-logo {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 96px;
    margin-right: 104px;
    align-self: flex-end;
  }
  
  @media (max-width: 991px) {
    .footer-logo {
      margin: 40px 10px 0 0;
    }
  }
  
  .footer-logo-icon {
    width: 27px;
    aspect-ratio: 1;
    object-fit: contain;
  }
  
  .footer-logo-text {
    width: 80px;
    aspect-ratio: 6.25;
    object-fit: contain;
    margin: auto 0;
  }
  
  .footer-social-links {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    margin-top: 17px;
    margin-left: 105px;
    align-self: flex-start;
  }
  
  @media (max-width: 991px) {
    .footer-social-links {
      margin-left: 10px;
    }
  }
  
  .footer-social-link {
    display: flex;
  }
  
  .footer-social-icon {
    width: 20px;
    aspect-ratio: 1;
    object-fit: contain;
  }
  
  .footer-logo-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 97px;
    align-self: center;
  }
  
  @media (max-width: 991px) {
    .footer-logo-container {
      margin-top: 40px;
    }
  }
  
  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  </style>
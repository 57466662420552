
<template>
    <div class="admin-dashboard">
      <div class="dashboard-sidebar">
        <div class="sidebar-header">
          <h2>Hello Admin</h2>
        </div>
        <ul class="sidebar-menu">
          <li><router-link to="/admin/dashboard" exact>Dashboard</router-link></li>
          <li><router-link to="/admin/dashboard/users">Users</router-link></li>
          <li><router-link to="/admin/dashboard/stripeplan">Stripe Plans</router-link></li>
          <li><router-link to="/admin/dashboard/credits">Credits</router-link></li>
          <li><router-link to="/admin/dashboard/template">Templates</router-link></li>
          <li><router-link to="/admin/dashboard/template-images">Images</router-link></li>
          <li><router-link to="/admin/dashboard/audio">Audio</router-link></li>
          <li>
            <router-link to="/admin/dashboard/support">
              Support
              <span v-if="openTicketCount > 0" class="notification-badge">{{ openTicketCount }}</span>
            </router-link>
          </li>
          <li><router-link to="/admin/dashboard/settings">Settings</router-link></li>
        </ul>
      </div>
      <div class="dashboard-content">
        <div class="content-header">
          <h3>{{ $route.name }}</h3>
        </div>
        <div class="content-body">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import { API_URL } from "@/config";
  
  export default {
    data() {
      return {
        openTicketCount: 0,
      };
    },
    mounted() {
      this.fetchOpenTicketCount();
    },
    methods: {
      async fetchOpenTicketCount() {
        const token = localStorage.getItem("token");
        if (!token) {
          return;
        }
  
        try {
          const response = await fetch(`${API_URL}/api/admin/tickets/open-count`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          const data = await response.json();
          this.openTicketCount = data.count;
        } catch (error) {
          console.error("Error fetching open ticket count:", error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .notification-badge {
    display: inline-block;
    background-color: #e53e3e;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    padding: 2px 6px;
    border-radius: 50%;
    margin-left: 5px;
  }
  
  .admin-dashboard {
    display: flex;
    height: 100vh;
  }
  
  .dashboard-sidebar {
    width: 250px;
    background-color: #1a202c;
    color: #fff;
    padding: 20px;
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: auto;
    transition: width 0.3s;
  }
  
  .dashboard-sidebar:hover {
    width: 280px;
  }
  
  .sidebar-header {
    margin-bottom: 30px;
    position: relative;
  }
  
  .sidebar-header h2 {
    font-size: 24px;
    font-weight: bold;
    opacity: 0;
    transform: translateY(-20px);
    animation: fadeInDown-4371df16 0.5s ease forwards;
    color: white;
  }
  
  .sidebar-menu {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .sidebar-menu li {
    margin-bottom: 15px;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.5s ease forwards;
  }
  
  .sidebar-menu li:nth-child(1) {
    animation-delay: 0.1s;
  }
  
  .sidebar-menu li:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .sidebar-menu li:nth-child(3) {
    animation-delay: 0.3s;
  }
  
  .sidebar-menu li:nth-child(4) {
    animation-delay: 0.4s;
  }
  
  .sidebar-menu li:nth-child(5) {
    animation-delay: 0.5s;
  }
  
  .sidebar-menu li:nth-child(6) {
    animation-delay: 0.6s;
  }
  
  .sidebar-menu li:nth-child(7) {
    animation-delay: 0.7s;
  }
  
  .sidebar-menu li:nth-child(8) {
    animation-delay: 0.8s;
  }
  
  .sidebar-menu li:nth-child(9) {
    animation-delay: 0.9s;
  }
  
  .sidebar-menu a {
    display: block;
    color: #fff;
    text-decoration: none;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s, transform 0.3s;
  }
  
  .sidebar-menu a:hover {
    background-color: #4a5568;
    color: #fff;
    transform: translateX(5px);
  }
  
  .sidebar-menu .router-link-active {
    background-color: #4a5568;
    font-weight: bold;
  }
  
  .dashboard-content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    background-color: #f7fafc;
  }
  
  .content-header {
    margin-bottom: 20px;
  }
  
  .content-header h3 {
    font-size: 24px;
    font-weight: bold;
    color: #1a202c;
    opacity: 0;
    transform: translateY(-20px);
    animation: fadeInDown 0.5s ease forwards;
    animation-delay: 0.2s;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }
  
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  </style>
  
<template>
  <div> <ReelHeader />
    <div class="video-processor">
      <div class="background-shapes"></div>
      <h1 class="title">اصنع محتواك بكل بساطه٫ من فيديو واحد طويل إلى مقتطفات قصيرة ورهيبة !</h1>
      <p class="subtitle">هي أداة فيديو تعمل بالذكاء الاصطناعي تعمل على إعادة استخدام مقاطع الفيديو الطويلة وتحويلها إلى مقاطع قصيرة سريعة الانتشار</p>
    
      <div class="video-input">
      <div class="input-wrapper">
        <i class="link-icon">🔗</i>
        <input v-model="videoUrl" type="text" placeholder="ضع رابط الفيديو" class="video-link-input">
        <button @click="processVideo" class="get-clips-btn">تأكيد</button>
      </div>
    </div>


    <div v-if="!urlEntered && !isDownloading" class="instructions-container">
        <p class="instructions-title">تعمل خوارزميتنا بشكل أفضل مع مقاطع الفيديو التالية</p>
        <div class="instructions-grid">
          <div class="instructions-column rejected">
            <div class="instruction-item">
              <span class="cross">✗</span>
              مدونة فيديو
            </div>
            <div class="instruction-item">
              <span class="cross">✗</span>
              فيديوهات الأفلام
            </div>
            <div class="instruction-item">
              <span class="cross">✗</span>
              مقاطع فيديو موسيقية
            </div>
          </div>
          <div class="instructions-column accepted">
            <div class="instruction-item">
              <span class="checkmark">✓</span>
              مقاطع فيديو
            </div>
            <div class="instruction-item">
              <span class="checkmark">✓</span>
              فيديوهات تعليمية
            </div>
            <div class="instruction-item">
              <span class="checkmark">✓</span>
              فيديو التطبيقات
            </div>
            <div class="instruction-item">
              <span class="checkmark">✓</span>
              تقنيات الشرح
            </div>
            <div class="instruction-item">
              <span class="checkmark">✓</span>
              فيديوهات تحضيرية
            </div>
          </div>
        </div>
      </div>




      
      <div v-if="urlEntered && !isDownloading" class="video-settings-container">
    <div class="video-preview">
      <iframe v-if="isYouTubeUrl" :src="youtubeEmbedUrl" frameborder="0" allowfullscreen></iframe>
      <video v-else :src="uploadedVideoUrl" controls></video>
    </div>
    
    <div class="settings-controls">
  <div class="setting">
    <label>معالجة الإطار الزمني</label>
    <div class="range-slider">
      <div class="range-selected" :style="rangeStyle"></div>
      <input type="range" v-model.number="timeframeStart" :min="0" :max="videoDuration" step="1" @input="updateTimeframe('start')" ref="startTimeSlider">
      <input type="range" v-model.number="timeframeEnd" :min="0" :max="videoDuration" step="1" @input="updateTimeframe('end')" ref="endTimeSlider">
    </div>
    <div class="range-values">
      <span class="time-display">{{ formatTime(timeframeStart) }}</span>
      <span class="time-display">{{ formatTime(timeframeEnd) }}</span>
    </div>
  </div>

  

      
      <div class="setting">
  <label>اختيار الخط</label>
  <div class="font-buttons">
    <button 
      v-for="option in fontOptions" 
      :key="option.value" 
      @click="setFont(option.value)"
      :class="{ active: fontName === option.value }"
    >
      {{ option.label }}
    </button>
  </div>
</div>


<div class="setting">
      <label>لون الخط</label>
      <div class="color-picker">
        <input type="color" v-model="fontColor" @input="updateFontColor">
        <span class="color-value">{{ fontColor }}</span>
      </div>
    </div>

    <div class="setting">
  <label>حجم الخط</label>
  <div class="font-size-control">
    <button @click="updateFontSize(fontSize - 5)">-</button>
    <span>{{ fontSize }}px</span>
    <button @click="updateFontSize(fontSize + 5)">+</button>
  </div>
  <span class="font-size-recommendation">الحجم الموصى به: 100px</span>
</div>

    <div class="setting">
  <label>معاينة النص</label>
  <div class="text-preview" :style="previewStyle">
    {{ exampleText }}
  </div>
</div>


    <div class="setting">
  <label>موضع التسمية التوضيحية</label>
  <div class="position-options">
    <button :class="{ active: captionPosition === 'top' }" @click.prevent="setCaptionPosition('top')">أعلى</button>
    <button :class="{ active: captionPosition === 'bottom' }" @click.prevent="setCaptionPosition('bottom')">أسفل</button>
  </div>
    </div>
  </div>
    
  <div class="credit-info-container">
  <div class="credit-info">
    <i class="fas fa-coins credit-icon"></i>
    <p>تكلفة الرصيد: <span class="credit-amount">{{ requiredCredits }}</span> رصيد</p>
  </div>
</div>

<button @click.prevent="submitSettings" class="submit-btn" :disabled="isLoading || insufficientCredits">
  <span v-if="!isLoading">تأكيد الإعدادات</span>
  <span v-else>جاري التحميل...</span>
</button>

<div v-if="insufficientCredits" class="warning-message">
  <i class="fas fa-exclamation-triangle warning-icon"></i>
  <p>ليس لديك رصيد كافٍ. <router-link :to="{ name: 'Plans' }">اشترِ المزيد من الرصيد</router-link></p>
</div>
</div>

  <div v-if="isDownloading" class="downloading-container">
      <div class="download-animation"></div>
      <h2>نحن نقوم بتنزيل الفيديو الآن</h2>
      <p>سيتم إعادة توجيهك في غضون دقيقة. يرجى عدم تحديث الصفحة.</p>
    </div>
  </div>
</div>
</template>

  
<script>
import { ref, computed, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { API_URL } from '@/config';
import '@/assets/fonts.css';
import ReelHeader from './ReelHeader.vue';  // Make sure this path is correct

export default {
  components: {
    ReelHeader
  },
  setup() {
    const router = useRouter();
    const isLoading = ref(false);
    const isDownloading = ref(false);

    const videoUrl = ref('');
    const urlEntered = ref(false);
    const timeframeStart = ref(0);
    const timeframeEnd = ref(0);
    const timeframeStartModified = ref(false);
    const timeframeEndModified = ref(false);
    const videoDuration = ref(0);
    const isYouTubeUrl = ref(true);
    const youtubeEmbedUrl = ref('');
    const uploadedVideoUrl = ref('');
    const fontSize = ref(100);
    const selectedTemplateId = ref(null);
    const fontName = ref('Amiri');
    const fontOptions = ref([
      { value: 'Amiri', label: 'Amiri' },
      { value: 'Noto Naskh Arabic', label: 'Noto Naskh Arabic' },
      { value: 'Noto Kufi Arabic', label: 'Noto Kufi Arabic' },
    ]);
    const fontColor = ref('#ffffff');
    const captionPosition = ref('bottom');
    const exampleText = ref('مثال على النص المعروض هنا');
    const requiredCredits = ref(0);
    const insufficientCredits = ref(false);

    const rangeStyle = computed(() => {
      const start = (timeframeStart.value / videoDuration.value) * 100;
      const end = (timeframeEnd.value / videoDuration.value) * 100;
      return {
        left: `${start}%`,
        width: `${end - start}%`
      };
    });

    const previewStyle = computed(() => {
      return {
        fontFamily: fontName.value,
        fontSize: `${fontSize.value}px`,
        color: fontColor.value,
        position: 'relative',
        [captionPosition.value]: '0',
        textAlign: 'center',
        padding: '10px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      };
    });

    watch(timeframeStart, (newValue) => {
      if (newValue > timeframeEnd.value) {
        timeframeEnd.value = newValue;
      }
    });

    watch(timeframeEnd, (newValue) => {
      if (newValue < timeframeStart.value) {
        timeframeStart.value = newValue;
      }
    });

    const fetchRequiredCredits = async () => {
      try {
        const response = await fetch(`${API_URL}/api/required-credits`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        const data = await response.json();
        if (data.length > 0) {
          requiredCredits.value = data[0].requested_credits;
        }
      } catch (error) {
        console.error('Error fetching required credits:', error);
      }
    };

    onMounted(() => {
      fetchRequiredCredits();
    });

    return {
      videoUrl,
      urlEntered,
      timeframeStart,
      timeframeEnd,
      videoDuration,
      isYouTubeUrl,
      youtubeEmbedUrl,
      uploadedVideoUrl,
      fontSize,
      selectedTemplateId,
      fontName,
      fontOptions,
      fontColor,
      captionPosition,
      exampleText,
      rangeStyle,
      previewStyle,
      router,
      isLoading,
      isDownloading,
      timeframeStartModified,
      timeframeEndModified,
      requiredCredits,
      insufficientCredits,
    };
  },

  methods: {
    updateFontSize(newSize) {
      this.fontSize = Math.max(50, Math.min(110, newSize));
      console.log('Font size updated:', this.fontSize);
    },

    updateTimeframe(type) {
      if (type === 'start') {
        this.timeframeStart = this.roundToSecond(this.timeframeStart);
        this.timeframeStartModified = true;
        if (this.timeframeStart > this.timeframeEnd) {
          this.timeframeEnd = this.timeframeStart;
          this.timeframeEndModified = true;
        }
      } else if (type === 'end') {
        this.timeframeEnd = this.roundToSecond(this.timeframeEnd);
        this.timeframeEndModified = true;
        if (this.timeframeEnd < this.timeframeStart) {
          this.timeframeStart = this.timeframeEnd;
          this.timeframeStartModified = true;
        }
      }
    },

    setFont(value) {
      this.fontName = value;
      console.log('Font set to:', value);
    },

    setCaptionPosition(position) {
      console.log('setCaptionPosition called with:', position);
      this.captionPosition = position;
    },

    updateFontColor(event) {
      this.fontColor = event.target.value;
      console.log('Font color updated:', this.fontColor);
    },

    processVideo() {
      console.log('processVideo called');
      if (this.videoUrl.trim() !== '') {
        this.urlEntered = true;
        console.log('urlEntered set to true');
        this.determineVideoSource();
        this.fetchVideoInfo();
      }
    },

    determineVideoSource() {
      if (this.videoUrl.includes('youtube.com') || this.videoUrl.includes('youtu.be')) {
        this.isYouTubeUrl = true;
        this.setYouTubeEmbedUrl();
      } else {
        this.isYouTubeUrl = false;
        this.uploadedVideoUrl = this.videoUrl;
      }
    },

    setYouTubeEmbedUrl() {
      let videoId = this.extractYouTubeId(this.videoUrl);
      if (videoId) {
        this.youtubeEmbedUrl = `https://www.youtube.com/embed/${videoId}`;
      }
    },

    extractYouTubeId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
      return (match && match[2].length === 11) ? match[2] : null;
    },

    fetchVideoInfo() {
      const token = localStorage.getItem('token');
      fetch(`${API_URL}/get_video_info`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ video_url: this.videoUrl }),
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        this.videoDuration = this.roundToSecond(data.duration);
        this.timeframeStart = 0;
        this.timeframeEnd = this.videoDuration;
        this.$nextTick(() => {
          if (this.$refs.startTimeSlider) {
            this.$refs.startTimeSlider.max = this.videoDuration;
          }
          if (this.$refs.endTimeSlider) {
            this.$refs.endTimeSlider.max = this.videoDuration;
          }
        });
      })
      .catch(error => {
        console.error('Error fetching video info:', error);
      });
    },

    formatTime(seconds) {
      const roundedSeconds = this.roundToSecond(seconds);
      const minutes = Math.floor(roundedSeconds / 60);
      const remainingSeconds = roundedSeconds % 60;
      return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}ث`;
    },

    roundToSecond(value) {
      return Math.round(value);
    },

    playVideo(event) {
      event.target.play();
    },

    pauseVideo(event) {
      event.target.pause();
    },

    
    async submitSettings() {
      console.log('submitSettings called');
      this.isLoading = true;
      this.insufficientCredits = false;

      const fontFileMap = {
        'Amiri': 'Amiri-Regular.ttf',
        'Noto Naskh Arabic': 'NotoNaskhArabic-VariableFont_wght.ttf',
        'Noto Kufi Arabic': 'NotoKufiArabic-VariableFont_wght.ttf',
      };

      const settings = {
        video_url: this.videoUrl,
        font_name: fontFileMap[this.fontName] || this.fontName,
        font_size: this.fontSize,
        caption_position: this.captionPosition,
        font_color: this.fontColor,
      };

      if (this.timeframeStartModified) {
        settings.start_time = this.timeframeStart;
      }
      if (this.timeframeEndModified) {
        settings.end_time = this.timeframeEnd;
      }

      try {
        const response = await fetch(`${API_URL}/process_youtube_video`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify(settings),
        });

        const data = await response.json();

        if (response.status === 403 && data.message === "Insufficient credits") {
          this.insufficientCredits = true;
          this.isLoading = false;
          return;
        }

        if (data.video_id) {
          this.isDownloading = true;
          this.monitorProcessing(data.video_id);
        } else {
          console.error('Missing video_id in response');
          this.isLoading = false;
          this.isDownloading = false;
        }
      } catch (error) {
        console.error('Error starting video processing:', error);
        this.isLoading = false;
      }
    },

    monitorProcessing(videoId) {
      const checkStatus = () => {
        fetch(`${API_URL}/monitor_processing/${videoId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then(response => response.json())
        .then(data => {
          console.log('Processing update:', data);

          if (data.processed_status === 'downloaded' || data.processed_status === 'completed') {
            console.log('INFO - Video processing completed');
            this.isDownloading = false;  // Hide the downloading container
            this.$router.push({ 
              name: 'ReelProjectsPage', 
              params: { videoId: videoId }
            });
          } else if (data.processed_status === 'failed') {
            console.log('ERROR - Video processing failed');
            this.isDownloading = false;  // Hide the downloading container
            this.$toast.error('Video processing failed. Please try again.');
          } else {
            // If still processing, check again after 1 second
            setTimeout(checkStatus, 1000);
          }
        })
        .catch(error => {
          console.error('Error checking processing status:', error);
          this.isDownloading = false;  // Hide the downloading container
          this.$toast.error('Error checking processing status. Please try again.');
        });
      };

      // Start checking status
      checkStatus();
    }
  }
}
</script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;700&display=swap');
  
  .video-processor {
  background-color: #0c1620;
  color: white;
  font-family: 'Cairo', sans-serif;
  padding: 4rem 2rem;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  direction: rtl;
  position: relative;
  overflow: hidden;
}
  
  .background-shapes {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: 
      radial-gradient(circle at 10% 20%, rgba(255, 255, 255, 0.03) 0%, transparent 20%),
      radial-gradient(circle at 90% 80%, rgba(255, 255, 255, 0.03) 0%, transparent 20%),
      linear-gradient(to right, rgba(255, 255, 255, 0.02) 1px, transparent 1px),
      linear-gradient(to bottom, rgba(255, 255, 255, 0.02) 1px, transparent 1px);
    background-size: 100% 100%, 100% 100%, 20px 20px, 20px 20px;
    opacity: 0.5;
  }
  
  .background-shapes::before,
  .background-shapes::after {
    content: '';
    position: absolute;
    width: 200px;
    height: 200px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 20px;
  }
  
  .background-shapes::before {
    top: 10%;
    left: 5%;
    transform: rotate(15deg);
  }
  
  .background-shapes::after {
    bottom: 15%;
    right: 10%;
    transform: rotate(-10deg);
  }
  
  .title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    font-weight: 700;
    max-width: 800px;
  }
  
  .subtitle {
    font-size: 1.1rem;
    margin-bottom: 3rem;
    opacity: 0.7;
    max-width: 600px;
  }
  
  .input-container {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
    width: 100%;
    max-width: 600px;
  }
  
  .video-input {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 50px;
  width: 100%;
}

.link-icon {
  position: absolute;
  right: 20px;
  font-style: normal;
  color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}

.video-link-input {
  padding: 1rem 3.5rem 1rem 1rem;
  width: calc(100% - 200px);
  border: none;
  background-color: transparent;
  color: white;
  font-size: 1rem;
  outline: none;
}

.video-link-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.get-clips-btn {
  position: absolute;
  left: 5px;
  top: 5px;
  bottom: 5px;
  padding: 0 2rem;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
  z-index: 2;
}

.get-clips-btn::before,
.get-clips-btn::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: #3498db;
  border-radius: 25px;
  transform: translate(-50%, -50%) scale(1);
  opacity: 0;
  transition: all 0.6s ease;
  z-index: -1;
}

.get-clips-btn:hover::before {
  transform: translate(-50%, -50%) scale(1.5);
  opacity: 0.3;
}

.get-clips-btn:hover::after {
  transform: translate(-50%, -50%) scale(2);
  opacity: 0.1;
}
.instructions-container {
  background-color: rgba(30, 40, 52, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 2rem;
  text-align: right;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.instructions-title {
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  font-weight: 700;
  color: #ffffff;
}

.instructions-grid {
  display: flex;
  justify-content: space-between;
}

.instructions-column {
  width: 48%;
}

.instruction-item {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.checkmark, .cross {
  margin-left: 0.75rem;
  font-weight: bold;
  font-size: 1.2rem;
}

.accepted .checkmark {
  color: #2ecc71;
}

.rejected .cross {
  color: #e74c3c;
}






.video-settings-container {
  background-color: #1a2634;
  color: white;
  padding: 2rem;
  border-radius: 10px;
  width: 55%;
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .video-settings-container {
    width: 95%;
    padding: 1rem;
  }
}


.position-options button,
.submit-btn {
  cursor: pointer;
  pointer-events: auto;
  z-index: 10;
  position: relative;
}

.position-options button {
  padding: 5px 10px;
  margin: 0 5px;
  background-color: #2c3e50;
  border: none;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.position-options button.active {
  background-color: #3498db;
}

.position-options button:hover,
.submit-btn:hover {
  opacity: 0.8;
}


.setting select {
  width: 100%;
  padding: 0.5rem;
  background-color: #2c3e50;
  color: white;
  border: 1px solid #3498db;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;
  padding-right: 1.5rem;
}

.setting select:hover,
.setting select:focus {
  border-color: #2ecc71;
  outline: none;
}

.setting select option {
  background-color: #2c3e50;
  color: white;
}

.setting select,
.setting input[type="color"],
.setting button {
  pointer-events: auto;
  cursor: pointer;
  z-index: 10;
}

.setting select {
  position: relative;
  z-index: 1000;
}

.font-buttons {
  display: grid;
  gap: 10px;
}

.font-buttons button {
  padding: 5px 10px;
  background-color: #2c3e50;
  border: 1px solid #3498db;
  color: white;
  cursor: pointer;
}

.font-buttons button.active {
  background-color: #3498db;
}

.native-select {
  /* Reset to native styles */
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
  background: white;
  color: black;
  border: 1px solid #ccc;
  padding: 5px;
  width: 100%;
}

.color-picker input[type="color"] {
  -webkit-appearance: none;
  width: 50px;
  height: 50px;
  border: none;
  cursor: pointer;
}

.color-picker input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

.color-picker input[type="color"]::-webkit-color-swatch {
  border: none;
}

.video-preview {
  margin-bottom: 2rem;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.video-preview iframe,
.video-preview video {
  width: 100%;
  height: 450px;
  object-fit: contain;
  border-radius: 10px;
  background-color: #000; /* Add a black background for letterboxing */
}

@media (max-width: 1024px) {
  .video-preview iframe,
  .video-preview video {
    height: 400px;
  }
}

@media (max-width: 768px) {
  .video-preview iframe,
  .video-preview video {
    height: 350px;
  }
}

@media (max-width: 480px) {
  .video-preview iframe,
  .video-preview video {
    height: 250px;
  }
}

.settings-controls {
  margin-bottom: 2rem;
}

.setting {
  margin-bottom: 1.5rem;
}

.setting label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}
.range-slider {
  position: relative;
  height: 5px;
  background-color: #2c3e50;
  border-radius: 5px;
  margin: 2rem 0;
  direction: ltr; /* Ensure left-to-right for slider */
}

.range-slider input[type="range"] {
  position: absolute;
  top: -7px;
  left: 0;
  width: 100%;
  -webkit-appearance: none;
  background: transparent;
  pointer-events: auto;
}

.range-slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #3498db;
  cursor: pointer;
  z-index: 2;
  position: relative;
}

.range-slider input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #3498db;
  cursor: pointer;
  z-index: 2;
  position: relative;
}

.range-selected {
  position: absolute;
  height: 5px;
  background-color: #3498db;
  top: 0;
}

.range-values {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.time-display {
  background-color: #3498db;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.9em;
}


.text-preview {
  width: 100%;
  min-height: 60px;
  border: 1px solid #ccc;
  margin-top: 10px;
  padding: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  box-sizing: border-box;
}

@media screen and (max-width: 600px) {
  .text-preview {
    min-height: 80px;
    font-size: 14px;
    padding: 8px;
  }
}

@media screen and (max-width: 400px) {
  .text-preview {
    min-height: 100px;
    font-size: 12px;
    padding: 6px;
  }
}

.select-input {
  width: 100%;
  padding: 0.5rem;
  background-color: #2c3e50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;  /* Added cursor style */
}

.color-picker {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.color-picker input[type="color"] {
  -webkit-appearance: none;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  background: none;
  cursor: pointer;
}

.color-picker input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

.color-picker input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
}

.color-value {
  font-size: 0.9rem;
  color: #3498db;
}

.video-length-options {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.video-length-options button {
  flex: 1;
  background-color: #2c3e50;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.video-length-options button.active {
  background-color: #3498db;
}

.caption-templates h3 {
  margin-bottom: 1rem;
}

.template-options {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  padding-bottom: 1rem;
}

.template-option {
  text-align: center;
}

.template-option video {
  width: 100px;
  height: 150px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
}

.template-option span {
  display: block;
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

.submit-btn {
  display: block;
  width: 100%;
  background-color: #27ae60;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #2ecc71;
}

.font-size-control {
  display: grid;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.font-size-control button {
  background-color: #2c3e50;
  border: none;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.font-size-control button:hover {
  background-color: #3498db;
}

.font-size-control span {
  font-size: 1rem;
  min-width: 50px;
  text-align: center;
}

.font-size-recommendation {
  display: block;
  font-size: 0.9rem;
  color: #7f8c8d;
  margin-top: 0.5rem;
}

.submit-btn:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

.downloading-container {
    background-color: rgba(30, 40, 52, 0.9);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    padding: 2rem;
    text-align: center;
    max-width: 600px;
    width: 100%;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.1);
    margin: 2rem auto;
  }

  .downloading-container h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #3498db;
  }

  .downloading-container p {
    font-size: 1rem;
    color: #ecf0f1;
  }

  .download-animation {
    width: 80px;
    height: 80px;
    border: 5px solid #ecf0f1;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    margin: 0 auto 2rem;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .credit-info-container {
  background-color: rgba(52, 152, 219, 0.1);
  border: 1px solid rgba(52, 152, 219, 0.3);
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.credit-info {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  color: #ecf0f1;
}

.credit-icon {
  font-size: 1.5rem;
  color: #f1c40f;
  margin-right: 0.5rem;
  margin-left: 10px;
}

.credit-amount {
  font-weight: bold;
  color: #2ecc71;
  margin: 0 0.25rem;
}

.submit-btn {
  display: block;
  width: 100%;
  background-color: #27ae60;
  color: white;
  border: none;
  padding: 1rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  margin-top: 1rem;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.submit-btn:hover:not(:disabled) {
  background-color: #2ecc71;
  transform: translateY(-2px);
}

.submit-btn:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

.warning-message {
  background-color: rgba(231, 76, 60, 0.1);
  border: 1px solid rgba(231, 76, 60, 0.3);
  border-radius: 10px;
  padding: 1rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  color: #e74c3c;
  position: relative;
  z-index: 10;
}

.warning-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.warning-message p {
  margin: 0;
  position: relative;
  z-index: 11;
}

.warning-message a {
  color: #3498db;
  text-decoration: underline;
  font-weight: bold;
  transition: color 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  padding: 2px 4px;
  border-radius: 3px;
  position: relative;
  z-index: 12;
  pointer-events: auto;
}

.warning-message a:hover {
  color: #2980b9;
  background-color: rgba(52, 152, 219, 0.1);
}

/* Ensure the link is clickable by setting pointer-events to auto for all parent elements */
.video-processor,
.video-settings-container,
.settings-controls {
  pointer-events: auto;
}
</style>
 
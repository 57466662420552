<!-- eslint-disable vue/no-parsing-error -->
<template>
    <section class="hero-section">
        <header class="header">
      <div class="header-actions">
        <router-link to="/dashboard" class="login-link" style="font-weight: bold; font-size: 18px; text-decoration: underline;">لنبدأ</router-link>
      </div>

      <nav class="header-nav">
        <a href="#" class="nav-link">الأسعار</a>
        <a href="#" class="nav-link">الأسئلة الشائعة</a>
      </nav>
      <div class="header-icons">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/1909eed33e4c92c0c6a7ae656b371072f6a331d8caa1d3f9aff6a1b1f6fa06f2?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Icon" class="icon" />
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/076ad4d43ff739e5b7165868b496c5cce1c42209ae846a2e33f5034beb9bd8ef?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Logo" class="logo" />
      </div>
    </header>
      <div class="hero-container">
        <img class="hero-image" src="https://cdn.builder.io/api/v1/image/assets/TEMP/e10882cdb04faf6a0132636ef208861570258418e0825307f47985d3a87efb47?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Hero Image">
        <div class="content">
          <h1>تحويل المنتجات إلى <span class="highlight">فيديوهات ترويجية</span> بنقرة واحدة</h1>
          <p>نسهل لك إنتاج فيديوهات ترويجية للوصول لأكبر عدد من العملاء في وسائل التواصل الاجتماعي. اختر من قوالب تصميمنا أو قم بتصميم فيديو يناسبك مع وسائل التواصل الاجتماعي لتعزيز حضورك على الإنترنت.</p>
          <router-link to="/dashboard" class="cta-button">التجربة مجاناً</router-link>
        </div>
      </div>
      <img class="additional-image" src="https://cdn.builder.io/api/v1/image/assets/TEMP/2ab011620cb5972ddd36e976ed1cb142716e36f36b79155c0da88b9906560519?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Additional Image">
    </section>

<!--section 2 -->
<section class="trusted-section">
    <div class="trusted-container">
      <h2>موثوق من 25 ألف شركة</h2>
      <div class="company-logos">
        <div class="company-logo">ProSaas</div>
        <div class="company-logo">No1 City</div>
        <div class="company-logo">Mata</div>
        <div class="company-logo">BRAND</div>
        <div class="company-logo">Tech House</div>
      </div>
    </div>
  </section>

  <!--section 3 -->
  <section class="steps-section">
    <div class="steps-container">
      <div class="steps-image">
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/3654435a0c3ebe2aa114a37431ffc37c16f67f9888ac2a7c7f1aaba413c81dc2?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Phone Screens">
      </div>
      <div class="steps-content"style="direction: rtl;">
        <h2>كيف يعمل Ebda3i؟</h2>
        <div class="step">
          <div class="step-number">1</div>
          <p>قم بإضافة الرابط: يتم إضافة الرابط الخاص بالعميل أو المنتج الذي تريده.</p>
        </div>
        <div class="step">
          <div class="step-number">2</div>
          <p>إضافة المحتوى: يتم الدخول على الفيديوهات، وضمن الخطوات يمكنك تحميل عدد لا نهائي من القوالب المعدة.</p>
        </div>
        <div class="step">
          <div class="step-number">3</div>
          <p>تخصيص المنتج: يتم تعديل النص المقترح على المقاطع وجعل المنتج جاهز للعرض بحلته النهائية.</p>
        </div>
      </div>
    </div>
  </section>

  <!--section 4 -->
  <section class="info-section">
    <div class="info-container">
      <div class="info-content">
        <h2>الأثر القوي للفيديوهات القصيرة</h2>
        <p>دراسات مستفيضة أثبتت أن الفيديوهات التي مدتها 60 ثانية تجذب انتباه الناس وتزيد محتواها أكثر من الفيديوهات الطويلة. تبرز التفاصيل والتوجه في وسائل التواصل الاجتماعي، مما يساعد على تعزيز وصول محتواك ومشاهداته إلى أرقام قياسية.</p>
        <p>تحليل الفيديوهات والنتائج يمكن أن يؤدي إلى اتخاذ إجراءات وتحسين مستمر. في "إبداعي"، نوفر لك القوالب والتطبيق لتتمكن من بناء محتوى يحقق النتائج الفعّالة.</p>
      </div>
      <div class="info-image">
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c3c4d7d6564356619891f646a59a3acabcee681d5cd7c15f7003e5b05bfd056f?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Video Impact">
      </div>
    </div>
  </section>


  <!--section 5 -->
  <section class="features-section">
    <div class="features-container">
      <div class="features-image">
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/bbaa03da2ae1b3d4897db46c6d085c8bf8e312fd65ff3f44ee57e2d1a7d1fbb8?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Phone Features">
      </div>
      <div class="features-content">
        <h2>مزایا الخدمة</h2>
        <div class="features-grid">
          <div class="feature-box">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/9a02f5ac870c6d7f089c3792a6663782d976db558eda45560216c10a51b35f52?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" class="service-icon" alt="Template design icon" />
            <p>إنتاج فيديوهات ترويجية بجودة عالية في دقائق</p>
          </div>
          <div class="feature-box">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/f1cde5be10e88f2d14510fc71d474d9e23b452f3e07536f524daeba876f456ee?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" class="service-icon" alt="High quality production icon" />
            <p>قوالب مصممة بعناية لتناسب مختلف الصناعات والمنتجات</p>
          </div>
          <div class="feature-box">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/92741879ec167e794ad8f6a11319a22c2a94100d2cda9922843596c8b58a0609?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" class="service-icon" alt="Analysis and evaluation icon" />
            <p>إمكانية التحليل والتقييم لأداء الفيديوهات عبر منصات متعددة</p>
          </div>
        </div>
      </div>
    </div>
  </section>


  <!--section 6 -->
  <section class="benefits-section">
    <div class="benefits-container">
      <div class="benefits-content">
        <h2>لماذا إبداعي؟</h2>
        <div class="benefits-grid">
          <div class="benefit-box">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/3a9913487545701f456d514dcb640d44e55a7e4e437a56c50d455bb4d40f5939?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Wide integration icon" class="benefit-icon">
            <p>تكامل واسع النطاق: يوفر نظامنا تحليلات شاملة، تتبع أداء الفيديو، ويساعد في تحديد الفعالية والكفاءة.</p>
          </div>
          <div class="benefit-box">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/759ad2ba9241d21902bed75be4e8a743ae345d0bfaf6e8de9482c9aef969f730?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Unmatched design quality icon" class="benefit-icon">
            <p>جودة تصميم في متناول اليد: أدواتنا تتيح لك تصميم فيديوهات عالية الجودة بسرعة وسهولة، دون الحاجة لمهارات فنية متقدمة.</p>
          </div>
          <div class="benefit-box">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/514d5344f9184fbf504dd8d20cd9f5d1001d0a179f54e1b3fe00c887bcc33d2d?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Experienced design icon" class="benefit-icon">
            <p>مصمم بخبرة: تم تطوير نظامنا ليوفر أفضل تجربة مستخدم، مع أدوات سهلة الاستخدام وميزات قوية.</p>
          </div>
          <div class="benefit-box">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/d08c8533152631134a524551a719e7acbed960be9f204fe5dff8f15f5566c779?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Simplicity icon" class="benefit-icon">
            <p>البساطة في أسمى صورها: احصل على نظرة شاملة لأداء فيديوهاتك عبر تقارير دقيقة وتحليلات مفصلة.</p>
          </div>
          <div class="benefit-box">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/5f79d970588e09c3280b556bd90df8afb14d170086be271e79a13fb610abe798?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Scheduling and auto-publishing icon" class="benefit-icon">
            <p>الجدولة والنشر التلقائي: أنشر فيديوهاتك بجودة عالية على مختلف المنصات بسرعة وبدون عناء.</p>
          </div>
          <div class="benefit-box">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/96c41a30de6a4047c638f7673039ea83d0c8cb52d0e78c937ce938a324cac78d?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Easy integration icon" class="benefit-icon">
            <p>التكامل السهل: يتكامل نظامنا بسلاسة مع مختلف الأدوات والتطبيقات التي تستخدمها، لتحسين كفاءتك.</p>
          </div>
        </div>
      </div>
      <div class="benefits-image">
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/2f2c58e4be7b53a9c0f4e1366923a8868a4f2584b7e21e2abcfc522e5e56e20e?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Benefits Image">
      </div>
    </div>
  </section>


  <!--section 7 -->
  <section class="numbers-section">
    <h2>بالأرقام</h2>
    <div class="numbers-container">
      <div class="number-box">
        <h3>+1000000</h3>
        <p>وصول إلى ملايين المتابعين عبر الفيديوهات المنشورة</p>
      </div>
      <div class="number-box">
        <h3>+500</h3>
        <p>قالب متنوع</p>
      </div>
      <div class="number-box">
        <h3>+10,000</h3>
        <p>فيديو تم تقديمه حتى الآن</p>
      </div>
    </div>
  </section>


   <!--section 8 -->
   <section class="testimonials-section">
    <h2>استمع إلى ما يقوله عملاؤنا</h2>
    <div class="testimonials-wrapper">
      <button class="nav-button" @click="scrollLeft">←</button>
      <div class="testimonials-container" ref="container" @scroll="handleScroll">
        <div class="testimonial-box" v-for="(testimonial, index) in infiniteTestimonials" :key="index">
          <img :src="testimonial.image" alt="User Image" class="user-image">
          <p class="testimonial-text">{{ testimonial.text }}</p>
          <h3 class="user-name">{{ testimonial.name }}</h3>
          <p class="user-role">{{ testimonial.role }}</p>
          <div class="rating">★★★★★</div>
        </div>
      </div>
      <button class="nav-button" @click="scrollRight">→</button>
    </div>
  </section>


    <!--section 9 -->
 <section class="image-section">
    <div class="image-container">
      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/0ca525371d9f615b3dc374cfa4bef23613bea88ea54399d76e778cc8480c2c90?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Centered Image">
    </div>
  </section>


 <!--section 10 -->
 <section class="cta-section">
    <div class="cta-container" style="direction: rtl;">
      <h2>انضم إلى رحلة الإبداع مع Ebda3i</h2>
      <p>
        ابدأ الآن واكتشف كيف يمكن لـ "Ebda3i" أن يحول أفكارك إلى واقع يلامس ويخدم جمهورك. لا تفوت فرصة تعزيز علامتك التجارية بالفيديوهات الترويجية الجذابة والمؤثرة.
      </p>
      <router-link to="/dashboard" class="cta-button-2">التجربة مجاناً</router-link>
      <div class="floating-circles">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
      </div>
    </div>
  </section>


  </template>
  
  <script>
  export default {
    name: 'EighthSection',
    data() {
      return {
        testimonials: [
          {
            id: 1,
            image: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
            text: 'استخدام إبداعي لإنشاء الفيديوهات الترويجية هو شيء لا يُصدق. لقد ساعدنا في الوصول إلى جمهور أوسع بوقت قياسي!',
            name: 'محمد علي',
            role: 'مدير',
          },
          {
            id: 2,
            image: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
            text: 'إبداعي هو الأداة المثالية لتحسين تواجدنا على وسائل التواصل الاجتماعي. نستخدمها لإنشاء محتوى رائع وجذاب.',
            name: 'أحمد حسن',
            role: 'مصمم',
          },
          {
            id: 3,
            image: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
            text: 'نوصي بشدة باستخدام إبداعي لأي شركة ترغب في تعزيز وجودها الرقمي وتحقيق نتائج ملموسة.',
            name: 'سارة يوسف',
            role: 'مستشار تسويق',
          },
          {
            id: 4,
            image: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
            text: 'إبداعي يوفر أدوات ممتازة وسهلة الاستخدام لإنتاج فيديوهات عالية الجودة بسرعة.',
            name: 'خالد أحمد',
            role: 'مسوق',
          },
          {
            id: 5,
            image: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
            text: 'لقد شهدنا زيادة كبيرة في التفاعل على منصات التواصل الاجتماعي بفضل إبداعي.',
            name: 'ليلى محمد',
            role: 'مدير وسائل التواصل الاجتماعي',
          },
          {
            id: 6,
            image: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
            text: 'نحب استخدام إبداعي لإنشاء محتوى مرئي رائع يجذب جمهورنا.',
            name: 'يوسف علي',
            role: 'مصمم جرافيك',
          },
        ],
      };
    },
    computed: {
      infiniteTestimonials() {
        return [...this.testimonials, ...this.testimonials];
      },
    },
    methods: {
      scrollLeft() {
        this.$refs.container.scrollBy({
          left: -300,
          behavior: 'smooth',
        });
      },
      scrollRight() {
        this.$refs.container.scrollBy({
          left: 300,
          behavior: 'smooth',
        });
      },
      handleScroll() {
        const container = this.$refs.container;
        if (container.scrollLeft === 0) {
          container.scrollLeft = container.scrollWidth / 2;
        } else if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
          container.scrollLeft = container.scrollWidth / 2 - container.clientWidth;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .hero-section {
    width: 100%;
    text-align: center;
    background: #f9f9f9;
    position: relative;
  }
  
  .hero-container {
    position: relative;
    width: 100%;
  }
  
  .hero-image {
    width: 100%;
    height: auto;
  }
  
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    max-width: 800px;
    padding: 0 1rem;
  }
  
  h1 {
    font-size: 2.5rem;
    color: black;
  }
  
  .highlight {
    color: #007bff;
  }
  
  p {
    font-size: 1.25rem;
    color: black;
    margin: 1rem 0;
  }
  
  .cta-button {
    background-color: #0089ff;
    color: #ffffff;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 25px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #0056b3;
  }
  
  .additional-image {
    width: 100%;
    max-width: 1000px;
    margin-top: 1rem;
  }


  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(231, 231, 231, 0.2);
  }

.header-actions {
  display: flex;
  gap: 20px;
  font-size: 16px;
}

.login-link {
  padding: 0.75rem 1.5rem;
    border-radius: 72px;
    background-color: #0098fd;
    color: #fff;
    font: 400 16px / 160% Tajawal, sans-serif;
    text-transform: uppercase;
}

.register-button {
  margin: auto 0;
  color: #0098fd;
  font: 400 16px/150% Tajawal, sans-serif;
  text-align: center;
}

.header-nav {
  display: flex;
  gap: 20px;
  margin: auto 0;
  font-size: 16px;
  color: #222;
  font-weight: 400;
  text-align: center;
  line-height: 150%;
}

.nav-link {
  font: 400 16px/150% Tajawal, sans-serif;
}

.header-icons {
  display: flex;
  gap: 10px;
  margin: auto 0;
}

.icon {
  width: 27px;
  aspect-ratio: 1;
  object-fit: contain;
}

.logo {
  width: 80px;
  aspect-ratio: 6.25;
  object-fit: contain;
  margin: auto 0;
}






/*setion 2 css */
.trusted-section {
  width: 100%;
  background: #fff;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trusted-container {
  max-width: 1200px;
  width: 100%;
  text-align: center;
}

.trusted-container h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 2rem;
}

.company-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.company-logo {
  font-size: 1.25rem;
  color: #333;
  font-weight: bold;
  text-transform: uppercase;
}




/*section 3 */ 
.steps-section {
  width: 100%;
  background: #ffffff;
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.steps-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #ffffff;
  flex-wrap: wrap;
  gap: 3rem;
}

.steps-image {
  flex: 1;
  max-width: 45%;
}

.steps-image img {
    width: 40%;
    height: auto;
    margin-left: 190px;
}

.steps-content {
    flex: 1;
    max-width: 45%;
    text-align: right;
    margin-right: 200px;
}

.steps-content h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 2.5rem;
    margin-right: 40px;
}

.step {
    display: flex;
    align-items: center;
    margin-bottom: 5rem;
    gap: 12px;
}

.step-number {
  width: 40px;
  height: 40px;
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.step p {
  font-size: 1.5rem;
  color: #333;
  margin: 0;
  font-weight: 500;
}





/*section 4 */
.info-section {
  width: 100%;
  background: #ffffff;
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #ffffff;
  flex-wrap: wrap;
  gap: 3rem;
}

.info-content {
  flex: 1;
  max-width: 45%;
  text-align: right;
  margin-left: 200px;
}

.info-content h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2.5rem;
}

.info-content p {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.info-image {
  flex: 1;
  max-width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-image img {
    width: 90%;
    height: auto;
    margin-top: -114px;
}







/*section 5 */
.features-section {
  width: 100%;
  background: #ffffff;
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.features-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: #ffffff;
  gap: 3rem;
}

.features-image {
  flex: 1;
  max-width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.features-image img {
  width: 60%;
  height: auto;
}

.features-content {
  flex: 1;
  max-width: 45%;
  text-align: right;
  margin-right: 200px;
}

.features-content h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 2.5rem;
    margin-right: 52px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.feature-box {
  background: #fff;
  width: 100%;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  aspect-ratio: 1 / 1; /* Ensures the boxes are square */
}

.feature-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.feature-box p {
    font-size: 2.25rem;
    color: #333;
    margin: 59px;
    font-weight: 450;
}

.feature-box:nth-child(3) {
  grid-column: span 2;
  justify-self: center;
  width: 50%; /* Adjust width for the third box to center it */
}







/*section 6 */
.benefits-section {
  width: 100%;
  background: #ffffff;
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.benefits-container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 3rem;
}

.benefits-content {
  flex: 1;
  max-width: 55%;
}

.benefits-content h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 2.5rem;
    text-align: center;
    margin-left: -258px;
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}

.benefit-box {
    background: #fff;
    width: 100%;
    padding: 1.5rem;
    border-radius: 8px;
    text-align: center;
    border: 1px solid #ddd;
    margin-left: -136px;
}

.benefit-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.benefit-box p {
  font-size: 1.125rem;
  color: #333;
  margin: 0;
  font-weight: 500;
}

.benefits-image {
    flex: 1;
    max-width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -42px;
}

.benefits-image img {
    width: 100%;
    height: auto;
    margin-right: -161px;
}



/*section7*/
.numbers-section {
  width: 100%;
  background: #f9f9f9;
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.numbers-section h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 2rem;
}

.numbers-container {
  width: 90%;
  max-width: 1200px;
  background: #007bff;
  padding: 2rem;
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.number-box {
  text-align: center;
  color: #fff;
}

.number-box h3 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.number-box p {
  font-size: 1.125rem;
  color: #ffffff;
}




/*section 8*/

.testimonials-section {
  width: 100%;
  background: #f9f9f9;
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.testimonials-section h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 2rem;
}

.testimonials-wrapper {
  width: 90%;
  max-width: 1200px;
  display: flex;
  align-items: center;
}

.testimonials-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: 1rem;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.testimonials-container::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.testimonial-box {
  background: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #ddd;
  min-width: 300px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.user-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.testimonial-text {
  font-size: 1rem;
  color: #333;
  margin-bottom: 1rem;
}

.user-name {
  font-size: 1.125rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
}

.user-role {
  font-size: 0.875rem;
  color: #666;
  margin-bottom: 1rem;
}

.rating {
  font-size: 1.25rem;
  color: #f39c12;
}

.nav-button {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #007bff;
}



/*section 9*/
.image-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  background-color: #f9f9f9;
}

.image-container {
  max-width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/*section 10 */
.cta-section {
  width: 100%;
  background: #f9f9f9;
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cta-container {
  width: 65%; /* Half the website width */
  background: #007bff;
  text-align: center;
  color: #fff;
  padding: 2rem;
  border-radius: 20px;
  position: relative; /* To position the floating circles inside it */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Optional: Add a slight shadow for better focus */
  overflow: hidden; /* To hide overflow of floating circles */
}

.cta-container h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #ffffff;
}

.cta-container p {
  font-size: 1.125rem;
  margin-bottom: 2rem;
  color: #ffffff;
}

.cta-button-2 {
  background-color: #fff;
  color: #007bff;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #0056b3;
  color: #fff;
}

.floating-circles {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.circle {
  position: absolute;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  opacity: 0.6;
  animation: float 5s infinite ease-in-out; /* Increased speed */
}

.circle:nth-child(1) {
  width: 50px;
  height: 50px;
  bottom: 20%;
  left: 10%;
  animation-duration: 4s; /* Adjusted duration for faster movement */
}

.circle:nth-child(2) {
  width: 75px;
  height: 75px;
  top: 25%;
  right: 15%;
  animation-duration: 3s; /* Adjusted duration for faster movement */
}

.circle:nth-child(3) {
  width: 100px;
  height: 100px;
  bottom: 30%;
  right: 5%;
  animation-duration: 5s; /* Adjusted duration for faster movement */
}

.circle:nth-child(4) {
  width: 60px;
  height: 60px;
  top: 50%;
  left: 20%;
  animation-duration: 4.5s; /* Adjusted duration for faster movement */
}

.circle:nth-child(5) {
  width: 80px;
  height: 80px;
  bottom: 40%;
  left: 30%;
  animation-duration: 3.5s; /* Adjusted duration for faster movement */
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Increased the float distance */
  }
  100% {
    transform: translateY(0);
  }
}

@media screen and (max-width: 1200px) {
  .steps-content,
  .info-content,
  .features-content,
  .benefits-content {
    margin-right: 0;
    margin-left: 0;
  }

  .steps-image img,
  .info-image img,
  .features-image img,
  .benefits-image img {
    margin-left: 0;
    margin-right: 0;
  }

  .benefits-grid {
    margin-left: 0;
  }
}

@media screen and (max-width: 992px) {
  .steps-container,
  .info-container,
  .features-container,
  .benefits-container {
    flex-direction: column;
    align-items: center;
  }

  .steps-image,
  .info-image,
  .features-image,
  .benefits-image {
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .steps-content,
  .info-content,
  .features-content,
  .benefits-content {
    max-width: 100%;
    text-align: center;
  }

  .steps-content h2,
  .info-content h2,
  .features-content h2,
  .benefits-content h2 {
    margin-right: 0;
    margin-left: 0;
  }

  .feature-box:nth-child(3) {
    width: 100%;
  }

  .benefits-grid {
    grid-template-columns: 1fr;
  }

  .benefit-box {
    margin-left: 0;
  }

  .numbers-container {
    flex-direction: column;
    gap: 2rem;
  }

  .cta-container {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .header {
    flex-direction: column;
    gap: 1rem;
  }

  .header-nav {
    margin: 0;
  }

  .hero-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .hero-image {
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .content {
    position: static;
    transform: none;
    padding: 0 1rem;
    text-align: center;
  }

  .hero-section h1 {
    font-size: 2rem;
  }

  .hero-section p {
    font-size: 1rem;
  }

  .trusted-container h2,
  .steps-content h2,
  .info-content h2,
  .features-content h2,
  .benefits-content h2,
  .numbers-section h2,
  .testimonials-section h2,
  .cta-container h2 {
    font-size: 1.8rem;
  }

  .step p,
  .info-content p,
  .benefit-box p,
  .cta-container p {
    font-size: 1rem;
  }

  .features-grid {
    grid-template-columns: 1fr;
  }

  .feature-box p {
    margin: 1rem;
  }

  .numbers-container {
    width: 100%;
    border-radius: 0;
  }

  .testimonials-wrapper {
    width: 100%;
  }

  .testimonial-box {
    min-width: 250px;
  }
}

@media screen and (max-width: 576px) {
  .header-actions {
    gap: 10px;
  }

  .login-link,
  .register-button {
    font-size: 14px;
  }

  .header-nav {
    gap: 10px;
  }

  .nav-link {
    font-size: 14px;
  }

  .hero-section h1 {
    font-size: 1.5rem;
  }

  .hero-section p {
    font-size: 0.9rem;
  }

  .trusted-container h2,
  .steps-content h2,
  .info-content h2,
  .features-content h2,
  .benefits-content h2,
  .numbers-section h2,
  .testimonials-section h2,
  .cta-container h2 {
    font-size: 1.5rem;
  }

  .step p,
  .info-content p,
  .benefit-box p,
  .cta-container p {
    font-size: 0.9rem;
  }

  .number-box h3 {
    font-size: 2rem;
  }

  .number-box p {
    font-size: 1rem;
  }

  .cta-button-2 {
    font-size: 0.9rem;
  }

  .steps-image img,
  .info-image img,
  .features-image img,
  .benefits-image img {
    width: 100%;
  }

  .step {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .step-number {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
  </style>
  
  
  
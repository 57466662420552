<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="policy-page">
    <header class="header">
      <div class="header-actions">
        <router-link to="/dashboard" class="login-link" style="font-weight: bold; font-size: 18px; text-decoration: underline;">لنبدأ</router-link>
      </div>

      <nav class="header-nav">
        <a href="#" class="nav-link">الأسعار</a>
        <a href="#" class="nav-link">الأسئلة الشائعة</a>
      </nav>
      <div class="header-icons">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/1909eed33e4c92c0c6a7ae656b371072f6a331d8caa1d3f9aff6a1b1f6fa06f2?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Icon" class="icon" />
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/076ad4d43ff739e5b7165868b496c5cce1c42209ae846a2e33f5034beb9bd8ef?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Logo" class="logo" />
      </div>
    </header>
    <main class="main-content">
      <section class="hero-section">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/75cb945eb5a73e27dc1957579339054510327a97c47fce8444f50b8cd4efc819?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="" class="hero-background" />
        <h1 class="hero-title">سياسة الخصوصية</h1>
      </section>
      <section class="policy-content">
        <article class="policy-text">
          <h2 class="section-title">التزامنا:</h2>
          <p class="section-description">
            في "فيلماتيكس"، نتعامل مع بياناتك الشخصية بمسؤولية وشفافية. سياسة الخصوصية هذه توضح كيفية جمعنا، استخدامنا، وحمايتنا لبياناتك الشخصية.
          </p>
          <h2 class="section-title">جمع البيانات:</h2>
          <ul class="policy-list">
            <li class="policy-item">نجمع بيانات شخصية عندما تسجل لاستخدام خدماتنا، مثل الاسم، البريد الإلكتروني، ورقم الهاتف.</li>
            <li class="policy-item">نجمع أيضًا بيانات غير شخصية تتعلق بكيفية استخدامك لموقعنا.</li>
            <li class="policy-item">عند تسجيل الدخول باستخدام Google، نجمع معلومات ملفك الشخصي في Google، بما في ذلك الاسم وعنوان البريد الإلكتروني، لإنشاء وإدارة حسابك.</li>
          </ul>
          <h2 class="section-title">استخدام البيانات:</h2>
          <ul class="policy-list">
            <li class="policy-item">نستخدم بياناتك لتقديم وتحسين خدماتنا، للتواصل معك بخصوص حسابك أو خدماتنا، ولأغراض التسويق بموافقتك.</li>
          </ul>
          <h2 class="section-title">حماية البيانات:</h2>
          <ul class="policy-list">
            <li class="policy-item">نتخذ جميع الإجراءات المعقولة لحماية بياناتك من الوصول غير المصرح به والكشف والتعديل والتدمير.</li>
            <li class="policy-item">يتم تخزين بياناتك بأمان والوصول إليها محدود فقط للأفراد المصرح لهم.</li>
          </ul>
          <h2 class="section-title">تبادل البيانات:</h2>
          <ul class="policy-list">
            <li class="policy-item">لا نبيع بياناتك الشخصية أو نشاركها مع أطراف ثالثة، باستثناء ما هو مطلوب بموجب القانون أو كجزء من تقديم خدماتنا لك.</li>
            <li class="policy-item">قد نشارك بياناتك مع مقدمي الخدمات الخارجيين لمساعدتنا في تشغيل وتحسين خدماتنا، بشرط امتثالهم لمعايير حماية البيانات الخاصة بنا.</li>
          </ul>
          <h2 class="section-title">حقوق المستخدمين:</h2>
          <ul class="policy-list">
            <li class="policy-item">لديك الحق في الوصول إلى بياناتك الشخصية أو تحديثها أو حذفها في أي وقت عن طريق الاتصال بنا.</li>
            <li class="policy-item">يمكنك أيضًا اختيار عدم تلقي الاتصالات التسويقية منا.</li>
          </ul>
          <h2 class="section-title">ملفات تعريف الارتباط وتقنيات التتبع:</h2>
          <ul class="policy-list">
            <li class="policy-item">نستخدم ملفات تعريف الارتباط والتقنيات المماثلة لتعزيز تجربة المستخدم الخاصة بك، وتحليل أنماط الاستخدام، وتقديم محتوى مخصص.</li>
          </ul>
          <h2 class="section-title">تغييرات على سياسة الخصوصية:</h2>
          <ul class="policy-list">
            <li class="policy-item">قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر. سيتم نشر أي تغييرات على هذه الصفحة، وسنقوم بإعلامك بالتغييرات الكبيرة.</li>
          </ul>
          <h2 class="section-title">معلومات الاتصال:</h2>
          <ul class="policy-list">
            <li class="policy-item" dir="rtl">إذا كانت لديك أي أسئلة أو مخاوف بشأن سياسة الخصوصية هذه أو ممارساتنا المتعلقة بالبيانات، يرجى الاتصال بنا على [info@filmatex.com].</li>

          </ul>
        </article>
        <aside class="decorative-dots">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </aside>
      </section>
    </main>
  </div>
</template>


  
  <script >

  </script>
  
  <style scoped>

.header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(231, 231, 231, 0.2);
  }

.header-actions {
  display: flex;
  gap: 20px;
  font-size: 16px;
}

.login-link {
  padding: 0.75rem 1.5rem;
    border-radius: 72px;
    background-color: #0098fd;
    color: #fff;
    font: 400 16px / 160% Tajawal, sans-serif;
    text-transform: uppercase;
}

.register-button {
  margin: auto 0;
  color: #0098fd;
  font: 400 16px/150% Tajawal, sans-serif;
  text-align: center;
}

.header-nav {
  display: flex;
  gap: 20px;
  margin: auto 0;
  font-size: 16px;
  color: #222;
  font-weight: 400;
  text-align: center;
  line-height: 150%;
}

.nav-link {
  font: 400 16px/150% Tajawal, sans-serif;
}

.header-icons {
  display: flex;
  gap: 10px;
  margin: auto 0;
}

.icon {
  width: 27px;
  aspect-ratio: 1;
  object-fit: contain;
}

.logo {
  width: 80px;
  aspect-ratio: 6.25;
  object-fit: contain;
  margin: auto 0;
}



  .policy-page {
    background-color: var(--color-whites-White, #fff);
    display: flex;
    flex-direction: column;
  }
  
  .header {
    box-shadow: 0 2px 5px 0 rgba(231, 231, 231, 0.2);
    background-color: var(--color-whites-White, #fff);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 80px;
  }
  
  @media (max-width: 991px) {
    .header {
      flex-wrap: wrap;
      padding: 0 20px;
    }
  }
  
  .logo-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .logo-icon {
    width: 27px;
    aspect-ratio: 1;
    object-fit: contain;
  }
  
  .logo-text {
    width: 80px;
    aspect-ratio: 6.25;
    object-fit: contain;
  }
  
  .navigation {
    display: flex;
    gap: 20px;
    font-size: 16px;
    color: #222;
    white-space: nowrap;
    text-align: center;
    line-height: 150%;
  }
  
  @media (max-width: 991px) {
    .navigation {
      white-space: initial;
    }
  }
  
  .nav-link {
    font-family: Tajawal, sans-serif;
    text-decoration: none;
    color: inherit;
  }
  
  .auth-buttons {
    display: flex;
    gap: 20px;
    font-weight: 400;
  }
  
  .sign-in-link {
    color: var(--1, #0098fd);
    text-align: center;
    font: 16px/150% Tajawal, sans-serif;
    text-decoration: none;
  }
  
  .get-started-button {
    border-radius: 72px;
    background-color: var(--1, #0098fd);
    color: var(--color-whites-White, #fff);
    text-transform: uppercase;
    padding: 28px 38px 29px;
    font: 18px/160% Tajawal, sans-serif;
    text-decoration: none;
  }
  
  @media (max-width: 991px) {
    .get-started-button {
      padding: 0 20px;
    }
  }
  
  .main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;
  }
  
  .hero-section {
    position: relative;
    overflow: hidden;
    min-height: 149px;
    width: 100%;
    max-width: 1190px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #303030;
    text-align: center;
    text-transform: capitalize;
    margin: 136px 0 14px;
    padding: 55px 60px 25px;
  }
  
  @media (max-width: 991px) {
    .hero-section {
      max-width: 100%;
      margin-top: 40px;
      padding: 0 20px;
    }
  }
  
  .hero-background {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  .hero-title {
    position: relative;
    font: 500 52px/133.2% Tajawal, sans-serif;
  }
  
  @media (max-width: 991px) {
    .hero-title {
      font-size: 40px;
    }
  }
  
  .policy-content {
    display: flex;
    gap: 20px;
    padding: 0 20px;
    margin-top: 76px;
    align-self: center;
  }
  
  @media (max-width: 991px) {
    .policy-content {
      flex-wrap: wrap;
      margin-top: 40px;
    }
  }
  
  .policy-text {
    color: var(--color-blacks-Black, #000);
    text-align: right;
    flex-grow: 1;
    font: 400 24px/48px Inter, sans-serif;
  }
  
  @media (max-width: 991px) {
    .policy-text {
      max-width: 100%;
    }
  }
  
  .section-title {
    font: 30px Tajawal, sans-serif;
    margin-bottom: 16px;
  }
  
  .section-description {
    font: 24px Tajawal, sans-serif;
    margin-bottom: 24px;
  }
  
  .policy-list {
    margin-bottom: 24px;
    padding-right: 20px;
  }
  
  .policy-item {
    font: 24px Tajawal, -apple-system, Roboto, Helvetica, sans-serif;
    margin-bottom: 8px;
  }
  
  .decorative-dots {
    display: flex;
    flex-direction: column;
    align-self: start;
  }
  
  @media (max-width: 991px) {
    .decorative-dots {
      display: none;
    }
  }
  
  .dot {
    background-color: var(--1, #0098fd);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-bottom: 231px;
  }
  
  .dot:last-child {
    margin-bottom: 0;
  }
  </style>
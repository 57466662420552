<template>
  <div class="music-edit-container" dir="rtl">
    <div class="music-edit-header">
      <h1>تعديل الموسيقى</h1>
    </div>
    <div class="music-edit-body">
      <section v-if="adminMusic.length" class="suggested-music">
        <h2>الموسيقى المقترحة</h2>
        <ul>
          <li v-for="track in adminMusic" :key="track.id" class="track-item">
            <button @click="selectTrack(track.url)" class="play-button">
              تشغيل <i class="fas fa-play" style="margin-left: 5px;"></i>
            </button>
            <span class="track-title">{{ track.title }}</span>
          </li>
        </ul>
      </section>
      <section class="upload-section">
        <h2>رفع موسيقاك</h2>
        <label for="file-upload" class="custom-file-upload">
          <i class="fas fa-cloud-upload-alt"></i> اختر ملف
        </label>
        <input id="file-upload" type="file" accept="audio/*" @change="handleFileUpload" />
        <div v-if="audioData" class="audio-info">
          <p>الملف الصوتي المختار: <strong>{{ audioFileName }}</strong></p>
          <audio :src="audioData" controls></audio>
        </div>
        <div v-if="isLoading" class="loading-indicator">
          <div class="spinner"></div>
          <span>جار التحميل...</span>
        </div>
      </section>
    </div>
  </div>
</template>

  
  <script>
import { API_URL } from '@/config';
import { mapMutations } from 'vuex';

export default {
  name: 'MusicPreview',
  props: {
    previewInstance: {
      type: Object,
      required: true
    },
    modifications: {
      type: Object,
      required: true
    },
    dynamicElementIds: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      audioData: null,
      audioFileName: '',
      adminMusic: [],
      isLoading: false,
    };
  },
  mounted() {
    this.loadInitialAudioData();
    this.fetchAdminMusic();
  },
  methods: {
    ...mapMutations(['updateModifications']),
    handleFileUpload(event) {
    const file = event.target.files[0];
    this.audioFileName = file.name;
    this.uploadFileToBackend(file);
  },
  async uploadFileToBackend(file) {
    const formData = new FormData();
    formData.append('audio', file);
    const token = localStorage.getItem('token');
    this.isLoading = true;

    try {
      const response = await fetch(`${API_URL}/api/upload_audio`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log('File uploaded successfully:', data);
      this.audioData = data.url;
      this.updateAudio('audio_key', this.audioData);
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      this.isLoading = false;
    }
  },
  loadInitialAudioData() {
    const audioKey = 'audio_key';
    const elementId = this.dynamicElementIds[audioKey];
    if (elementId && this.modifications[elementId]) {
      this.audioData = this.modifications[elementId];
    }
  },
  updateAudio(key, audioData) {
    const elementId = this.dynamicElementIds[key];
    if (elementId) {
      this.updateModifications({ key: elementId, value: audioData });
      this.$emit('update-modifications', { [elementId]: audioData });
    } else {
      console.warn(`Element ID not found for audio key: ${key}`);
    }
  },
    applyAudioModifications() {
      if (this.previewInstance) {
        const modifications = { ...this.modifications };
        const audioElementId = this.dynamicElementIds['audio_key'];
        if (audioElementId) {
          modifications[audioElementId] = this.audioData;
          console.log('Applying modifications:', modifications);
          try {
            this.previewInstance.setModifications(modifications);
            console.log('Modifications applied successfully to preview instance.');
          } catch (error) {
            console.error('Error applying modifications to preview instance:', error);
          }
        } else {
          console.warn('Audio element ID is not defined for applying modifications.');
        }
      } else {
        console.warn('Preview instance is not defined.');
      }
    },
    fetchAdminMusic() {
    const token = localStorage.getItem('token');
    fetch(`${API_URL}/get_audio_files_users`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        this.adminMusic = data;
      })
      .catch(error => {
        console.error('Error fetching admin music:', error);
      });
  },
  selectTrack(url) {
    this.audioData = url;
    this.updateAudio('audio_key', url);
  }
}
};
</script>

<style scoped>
.music-edit-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  background: #f4f4f8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.music-edit-header h1 {
  color: #333;
  text-align: center;
}

.music-edit-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.suggested-music h2,
.upload-section h2 {
  color: #555;
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}

.track-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.play-button {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;
  margin-right: 15px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.play-button i {
  margin-right: 5px;
}

.track-title {
  flex-grow: 1;
  margin-right: 20px;
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #007BFF;
  color: white;
  border-radius: 4px;
  font-size: 16px;
  text-align: center;
}

.custom-file-upload i {
  margin-right: 8px;
}

#file-upload {
  display: none;
}

.audio-info {
  margin-top: 10px;
  background: #e9ecef;
  padding: 10px;
  border-radius: 4px;
}

.audio-info p {
  margin: 0;
  color: #333;
}

.loading-indicator {
  margin-top: 10px;
  color: #007bff;
  font-size: 16px;
  text-align: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #007bff;
  width: 24px;
  height: 24px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

import { createApp, h } from 'vue';
import App from './App.vue';
import router from './router';
import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { VCalendar } from 'vuetify/labs/VCalendar';
import '@mdi/font/css/materialdesignicons.css'; // Ensure you have this line
import 'plyr/dist/plyr.css';
import 'video.js/dist/video-js.css'
import store from './store';

const vuetify = createVuetify({
  components: {
    ...components,
    VCalendar,
  },
  directives,
  icons: {
    defaultSet: 'mdi',
    sets: {
      mdi: {
        component: (props) => h('span', { class: 'mdi mdi-' + props.icon })
      }
    },
  }
});

const app = createApp(App);

app.config.errorHandler = (err, vm, info) => {
  console.error('Error:', err);
  console.error('Error Info:', info);
  console.error('Error Component Name:', vm? vm.$options.name : 'unknown');
  console.error('Error Props:', vm? vm.$props : 'No props');
  console.error('Full Component:', vm);
};

app.use(router);
app.use(vuetify);
app.use(store);
app.mount('#app');
